import { FunctionComponent } from "react"
import * as React from "react"

interface IProps {
	headline: string
	subHeadline?: string
}

export const Headline: FunctionComponent<IProps> = (props: IProps) => {
	return (
		<React.Fragment>
			<div className="row">
				<div className="col-12">
					<div className="billing">
						<span className="heading">{props.headline}</span>
						{props.subHeadline && <p>{props.subHeadline}</p>}
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}
