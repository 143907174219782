import * as React from "react";
import * as ReactDom from 'react-dom';
import { PasswordUpdate } from "./components/password-andern";

export const RenderPasswordUpdate = (): void => {
	const passwordUpdate: HTMLElement = document.querySelector("#passwordAndern");
	if (passwordUpdate){
		ReactDom.render(<PasswordUpdate />, passwordUpdate);
	}
    
};