import { buildTrboRequestUrl } from "../shared/Trbo/trbo-request-builder";
import { mapTrboResponseToProduktkarussellModel } from "../shared/Trbo/trbo-response-mapper";
import { IProduktkarussellModel } from "../models/i-product-carousel";
import { CreateSliderContent } from "./trbo-slider";
import { PromotionviewTracking } from "../functions/google-tracking";
import { SwiperSlider } from "./swiper-slider";

declare const UC_UI: any;
declare const _trbo: any;
const trboId = "ryxCCtrEX";

export const RenderTrboDynamicSliders = (): void => {
	const carousels = document.querySelectorAll(".trbo_dynamic-slider__carousel");
	if (carousels && carousels.length > 0) {
		if ((window as { [key: string]: any }).UC_UI !== undefined && UC_UI.isInitialized) {
			if (
				!UC_UI.getServicesBaseInfo().find((service: any) => service.id === trboId).consent.status
			) {
				carousels.forEach((carousel: HTMLElement) => {
					const serverWrapper = carousel.querySelector(".swiper-wrapper-server") as HTMLElement;
					if (serverWrapper.childElementCount === 0) {
						carousel.style.display = "none";
					} else {
						serverWrapper.style.display = "";
					}
				});

				if (
					UC_UI.getServicesBaseInfo().find((service: any) => service.id === trboId).consent
						.status &&
					(window as { [key: string]: any })._trbo === undefined
				) {
					setTimeout(() => {
						RenderTrboDynamicSliders();
					}, 100);
				}
			} else {
				carousels.forEach((carousel) => {
					const serverWrapper = carousel.querySelector(".swiper-wrapper-server") as HTMLElement;

					const trboSlot = carousel.getAttribute("data-trbo-slot");
					const carouselGuid = carousel.getAttribute("data-guid");
					const url = buildTrboRequestUrl(trboSlot);

					fetch(encodeURI(url))
						.then((response) => {
							response
								.json()
								.then((data) => {
									const model = mapTrboResponseToProduktkarussellModel(
										data
									) as IProduktkarussellModel;

									if (
										!model ||
										model.karussellItems.length === 0 ||
										carousel.querySelectorAll(`#product${carouselGuid}`).length === 0
									) {
										serverWrapper.style.display = "";

										return;
									}

									serverWrapper.remove();

									CreateSliderContent(model, carousel, true);

									/* scroll to the first slide , hacky */
									setTimeout(function() {
										SwiperSlider();
									}, 100);

									PromotionviewTracking();
								})
								.catch((error) => {
									console.log(error);
								});
						})
						.catch((error) => {
							console.log(error);
						});
				});
			}
		} else {
			setTimeout(() => {
				RenderTrboDynamicSliders();
			}, 100);
		}
	}
};

function hideSpinner(container: Element): void {
	const spinner = container.querySelector(".ajaxSpinner_Loading") as HTMLElement;
	const guid = container.getAttribute("data-guid");
	if (spinner) {
		spinner.classList.add("hide");
		container.querySelector(`#product${guid}`).remove();
	}
}
