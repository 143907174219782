import * as React from "react"
import { createRef, FunctionComponent, useState } from "react"
import { ICommercetoolsAddress } from "../../../ts/models/billing-address"
import { DeliveryAddresses } from "../../checkout-addresses/components//delivery-addresses"
import axios, { AxiosResponse } from "axios"
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner"
import { IResponseModelState } from "../../../ts/models/response-model-state"
import { ShowPopupError } from "../../../ts/components/error-popup"
import { IFormError } from "../../../ts/models/error/form-error"
import { ICustomer } from "../../../ts/models/customer"

interface IProps {
	ShippingAddresses: ICommercetoolsAddress[];
	BillingAdressId: string;
	IsPurchaser:boolean;
	IsEmployee:boolean;
	IsOnlineCustomer:boolean;
}

export const Versandadressen: FunctionComponent<IProps> = (props: IProps) => {
	const scrollToForm = createRef<HTMLDivElement>()
	const [currentEditShippingAddress, setcurrentEditShippingAddress] = useState<
		ICommercetoolsAddress
	>()

	const [existingShippingAddresses, setExistingShippingAddresses] = useState(
		props.ShippingAddresses
	)
	
	const [modelStateErrors, setModelStateErrors] = useState<IResponseModelState[]>()

	const [showShippingForm, setShowShippingForm] = useState(
		existingShippingAddresses.length < 1
	)

	function editShippingAddress(addressId: string) {
		setcurrentEditShippingAddress(
			existingShippingAddresses.find((el) => el.Id === addressId)
		)
		setShowShippingForm(true)
		if (scrollToForm.current) {
			scrollToForm.current.scrollIntoView({
				behavior: "smooth"
			})
		}
	}

	function removeShippingAddress(addressId: string) {
		ShowSpinner()
		const data = { addressId }
		axios
			.post(`/umbraco/api/address/deleteShippingAddress`, data)
			.then((result: AxiosResponse<ICommercetoolsAddress[]>) => {
				setExistingShippingAddresses(result.data)
				HideSpinner()
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError
					setModelStateErrors(object.ValidationErrors)
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner()
			})
	}

	function onShippingAddressFormSubmit(data: Record<string, any>) {
		ShowSpinner()
		data.relatedBusinessAddressId = props.BillingAdressId
		const url: string =
			data.id !== null && data.id !== ""
				? `/umbraco/api/address/updateShippingAddress`
				: `/umbraco/api/address/createShippingAddress`
		axios
			.post(url, data)
			.then((result: AxiosResponse<ICommercetoolsAddress[]>) => {
				setExistingShippingAddresses(result.data)
				HideSpinner()
				setShowShippingForm(false)
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError
					setModelStateErrors(object.ValidationErrors)
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner()
			})
	}

	const toggleShippingForm = () => {
		setShowShippingForm(!showShippingForm)
		setcurrentEditShippingAddress(null)
	}

	return (
		<React.Fragment>
			<div className="container">
				<DeliveryAddresses
					shippingAddresses={existingShippingAddresses}
					onSelectShippingAddress={null}
					currentShippingAddres={null}
					onFormSubmit={onShippingAddressFormSubmit}
					showShippingForm={showShippingForm}
					toggleForm={toggleShippingForm}
					onRemoveShippingAddress={removeShippingAddress}
					onEditShippingAddress={editShippingAddress}
					editShippingAddress={currentEditShippingAddress}
					modelStateErrors={modelStateErrors}
					addresBoxLarge={true}
					showRadioButton={false}
					scrollTo={scrollToForm}
					isEmployee={props.IsEmployee}
					isPurchaser = {props.IsPurchaser}
					isOnlineCustomer= {props.IsOnlineCustomer}
				/>
			</div>
		</React.Fragment>
	)
}
