import * as React from "react"
import { FunctionComponent, useState } from "react"
import axios, { AxiosResponse } from "axios"
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner"
import { PersonlicheDatenForm } from "./personliche-daten-form"
import { ICustomer } from "../../../ts/models/customer"
import { IFormError } from "../../../ts/models/error/form-error"
import { ShowPopupError } from "../../../ts/components/error-popup"
import { IResponseModelState } from "../../../ts/models/response-model-state"
import { fullProfessionList } from "../../../ts/constants/form-values"

interface IProps {
	Customer: ICustomer
}

export const PersonlicheDaten: FunctionComponent<IProps> = (props: IProps) => {
	const [showForm, setShowForm] = useState(false)
	const [showCustomerInfo, setShowCustomerInfo] = useState(true)

	const [existingCustomer, setExistingCustomer] = useState(props.Customer)

	const toggleForm = () => {
		setShowForm(!showForm)
		setShowCustomerInfo(!showCustomerInfo)
	}

	const toggleCutomerInfo = () => {
		setShowCustomerInfo(!showCustomerInfo)
	}

	function onPersonlicheDataFormSubmit(data: Record<string, any>) {
		ShowSpinner()
		axios
			.post(`/umbraco/api/user/UpdateUser`, data)
			.then((result: AxiosResponse<ICustomer>) => {
				HideSpinner()
				setExistingCustomer(result.data)
				toggleForm()
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage)
					}
				}
				HideSpinner()
			})
	}

	const mapProfession = (professions: string[]): string => {
		if (professions != null && professions.length > 0) {
			const profession = professions[0]
			const mappedProfession = fullProfessionList.find((x) => x.value === profession)
			return mappedProfession != null ? mappedProfession.name : profession
		}

		return ""
	}

	return (
		<>
			<div>
				{showCustomerInfo && (
					<div>
						<span className="heading">Persönliche Daten</span>
						<hr />
					</div>
				)}
				<div className="data">
					{showCustomerInfo && (
						<div>
							<p className="intro">
								Hallo{" "}
								{existingCustomer.Gender == "M"
									? "Herr"
									: existingCustomer.Gender == "F"
									? "Frau"
									: "Divers"}{" "}
								{existingCustomer.Surname}!
							</p>
							<div className="row">
								<div className="col-4 col-md-3 disabled-info extra-info intro">
									Benutzername{" "}
									<span className="form-hint">
										<br />
										(nicht veränderbar)
									</span>
								</div>
								<div className="col-8 col-md-9 disabled-info">{existingCustomer.Username}</div>
								<div className="col-4 col-md-3 intro extra-info">
									E-Mail-Adresse
									<br />
									<span className="form-hint">(zur Kommunikation)</span>
								</div>
								<div className="col-8 col-md-9">{existingCustomer.Email}</div>
								<div className="col-4 col-md-3 intro">Anrede</div>
								<div className="col-8 col-md-9">
									{existingCustomer.Gender == "M"
										? "Herr"
										: existingCustomer.Gender == "F"
										? "Frau"
										: "Divers"}
								</div>
								<div className="col-4 col-md-3 intro">Titel</div>
								<div className="col-8 col-md-9">{existingCustomer.Title}</div>
								<div className="col-4 col-md-3 intro">Vorname</div>
								<div className="col-8 col-md-9">{existingCustomer.Prename}</div>
								<div className="col-4 col-md-3 intro">Nachname</div>
								<div className="col-8 col-md-9">{existingCustomer.Surname}</div>
								<div className="col-4 col-md-3 intro">Beruf/Funktion</div>
								<div className="col-8 col-md-9">{mapProfession(existingCustomer.Professions)}</div>
								<div className="col-4 col-md-3 intro">Passwort</div>
								<div className="col-8 col-md-9">
									<a href="/meine-daten/passwort-andern" className="link--underline">
										<i className="fas fa-chevron-right icon-red" />
										Passwort ändern
									</a>
								</div>
								<div className="col-12 col-md-12 my-3">
									<button
										onClick={() => toggleForm()}
										className="button button__cta button--red order__button"
									>
										<span className="txt">Daten ändern</span>
										<i className="fas fa-arrow-right icon-fa icon--white" />
									</button>
								</div>
							</div>
						</div>
					)}
					{showForm && (
						<PersonlicheDatenForm
							toggleForm={toggleForm}
							customer={existingCustomer}
							onFormSubmit={onPersonlicheDataFormSubmit}
						/>
					)}
				</div>
			</div>
		</>
	)
}
