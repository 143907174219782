import * as React from "react";
import * as ReactDom from 'react-dom';
import { Logout} from "./components/logout";

export const RenderLogout = (): void => {
	const logout: HTMLElement = document.querySelector("#logout");
	if (logout){
		ReactDom.render(<Logout />, logout);
	}
       

};