
const registerButton: HTMLElement = document.querySelector("#registrieren_button");
const register: HTMLElement = document.querySelector(".registerContainer");
const loginContainer: HTMLElement = document.querySelector(".loginContainer");
const backToLogin: HTMLElement = document.querySelector(".backToLogin");

function HideRegisterForm(): void {
    register.classList.add("hide");
    loginContainer.classList.remove("hide");
}

function ShowRegisteForm(): void {
    register.classList.remove("hide");
    loginContainer.classList.add("hide");
}

export const RegisterButton = (): void => {
    if (registerButton) {
        registerButton.addEventListener("click", ShowRegisteForm);
    }
    if (backToLogin) {
        backToLogin.addEventListener("click", HideRegisterForm);
    }
};