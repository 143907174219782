import * as React from "react";
import { FunctionComponent } from "react";
import { ICommercetoolsAddress } from "../../../ts/models/billing-address";
import { countryList } from "../../../ts/constants/form-values";

interface IProps {
	address: ICommercetoolsAddress;
	type: string;
	onRemoveAddress: Function;
	onEditAddress: Function;
	onSelect: Function;
	currentAddress: string;
	title: string;
	addresBoxLarge: boolean;
	showRadioButton: boolean;
	isOnlineCustomer?: boolean;
	isPurchaser?: boolean;
	isEmployee?: boolean;
	isParticipant?: boolean;
	hideDelete?: boolean;
	cartId?: string;
	lineItemId?: string;
}

export const AddressBox: FunctionComponent<IProps> = (props: IProps) => {
	const { address, onRemoveAddress, onEditAddress } = props;

	return (
		<React.Fragment key={address.Id}>
			<div
				className={
					props.addresBoxLarge ? "col-xl-4 col-lg-6 col-md-12" : "col-lg-3 col-md-4 col-sm-12"
				}
			>
				<div className="billing__box">
					<div className="form-check headline">
						<div className="radio-item" onClick={() => props.onSelect(address.Id)}>
							{props.showRadioButton && (
								<input
									type="radio"
									id={address.Id}
									value={address.Id}
									name={props.type}
									checked={props.currentAddress === address.Id}
								/>
							)}
							<label htmlFor={address.Id}>{props.title}</label>
						</div>
					</div>
					<div className="content">
						<p>
							{!props.isParticipant && (
								<>
									<span className="d-block company">{address.Company}</span>
									<span className="d-block additional_info">{address.CompanyAddition1}</span>
									<span className="d-block additional_info">{address.CompanyAddition2}</span>
									<span className="d-block name">
										<span className="anrede">
											{address.Salutation == "M" ? "Herr" : address.Salutation == "F" ? "Frau" : ""}{" "}
										</span>
										<span className="titel">{address.Title} </span>
										{address.FirstName} {address.LastName}
									</span>
									<span className="d-block additional_info">{props.address.Profession}</span>
								</>
							)}
							{props.isParticipant && (
								<>
									<span className="d-block company">
										<span className="anrede">
											{address.Salutation == "M" ? "Herr" : address.Salutation == "F" ? "Frau" : ""}{" "}
										</span>
										<span className="titel">{address.Title} </span>
										{address.FirstName} {address.LastName}
									</span>
									<span className="d-block additional_info">{props.address.Profession}</span>
									<span className="d-block">{address.Company}</span>
									<span className="d-block additional_info">{address.CompanyAddition1}</span>
									<span className="d-block additional_info">{address.CompanyAddition2}</span>
								</>
							)}

							{address.CustomerNo && (
								<span className="d-block kundennummer">Kd-Nr: {address.CustomerNo}</span>
							)}
							<span className="d-block abteilung">{address.Department}</span>
							<span className="d-block adresszusatz">{address.AdditionalInfo}</span>
							<span className="d-block strasse">
								{address.StreetName} {address.StreetNumber}
								{address.PostfachNo && `Postfach ${address.PostfachNo}`}
							</span>
							<span className="d-block ort">
								{address.PostalCode} {address.City}
							</span>
							<span className="d-block land">
								{countryList.find((x) => x.value === address.Country)?.name ?? address.Country}
							</span>
							<span className="d-block land">{address.VatNo}</span>
							<span className="d-block additional_info">{address.Email}</span>
							<span className="d-block additional_info">{address.Phone}</span>
						</p>
						{(((!props.isOnlineCustomer || props.type !== "billingaddress") &&
							!props.isPurchaser &&
							!props.isEmployee) ||
							props.isParticipant) && (
							<span className="text-right d-block edit">
								{!props.isParticipant &&
									props.type !== "billingaddress" &&
									props.type !== "shippingaddress" && (
										<>
											<button
												onClick={() => onEditAddress(address.Id)}
												className="link clear"
												type="button"
											>
												bearbeiten
											</button>{" "}
											|{" "}
										</>
									)}
								{!props.hideDelete && (
									<button
										onClick={() => onRemoveAddress(address.Id)}
										className="link clear"
										type="button"
									>
										löschen
									</button>
								)}
								{props.isParticipant && props.hideDelete && (
									<button
										onClick={() => props.onSelect(address.Id, props.cartId, props.lineItemId)}
										className="link clear"
										type="button"
									>
										hinzufügen
									</button>
								)}
							</span>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
