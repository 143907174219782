import * as React from "react";
import * as ReactDom from 'react-dom';
import { ICommercetoolsAddress } from "../../../ts/models/billing-address";
import { Participants } from "./components/participants";

export const RenderMeineDatenParticipants = (): void => {
    const participants: HTMLElement = document.querySelector("#participants");
	if (participants){
		const shippingaddressesJson = participants.getAttribute("data-participants");
		const shippingAddresses = JSON.parse(shippingaddressesJson) as ICommercetoolsAddress[];

		const billingAdressId = participants.getAttribute("data-billingadressid");

		const isOnlineCustomerJson = participants.getAttribute("data-isOnlineCustomer");
        const isOnlineCustomer = JSON.parse(isOnlineCustomerJson) as boolean;

		const isPurchaserJson = participants.getAttribute("data-isPurchaser");
        const isPurchaser = JSON.parse(isPurchaserJson) as boolean;

        const isEmployeeJson = participants.getAttribute("data-isEmployee");
        const isEmployee = JSON.parse(isEmployeeJson) as boolean;
		
		ReactDom.render(<Participants  IsOnlineCustomer={isOnlineCustomer} IsEmployee={isEmployee}
            IsPurchaser={isPurchaser} Particpants={shippingAddresses}  BillingAdressId={billingAdressId}/>, participants);
	}
    
};