import * as React from "react"
import { FunctionComponent, useState } from "react"
import axios, { AxiosResponse } from "axios";
import { ICommercetoolsCart } from "../../../ts/models/icommercetools-cart"
import { ILineitem } from "../../../ts/models/line-item"
import { BasketLineItem } from "./basket-lineitem"
import { BasketTotalPrice } from "./basket-totalprice"
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner"
import { IFormError } from "../../../ts/models/error/form-error"
import { ShowPopupError } from "../../../ts/components/error-popup"
import { updateLineItemTracking, removeItemFromCartTracking} from "../../../ts/functions/google-tracking"
import { ItemCountForBasket } from "../../../ts/shared/mini-cart";

interface ICartPros {
    Cart: ICommercetoolsCart;
}

export const Basket: FunctionComponent<ICartPros> = (
	props: ICartPros
) => {

	const [cart, setCart] = useState(props.Cart)
	
	function updateLineItem(lineItemId: string, engagementId: string, quantity: number, productId: number, actionName: string) {
		ShowSpinner();
		const data = {lineItemId, engagementId, quantity, productId, actionName};

		updateLineItemTracking(cart, 1, lineItemId, actionName)
		axios.post<ICommercetoolsCart>(`/umbraco/api/lineItem/Update`, data)
		.then((result: AxiosResponse<ICommercetoolsCart>) => {
			HideSpinner();
			setCart(result.data);
		})
		.catch((error: any) => {
			if (error?.response?.data) {
				const object = JSON.parse(error?.response?.data?.Message) as IFormError
				if (object.ErrorMessage) {
					ShowPopupError(object.ErrorMessage);
				}
			}
			HideSpinner()
		})
	}

	function  removeItem(lineItemId: string, productId: number) {
		ShowSpinner();
			const data = {lineItemId, productId};

			const qunatity = cart.Cart.LineItems.find((x) => x.Id === lineItemId).Quantity;
			removeItemFromCartTracking(cart, qunatity, lineItemId)

			axios.post<ICommercetoolsCart>(`/umbraco/api/lineItem/Delete`, data)
			.then((result: AxiosResponse<ICommercetoolsCart>) => {
				HideSpinner();
				setCart(result.data);
				ItemCountForBasket();
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner();
			})
	}

    return (
        <div className="checkout__area">
			<div className="header">
				<div className="row">
					<div className="col-md-4 pl-4 head-cell ">
						Artikel
					</div>
					<div className="col-md-3 head-cell d-none d-lg-block">
						Teilnehmer*
					</div>
					<div className="col-md-2 head-cell d-none d-lg-block">
						Einzelpreis
					</div>
					<div className="col-md-3 pr-4 text-right head-cell d-none d-lg-block">
						Gesamtpreis
					</div>
				</div>
			</div>

			<div className="item__list">
				{cart.Cart.LineItems.map((lineItem: ILineitem) => (
					<BasketLineItem key={lineItem.Id} onRemoveItem={removeItem} lineItem={lineItem} updateLineItem={updateLineItem}/>
				))}
			</div>

			<BasketTotalPrice Cart={cart}/>

			<div className="hint d-none d-lg-block">
				<div className="row">
					<div className="col-12">* Bei mehreren Teilnehmern geben Sie bitte die Teilnehmerdaten im Buchungsschritt 4 „Teilnehmer“ ein.</div>
				</div>
			</div>

		</div>
    )
}