import * as React from "react";
import * as ReactDom from 'react-dom';
import { INewsletterGrid } from "../../ts/models/newsletter-grid";
import { Newsletter } from "./components/newsletter"
export const RenderNewsletterGrid = (): void => {
    const newsletterGrid: HTMLElement = document.querySelector("#newsletterGriElement");
    
	if (newsletterGrid){
        const newsletterGridJson = newsletterGrid.getAttribute("data-newsletterModel");
        const recaptchaSiteKey = newsletterGrid.getAttribute("data-sitekey");
        const grid = JSON.parse(newsletterGridJson) as INewsletterGrid;
		ReactDom.render(<Newsletter NewsletterGrid={grid} SiteKey={recaptchaSiteKey}/>, newsletterGrid);
	}

};
