import { Logout } from "./components/logout";
import * as React from "react";
import * as ReactDom from 'react-dom';

export const RenderMeineDatenLogout = (): void => {
    const logout: HTMLElement = document.querySelector("#meineDatenLogout");
	if (logout){
        const pageAlias = logout.getAttribute("data-logout");
        ReactDom.render(<Logout PageAlias={pageAlias}/>, logout);
	}  
};