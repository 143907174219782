const CLASSNAME_TO_OPEN_NAVIGATION = "open";
const CLASSNAME_TO_OPEN_SUBMENU_PANEL = "panelOpen";
const CLASSNAME_TO_OPEN_SUBMENU_LIST_ITEM = "subView";

// open and close selectors
const mobileNavMenu: HTMLElement = document.querySelector("#mobileMenu");
const mobileNavButtonOpen: HTMLElement = document.querySelector("#openMobile");
const mobileNavButtonClose: HTMLElement = document.querySelector(
	"#closeMobile"
);

// submenu selectors
const subMenuTriggerList: NodeListOf<HTMLElement> = document.querySelectorAll(
	".subPanelTrigger"
);
const subMenuCloseList: NodeListOf<HTMLElement> = document.querySelectorAll(
	".subPanelClose"
);

function openMobileNav(): void {
	mobileNavMenu.classList.toggle(CLASSNAME_TO_OPEN_NAVIGATION);
}

function toggleSubMenu(menuPanel: HTMLElement, listElement: HTMLElement): void {
	menuPanel.classList.toggle(CLASSNAME_TO_OPEN_SUBMENU_PANEL);
	listElement.classList.toggle(CLASSNAME_TO_OPEN_SUBMENU_LIST_ITEM);
}

function subMenuTriggerFunction(): void {
	const currentMenuPanel = this.closest("ul.menuPanel");
	const currentListElement = this.closest("li.menuPanelSub");
	if (currentListElement && currentMenuPanel) {
		toggleSubMenu(currentMenuPanel, currentListElement);
	}
}

export const MobileMenu = (): void => {
	if (mobileNavButtonOpen) {
		mobileNavButtonOpen.addEventListener("click", openMobileNav);
	}

	if (mobileNavButtonClose) {
		mobileNavButtonClose.addEventListener("click", openMobileNav);
	}

	if (subMenuTriggerList.length > 0) {
		subMenuTriggerList.forEach(subMenuTrigger => {
			subMenuTrigger.addEventListener("click", subMenuTriggerFunction);
		});
	}

	if (subMenuCloseList.length > 0) {
		subMenuCloseList.forEach(subMenuClose => {
			subMenuClose.addEventListener("click", subMenuTriggerFunction);
		});
	}
};
