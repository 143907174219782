import * as React from "react";
import * as ReactDom from 'react-dom';
import { ICommercetoolsAddress } from "../../ts/models/billing-address"
import { ICustomer } from "../../ts/models/customer";
import { ICommercetoolsCart } from "../../ts/models/icommercetools-cart";
import { CheckoutAddresses } from "./components/checkout-addresses";
import { GoogleAnalytictsTrackingCheckout} from "../../ts/functions/google-tracking"

export const RenderAddresses = (): void => {
    const checkoutAddresses: HTMLElement = document.querySelector("#checkoutAddresses");
    
	if (checkoutAddresses){
        const billingaddressesJson = checkoutAddresses.getAttribute("data-billingaddresses");
        const billignAddresses = JSON.parse(billingaddressesJson) as ICommercetoolsAddress[];

        const shippingAddressesJson = checkoutAddresses.getAttribute("data-shippingaddresses");
        const shippingAddresses = JSON.parse(shippingAddressesJson) as ICommercetoolsAddress[];

        const cartJson = checkoutAddresses.getAttribute("data-cart");
        const cart = JSON.parse(cartJson) as ICommercetoolsCart;

        const customerJson = checkoutAddresses.getAttribute("data-customer");
        const customer = JSON.parse(customerJson) as ICustomer;

        const isPurchaserJson = checkoutAddresses.getAttribute("data-isPurchaser");
        const isPurchaser = JSON.parse(isPurchaserJson) as boolean;

        const isEmployeeJson = checkoutAddresses.getAttribute("data-isEmployee");
        const isEmployee = JSON.parse(isEmployeeJson) as boolean;

        GoogleAnalytictsTrackingCheckout(cart, "3");

		ReactDom.render(<CheckoutAddresses Cart={cart} BillingAddresses={billignAddresses} ShippingAddresses={shippingAddresses} Customer={customer}
            IsEmployee={isEmployee}
            IsPurchaser={isPurchaser}
        />, checkoutAddresses);
	}
};  