import * as React from "react"
import { ChangeEvent, FunctionComponent, useRef, useState } from "react"
import axios, { AxiosResponse } from "axios"
import * as faSearch from "@fortawesome/free-solid-svg-icons/faSearch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
	EAutoSuggestType,
	IFactFinderAutoSuggestWrapper
} from "../interfaces/i-fact-finder-auto-suggest"
import { FlapOutDisplayFilter } from "./sub-components/flap-out-display-filter"
import { useComponentVisible } from "../../helper/use-component-visible"

interface IFlapOutReactProps {
	data?: IFactFinderAutoSuggestWrapper
}

export const FlapOutReact: FunctionComponent<IFlapOutReactProps> = (
	props: IFlapOutReactProps
) => {
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(
		true
	)

	const inputRef = useRef({} as HTMLInputElement)

	const [searchQuery, setSearchQuery] = useState("" as string)
	const [searchResult, setSearchResult] = useState({
		Suggestions: []
	} as IFactFinderAutoSuggestWrapper)

	function startSearch(event: React.ChangeEvent): void {
		const query = (event.target as HTMLInputElement).value
		setSearchQuery(query)
		if (!query) {
			return;
		}

		axios
			.get<IFactFinderAutoSuggestWrapper>(
				`/umbraco/api/factfinder/autosuggest?query=${encodeURIComponent(query)}`
			)
			.then((result: AxiosResponse<IFactFinderAutoSuggestWrapper>) => {
				return setSearchResult(result.data)
			})
			.catch((error: any) => {
				
			})
	}

	return (
		<>
			<form action="/suche">
				<div ref={ref} className="search-field form-inline">
					<input
						name="query"
						className="form-control input input__search"
						type="text"
						ref={inputRef}
						placeholder="Veranstaltungsname, Suchbegriff, Webcode ..."
						onClick={() => setIsComponentVisible(true)}
						value={searchQuery}
						onChange={(event: ChangeEvent): void => startSearch(event)}
						id="searchInput"
					/>
					<button className="button button--red button__search" type="submit">
						<FontAwesomeIcon icon={faSearch} className="icon-fa icon--white" />
					</button>
				</div>
			</form>
			{searchResult && isComponentVisible && searchResult.Suggestions.length > 0 && (
				<div className="flapout__wrapper" id="flapoutWrapper">
					<div className="autosuggest flapout__body flapout--white">
						<div className="row">
							<div className="col-12 col-lg-6">
								<FlapOutDisplayFilter
									searchResult={searchResult}
									searchQuery={searchQuery}
									filterType={EAutoSuggestType.searchTerm}
									headline="Suchvorschläge"
								/>
								<FlapOutDisplayFilter
									searchResult={searchResult}
									filterType={EAutoSuggestType.category}
									searchQuery={searchQuery}
									headline="Kategorievorschläge"
								/>
							</div>
							<div className="col-12 col-lg-6">
								<FlapOutDisplayFilter
									searchResult={searchResult}
									filterType={EAutoSuggestType.productName}
									searchQuery={searchQuery}
									headline="Veranstaltungsvorschläge"
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
