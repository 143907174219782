import * as React from "react"
import { createRef, FunctionComponent, useState } from "react"
import { ICommercetoolsAddress } from "../../../ts/models/billing-address"
import { BillingAddresses } from "../../checkout-addresses/components/billing-addresses"
import axios, { AxiosResponse } from "axios"
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner"
import { IResponseModelState } from "../../../ts/models/response-model-state"
import { IFormError } from "../../../ts/models/error/form-error"
import { ShowPopupError } from "../../../ts/components/error-popup"
import { ICustomer } from "../../../ts/models/customer"

interface IProps {
	BillingAddresses: ICommercetoolsAddress[];
	IsOnlineCustomer:boolean;
	IsPurchaser:boolean;
	IsEmployee:boolean;
}

export const Rechnungsadressen: FunctionComponent<IProps> = (props: IProps) => {
	const scrollToForm = createRef<HTMLDivElement>()

	const [existingBillingAddresses, setExistingBillingAddresses] = useState(
		props.BillingAddresses
	)

	const [currentEditBillingAddress, setcurrentEditBillingAddress] = useState<
		ICommercetoolsAddress
	>()

	const [modelStateErrors, setModelStateErrors] = useState<IResponseModelState[]>()

	const [showBillingForm, setShowBillingForm] = useState(
		existingBillingAddresses.length < 1
	)

	function editAddress(addressId: string) {
		setcurrentEditBillingAddress(
			existingBillingAddresses.find((el) => el.Id === addressId)
		)
		setShowBillingForm(true)
		if (scrollToForm.current) {
			scrollToForm.current.scrollIntoView({
				behavior: "smooth"
			})
		}
	}

	function removeBillingAddress(addressId: string) {
		ShowSpinner()
		const data = { addressId }
		axios
			.post(`/umbraco/api/address/deleteBillingAddress`, data)
			.then((result: AxiosResponse<ICommercetoolsAddress[]>) => {
				setExistingBillingAddresses(result.data)
				HideSpinner()
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError
					setModelStateErrors(object.ValidationErrors)
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner()
			})
	}

	function onBillingAddressFormSubmit(data: Record<string, any>) {
		ShowSpinner()
		const url: string =
			data.id !== null && data.id !== ""
				? `/umbraco/api/address/UpdateBillingAddress`
				: `/umbraco/api/address/createBillingAddress`
		axios
			.post(url, data)
			.then((result: AxiosResponse<ICommercetoolsAddress[]>) => {
				setExistingBillingAddresses(result.data)
				HideSpinner()
				setShowBillingForm(false)
			})
			.catch((error) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError
					setModelStateErrors(object.ValidationErrors)
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner()
			})
	}

	const toggleBillingForm = () => {
		setShowBillingForm(!showBillingForm)
		setcurrentEditBillingAddress(null)
	}

	return (
		<React.Fragment>
			<div className="container">
				<BillingAddresses
					billingAddresses={existingBillingAddresses}
					onSelectBillingAddress={null}
					currentAddres={null}
					onFormSubmit={onBillingAddressFormSubmit}
					showBillingForm={showBillingForm}
					toggleForm={toggleBillingForm}
					onRemoveAddress={removeBillingAddress}
					onEditAddress={editAddress}
					editAddress={currentEditBillingAddress}
					modelStateErrors={modelStateErrors}
					addresBoxLarge={true}
					showRadioButton={false}
					scrollTo={scrollToForm}
					isOnlineCustomer={props.IsOnlineCustomer}
					isEmployee={props.IsEmployee}
					isPurchaser = {props.IsPurchaser}
				/>
			</div>
		</React.Fragment>
	)
}
