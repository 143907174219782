import * as React from "react";
import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ICommercetoolsCart } from "../../../ts/models/icommercetools-cart";
import { ParticipantBox } from "./participant-box";
import { ILineitem } from "../../../ts/models/line-item";
import { IParticipantResponse } from "../../../ts/models/participant-response";
import { IParticipantData } from "../../../ts/models/participant-data";
import { AddButton } from "../../shared/checkout/add-button";
import { ICommercetoolsAddress } from "../../../ts/models/billing-address";
import { AddressBox } from "../../checkout-addresses/components/address-box";
import { ParticipantForm } from "../../meine-daten/participants/components/participant-form";
import { IResponseModelState } from "../../../ts/models/response-model-state";

interface IProps {
	cart: ICommercetoolsCart;
	lineItemParticipants: IParticipantResponse[];
	participantAddresses: ICommercetoolsAddress[];
	showForm: boolean;
	currentParticipant: IParticipantData;
	updateParticipantQuantity: Function;
	onFormSubmit: Function;
	onRemoveParticipant: Function;
	addParticipant: Function;
	modelStateErrors?: IResponseModelState[];
}

export const SeminarInfo: FunctionComponent<IProps> = (props: IProps) => {
	function CreateBox(lineItem: ILineitem) {
		const participantsBoxes: JSX.Element[] = [];
		let counter = 0;
		if (props.lineItemParticipants.length != 0) {
			props.lineItemParticipants.map((lineItemwWithParcipants: IParticipantResponse) => {
				if (
					lineItemwWithParcipants.LineItemId == lineItem.Id &&
					lineItemwWithParcipants.ParticipantData != null
				) {
					lineItemwWithParcipants.ParticipantData.map((participant, i) => {
						counter += 1;
						participantsBoxes.push(
							<ParticipantBox
								key={`${lineItem.Id}-${counter}`}
								cartId={lineItemwWithParcipants.CartId}
								quantity={lineItem.Quantity}
								productId={lineItem.Product.ProductId}
								lineItemId={lineItemwWithParcipants.LineItemId}
								counter={counter}
								participant={participant}
								onRemoveParticipant={props.onRemoveParticipant}
							/>
						);
					});
				}
			});
		}

		return participantsBoxes;
	}

	const showAddressBox = (lineItemId: string, addressId: string): boolean => {
		if (props.lineItemParticipants.length === 0) {
			return true;
		}
		const lineItem = props.lineItemParticipants.find((x) => x.LineItemId === lineItemId);
		if (lineItem === null || lineItem === undefined || lineItem.ParticipantData === null) {
			return true;
		}
		if (lineItem.ParticipantData.find((x) => x.RelatedAddressId === addressId) !== undefined) {
			return false;
		}
		return true;
	};

	const showMissingParticipantsError = (lineItem: ILineitem): boolean => {
		const participants = props.lineItemParticipants.find((x) => x.LineItemId === lineItem.Id);
		if (
			participants === undefined ||
			participants === null ||
			participants.ParticipantData === null ||
			participants.ParticipantData === undefined
		) {
			return true;
		}
		if (participants.ParticipantData.length < lineItem.Quantity) {
			return true;
		}
		return false;
	};

	const showReduceParticpantsField = (lineItem: ILineitem): boolean => {
		const participants = props.lineItemParticipants.find((x) => x.LineItemId === lineItem.Id);
		if (
			participants === undefined ||
			participants === null ||
			participants.ParticipantData === null ||
			participants.ParticipantData === undefined
		) {
			return true;
		}
		if (participants.ParticipantData.length < lineItem.Quantity) {
			return true;
		}
		return false;
	};

	const getNoParticipantsErrorText = (lineItem: ILineitem): string => {
		const participants = props.lineItemParticipants.find((x) => x.LineItemId === lineItem.Id);
		if (
			participants === undefined ||
			participants === null ||
			participants.ParticipantData === null ||
			participants.ParticipantData === undefined ||
			participants.ParticipantData.length === 0
		) {
			return "Bitte fügen Sie Teilnehmer hinzu.";
		}
		return "Bitte fügen Sie weitere Teilnehmer hinzu.";
	};

	const renderTooltipAvailableParticipants = (props: any) => (
		<Tooltip className="participant__tooltip" {...props}>
			Hier finden Sie die verfügbaren Teilnehmer. Mit einem Klick auf "hinzufügen", fügen Sie den
			Teilnehmer der Seminarbuchung hinzu. Die Teilnehmer können Sie im Bereich "Meine Daten" unter
			dem Punkt "Teilnehmerverwaltung" verwalten.
		</Tooltip>
	);

	const renderTooltipSelectedParticipants = (props: any) => (
		<Tooltip className="participant__tooltip" {...props}>
			Hier finden Sie alle Teilnehmer, die für diese Veranstaltung gebucht werden. Mit einem Klick
			auf "entfernen" können Sie einen Teilnehmer aus der Buchung entfernen. Über "Neuen Teilnehmer
			anlegen" können Sie einen neuen Teilnehmer anlegen und der Buchung direkt hinzufügen.
		</Tooltip>
	);

	const [showForm, setShowForm] = useState(props.showForm);

	const toggleForm = () => {
		setShowForm(!showForm);
	};

	useEffect(() => {
		setShowForm(false);
	}, [props.showForm]);

	return (
		<>
			{props.cart.Cart.LineItems.map((lineItem: ILineitem, i) => (
				<div className="participant" key={lineItem.Id}>
					<div className="seminar__info__container">
						<div className="header">
							<div className="row">
								<div className="col-md-8 pl-4 head-cell ">Veranstaltung {i + 1}</div>
								<div className="col-md-3 pl-4 head-cell d-none d-lg-block">Teilnehmer</div>
							</div>
						</div>
						<div className="seminar__info">
							<div className="row item">
								<div className="col-md-8 pl-4">
									<span className="item__heading">{lineItem.Product.TitleMain}</span>
									<span className="item__place">
										{lineItem.Product.SeminarInfo.Engagement.Hotel.City},{" "}
										{lineItem.Product.SeminarInfo.Engagement.Hotel.Title}
									</span>
									<span className="item__date">
										{lineItem.Product.SeminarInfo.Engagement.DateSummary}
									</span>
								</div>
								<div className="col-md-3 pl-4">
									<span className="item__participants">
										<span className="d-inline d-md-none">Teilnehmer: </span>
										{(showMissingParticipantsError(lineItem) === false ||
											lineItem.Quantity === 1) &&
											lineItem.Quantity}
										{showMissingParticipantsError(lineItem) && lineItem.Quantity > 1 && (
											<div className="item__list">
												<div className="counter__elements">
													<a
														className="counter--sub"
														onClick={() =>
															props.onRemoveParticipant(
																props.cart.CartId,
																lineItem.Id,
																null,
																lineItem.Quantity - 1,
																lineItem.Product.ProductId
															)
														}
													>
														{" "}
														-{" "}
													</a>
													<input
														type="number"
														className="counter lineitem_count"
														min="1"
														value={lineItem.Quantity}
													/>
												</div>
											</div>
										)}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="row participant__boxes px-2">
						<div className="col-12 pb-1">
							<div className="participants">
								<span className="heading heading__sub">
									Gebuchte Teilnehmer Für diese Veranstaltung
								</span>
								<OverlayTrigger
									placement="bottom"
									delay={{ show: 250, hide: 400 }}
									overlay={renderTooltipSelectedParticipants}
								>
									<FontAwesomeIcon icon={faInfoCircle} />
								</OverlayTrigger>
							</div>
							{showMissingParticipantsError(lineItem) && (
								<span className="form-error bold">{getNoParticipantsErrorText(lineItem)}</span>
							)}
						</div>
						{CreateBox(lineItem)}
					</div>
					<div className="row participant__boxes px-2">
						<div className="col-12 pb-1">
							<div className="participants">
								<span className="heading heading__sub">Verfügbare Teilnehmer</span>
								<OverlayTrigger
									key="asd"
									placement="bottom"
									delay={{ show: 250, hide: 400 }}
									overlay={renderTooltipAvailableParticipants}
								>
									<FontAwesomeIcon icon={faInfoCircle} />
								</OverlayTrigger>
							</div>
						</div>
						<div className="col-12">
							<AddButton buttonText="Neuen Teilnehmer anlegen" onClick={toggleForm} />
						</div>
						{showForm && (
							<section
								aria-label="Neuer Teilnehmer Formular"
								aria-live="polite"
								className="col-12 mb-5"
							>
								<ParticipantForm
									editAddress={null}
									toggleForm={toggleForm}
									onFormSubmit={props.onFormSubmit}
									showHeading
									modelStateErrors={props.modelStateErrors}
									cartId={props.cart.CartId}
									lineItemId={lineItem.Id}
								/>
							</section>
						)}
						{props.participantAddresses.map(
							(address: ICommercetoolsAddress) =>
								showAddressBox(lineItem.Id, address.Id) && (
									<AddressBox
										key={address.Id}
										address={address}
										onRemoveAddress={null}
										onEditAddress={null}
										type="shippingaddress"
										onSelect={props.addParticipant}
										currentAddress={null}
										title="Teilnehmer"
										addresBoxLarge
										showRadioButton={false}
										isParticipant
										hideDelete
										cartId={props.cart.CartId}
										lineItemId={lineItem.Id}
									/>
								)
						)}
					</div>
				</div>
			))}
		</>
	);
};
