import * as React from "react"
import { ChangeEvent, FunctionComponent, useState } from "react"
import { ILineitem } from "../../../ts/models/line-item"

interface ILineItemPros {
	lineItem: ILineitem
	participantsCounter: number
}

export const BasketLineItem: FunctionComponent<ILineItemPros> = (
	props: ILineItemPros
) => {
	const { lineItem } = props

	return (
		<React.Fragment key={lineItem.Id}>
			<div className="row item item__list">
				<div className="col-md-5 pl-4">
					<a
						href={`/${lineItem.Product.SeoOptimizedUrlPart}/product/${lineItem.Product.ProductId}`}
						className="item__heading"
					>
						{lineItem.Product.TitleMain}
					</a>
					<span className="item__place">
						{lineItem.Product.SeminarInfo.Engagement.Hotel.City},{" "}
						{lineItem.Product.SeminarInfo.Engagement.Hotel.Title}
					</span>
					<span className="item__date">
						{lineItem.Product.SeminarInfo.Engagement.DateSummary}
					</span>
				</div>
				<div className="col-md-2 pl-4">
					<span className="item__participants">
						<span className="d-inline d-md-none">Teilnehmer: </span>
						{props.participantsCounter}
					</span>
				</div>
				<div className="col-md-2 pl-4">
					{(lineItem.SingleItemNetPrice.Amount / 100).toLocaleString("de-De", {
						style: "currency",
						currency: lineItem.SingleItemNetPrice.Currency.CurrencyISOCode
					})}
					<span className="item__mwst"> zzgl. gesetzl. MwSt.</span>
				</div>
				<div className="col-md-3 pr-4 text-right">
					<span className="item__fullprice">
						{(lineItem.TotalItemNetPrice.Amount / 100).toLocaleString("de-De", {
							style: "currency",
							currency: lineItem.TotalItemNetPrice.Currency.CurrencyISOCode
						})}
					</span>
					<span className="item__mwst">zzgl. gesetzl. MwSt. </span>
					<span className="item__mwst">{lineItem.SingleItemNetPrice.PriceDescriptionShort}</span>
					{lineItem.TotalItemNetPriceNonDiscountedOriginal && (
                    <div>
                        <span className="item__pricetype discount item__mwst">inkl. Kollegenrabatt</span>
                        <span className="item__fullprice"><s>{(lineItem.TotalItemNetPriceNonDiscountedOriginal?.Amount / 100).toLocaleString('de-De', { style: 'currency', currency: lineItem.TotalItemNetPrice.Currency.CurrencyISOCode })}</s></span>
                        <span className="item__pricetype item__mwst">regulärer Gesamtpreis</span>
                    </div>)
                }
				</div>
			</div>
		</React.Fragment>
	)
}
