const parseSegments = function() {
    let segments = {};
    if (typeof window !== 'undefined' && window && window._trbo) {
        segments = window._trbo.udp.data.segmentData;
    }

    return segments;
}

/**
 * unique trbo user id
 */
const getUserId = function() {
    return JSON.parse(localStorage._trbo_ud).user.userId;;
};

/**
 * current session id of user
 */
const getSessionId = function() {
    return JSON.parse(localStorage._trbo_ud).session.sessionId;
};

/**
 * id of current page type
 */
const getPageTypeId = function() {
    const pageTypeMapping = {
        'detail': 1,
        'search': 2,
        'basket': 3,
        'home': 4,
        'other': 5,
        'checkout': 6,
        'static': 7,
        'category': 8,
        'unknown': 9,
        'confirmation': 10,
        'account': 12
    };
    const pageTypeId = pageTypeMapping.detail;
    // TODO return correct page type

    return pageTypeId;
};

/**
 * value, number and items of current basket
 */
const getCurrentBasket = function() {
    const currentBasketData = {
        value: 0, // absolut value of current basket
        num: 0, // number of products within current basket
        voucher: 0, // is voucher within basket : 0 = no : 1 = yes
        p: [] // array of product_ids within current basket 
    };
    // TODO fill data
    return currentBasketData;
};


/**
 * total number of sale conversions
 */
const getSaleCount = function() {
    return JSON.parse(localStorage._trbo_ud).user.userSummary.saleCount;
};

/**
 * timestamp when user was created 
 */ 
const getUserStartTime = function() {
    return JSON.parse(localStorage._trbo_ud).user.userSummary.userCreateTime;
};

/**
 * total session duration from all sessions in seconds
 */
const getSessionDurationTotal = function() {
    return JSON.parse(localStorage._trbo_ud).user.userSummary.sessionDurationTotal;
};

/**
 * get number of sessions for this user
 */
const getSessionCount = function() {
    return JSON.parse(localStorage._trbo_ud).user.userSummary.sessionCount;
};

const getCic2 = function() {
    let cic2 = [];

    if (typeof window !== 'undefined' && window && window._trbo) {
        cic2 = window._trbo.udp.getChannelData()
    } 

    return cic2;
}

const parseGlobalData = function() {
    const globalData = {
      st: 0,
      p: window.location.href,
        t: Math.floor((new Date()).getTime() / 1000),
        sh: 14274, // shop_id from trbo interface : TODO 
        u: getUserId(), // trbo user_id
        ts: getSaleCount(), // sale count number of sales per User
        ust: getUserStartTime(), // user start time
        sdt: getSessionDurationTotal(), // session duration total of all sessions in seconds
        sc: getSessionCount(), // session count, number of total sessions
        sid: getSessionId(),
        pt: getPageTypeId(),
        cb: getCurrentBasket(),
        bw: 1440, // browser / viewport with
        bh: 768, // browser / viewport height
        cic: [], // click in channel - legacy
        cic2: getCic2(), // click in channel v2
        ref: "", // page referrer
    };

    return globalData;
};


const parseSessionData = function() {
    let sessionData = [];
    // if window._trbo is available :
    if (typeof window !== 'undefined' && window && window._trbo) {
        sessionData = window._trbo.udp.getSessions().slice(0,5);
    }else if (typeof localStorage !== 'undefined') {
        // else use local storage
        sessionData.push({
            sst: JSON.parse(localStorage._trbo_ud).session.createTime,
            pvc: JSON.parse(localStorage._trbo_ud).session.sessionSummary.pageViewCount,
            sd: JSON.parse(localStorage._trbo_ud).session.sessionSummary.sessionDuration
        });
        const passtSessions = JSON.parse(localStorage._trbo_ud).sessions;
        if (Object.keys(passtSessions).length > 0) {
            for (const sessionkey in passtSessions) {
                if (sessionData.length < 4) {
                    sessionData.push({
                        sst: passtSessions[sessionkey].createTime,
                        pvc: passtSessions[sessionkey].sessionSummary.pageViewCount,
                        sd: passtSessions[sessionkey].sessionSummary.sessionDuration 
                    });
                }
            }
        }
    }

    return sessionData;
};

const parseProductData = function(productId) {
    let productData = {};

    if (productId) {
        productData = {
            [productId]: Date.now()
        };

        return productData;
    }

    
    // if window._trbo is available :
    if (typeof window !== 'undefined' && window && window._trbo) {
        productData = window._trbo.app.opts.requestDataTransformer.getProducts()
    } 

    return productData;
};

const parseDataLayerData = function(slot) {
    const dataLayer = {};
   
    if (typeof window !== 'undefined' && window && window._trbo) {
        const localStorageDataLayer = _trbo.udp.getUserSummary().externalData;
        if (localStorageDataLayer && Object.keys(localStorageDataLayer).length > 0) {
            for (const dataLayerKey in localStorageDataLayer) {
                if (localStorageDataLayer[dataLayerKey] !== 'n/a' && 
                    localStorageDataLayer[dataLayerKey] !== '' &&  
                    dataLayerKey !== 'external_user_id') {
                    if (typeof localStorageDataLayer[dataLayerKey] === 'object' && 
                        localStorageDataLayer[dataLayerKey] !== null) {
                        dataLayer[dataLayerKey] = localStorageDataLayer[dataLayerKey]
                    }
                    else {
                        dataLayer[dataLayerKey] = localStorageDataLayer[dataLayerKey].toString().replace("&", "%26");
                    }
                    
                }
            }
        }
    }
    
    if (slot) {
        dataLayer.slot = slot;
    }

    return dataLayer;
};

export const buildTrboRequestUrl = (slot, productId) => {
    let requestUrl = 'https://api-v4.trbo.com/s2s.php';

    requestUrl +=
        `?g=${  JSON.stringify(parseGlobalData())}`;
    requestUrl +=
        `&s=${  JSON.stringify(parseSessionData())}`;
    requestUrl +=
        `&p=${  JSON.stringify(parseProductData(productId))}`;
    requestUrl +=
        `&dl=${ 
        JSON.stringify(parseDataLayerData(slot))}`;
    requestUrl +=
        `&seg=${ 
        JSON.stringify(parseSegments())}`;

    // if (Cookies.get("trbodebug")) {
    //     console.log(requestUrl);
    // }
    return requestUrl;
}


