
const accountMenu: HTMLElement = document.querySelector(
    "#myAccount"
);
const popup: HTMLElement = document.querySelector(
    ".my-account-menu-bubble"
);

function toggleMenu(ev: MouseEvent): void {
    ev.preventDefault();
    if (accountMenu != null && popup != null) {
        const windowWidth = window.innerWidth;
        if (windowWidth > 801) {
            if (popup.hasAttribute("hidden")) {
                popup.removeAttribute("hidden");
            } else {
                popup.setAttribute("hidden", "true");
            }
        }
    }
    ev.stopPropagation();
}

export const AccountPopup = (): void => {
    if (accountMenu != null) {
        accountMenu.addEventListener("click", toggleMenu)
    }
};

export const CloseAccountPopup = (): void => {

    document.addEventListener("click", function Close(event) {
        popup?.setAttribute("hidden", "true");
    });
};

