import axios, { AxiosResponse } from "axios"
import * as React from "react"
import { FunctionComponent, useState } from "react"
import { useForm } from "react-hook-form/dist/index.ie11"
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner"
import { ICommercetoolsCart } from "../../../ts/models/icommercetools-cart"
import { BackButton } from "../../shared/checkout/back-button"
import { IResponseModelState } from "../../../ts/models/response-model-state"
import { validatePostcode } from "../../../ts/functions/form-validation"
import { IFormError } from "../../../ts/models/error/form-error"
import { ShowPopupError } from "../../../ts/components/error-popup"
import { countryList } from "../../../ts/constants/form-values"

interface IProps {
	Cart: ICommercetoolsCart
}

export const CheckoutAddressesGuest: FunctionComponent<IProps> = (props: IProps) => {
	const { register, handleSubmit, errors, watch, setValue, getValues } = useForm({
		defaultValues: {
			billing: {
				company: props.Cart?.CurrentAddresses?.BillingAddress?.Company,
				companyAddition1:
					props.Cart?.CurrentAddresses?.BillingAddress?.CompanyAddition1,
				companyAddition2:
					props.Cart?.CurrentAddresses?.BillingAddress?.CompanyAddition2,
				department: props.Cart?.CurrentAddresses?.BillingAddress?.Department,
				industry: props.Cart?.CurrentAddresses?.BillingAddress?.Industry,
				salutation: props.Cart?.CurrentAddresses?.BillingAddress?.Salutation,
				title: props.Cart?.CurrentAddresses?.BillingAddress?.Title,
				firstName: props.Cart?.CurrentAddresses?.BillingAddress?.FirstName,
				lastName: props.Cart?.CurrentAddresses?.BillingAddress?.LastName,
				additionalInfo: props.Cart?.CurrentAddresses?.BillingAddress?.AdditionalInfo,
				streetName: props.Cart?.CurrentAddresses?.BillingAddress?.StreetName,
				streetNumber: props.Cart?.CurrentAddresses?.BillingAddress?.StreetNumber,
				postalCode: props.Cart?.CurrentAddresses?.BillingAddress?.PostalCode,
				city: props.Cart?.CurrentAddresses?.BillingAddress?.City,
				country: props.Cart?.CurrentAddresses?.BillingAddress?.Country
			},
			shipping: {
				company: props.Cart?.CurrentAddresses?.ShippingAddress?.Company,
				companyAddition1:
					props.Cart?.CurrentAddresses?.ShippingAddress?.CompanyAddition1,
				companyAddition2:
					props.Cart?.CurrentAddresses?.ShippingAddress?.CompanyAddition2,
				department: props.Cart?.CurrentAddresses?.ShippingAddress?.Department,
				industry: props.Cart?.CurrentAddresses?.ShippingAddress?.Industry,
				salutation: props.Cart?.CurrentAddresses?.ShippingAddress?.Salutation,
				title: props.Cart?.CurrentAddresses?.ShippingAddress?.Title,
				firstName: props.Cart?.CurrentAddresses?.ShippingAddress?.FirstName,
				lastName: props.Cart?.CurrentAddresses?.ShippingAddress?.LastName,
				additionalInfo:
					props.Cart?.CurrentAddresses?.ShippingAddress?.AdditionalInfo,
				streetName: props.Cart?.CurrentAddresses?.ShippingAddress?.StreetName,
				streetNumber: props.Cart?.CurrentAddresses?.ShippingAddress?.StreetNumber,
				postalCode: props.Cart?.CurrentAddresses?.ShippingAddress?.PostalCode,
				postfachNo: props.Cart?.CurrentAddresses?.ShippingAddress?.PostfachNo,
				city: props.Cart?.CurrentAddresses?.ShippingAddress?.City,
				country: props.Cart?.CurrentAddresses?.ShippingAddress?.Country,
				shippingtype: props.Cart?.CurrentAddresses?.ShippingAddress?.PostfachNo
					? "postfach"
					: "address"
			},
			email: props.Cart?.CurrentAddresses?.BillingAddress?.GuestEmailAddress,
			useShippingAddress:
				props.Cart?.CurrentAddresses?.IsShippingAddressSameAsBillingAddress !==
					undefined &&
				props.Cart?.CurrentAddresses?.IsShippingAddressSameAsBillingAddress === false
		}
	})

	const [useShippingAddress, setuseShippingAddress] = useState(
		props.Cart?.CurrentAddresses?.IsShippingAddressSameAsBillingAddress !==
			undefined &&
			props.Cart?.CurrentAddresses?.IsShippingAddressSameAsBillingAddress === false
	)
	const type = watch("shipping.shippingtype", "address")

	const [plzError, setPlZError] = useState(false)

	const countryBilling: string = watch("billing.country") // watch selcted country,
	const countryShipping: string = watch("shipping.country") // watch selcted country

	const [modelStateErrors, setModelStateErrors] = useState<IResponseModelState[]>()

	const validateShippingCountry = (value: string) => {
		return validatePlz(countryShipping, value)
	}

	const validateBillingCountry = (value: string) => {
		return validatePlz(countryBilling, value)
	}

	function validatePlz(country: string, value: string) {
		const isValid = validatePostcode(value, country)
		setPlZError(!isValid)
		return isValid
	}

	function onSubmit(data: Record<string, any>) {
		ShowSpinner()
		axios
			.post(`/umbraco/api/GuestCart/AddAddressesToCart`, data)
			.then((result: AxiosResponse) => {
				HideSpinner()
				window.location.href = "/checkout-teilnehmer"
			})
			.catch((error) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError
					setModelStateErrors(object.ValidationErrors)
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage)
					}
				}
				HideSpinner()
			})
	}

	function CreateApiError(key: string) {
		const error = modelStateErrors?.find((x) => x.Key === key)
		if (error !== undefined && error !== null) {
			return <div className="form-error">{error.Value[0]}</div>
		}
	}

	return (
		<div className="container">
			<div className="row">
				<div className="col-12 address__form">
					<div className="billing">
						<span className="heading">Gastbestellung</span>
						<p>
							Bitte füllen Sie alle mit <span className="mandatory">*</span>{" "}
							gekennzeichneten Felder aus.
						</p>
					</div>
				</div>
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="address__form">
					<div className="row">
						<div className="col-12">
							<div className="heading">Rechnungsadresse</div>
						</div>
						<div className="col-sm-12">
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									Firma / Name des Selbstständigen
									<span className="mandatory">*</span> <br />
									<span className="form-hint">
										(Bitte tragen Sie bei selbstständiger Tätigkeit hier die
										Firmierung / Ihren Namen, z.B. Rechtsanwältin Erika Musterfrau,
										ein.)
									</span>
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="billing.company"
										className="form-control"
										placeholder="Firmenname"
										maxLength={35}
										ref={register({ required: true })}
									/>
									{errors?.billing?.company && (
										<div className="form-error">
											Bitte geben Sie Ihren Firmennamen ein.
										</div>
									)}
									{CreateApiError("model.Billing.Company")}
									<input
										type="text"
										name="billing.companyAddition1"
										className="form-control mt-3"
										placeholder="Firmenzusatz1"
										maxLength={35}
										ref={register()}
									/>
									{CreateApiError("model.Billing.CompanyAddition1")}
									<input
										type="text"
										name="billing.companyAddition2"
										className="form-control mt-3"
										placeholder="Firmenzusatz2"
										maxLength={35}
										ref={register()}
									/>
									{CreateApiError("model.Billing.CompanyAddition2")}
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">Abteilung</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="billing.department"
										className="form-control"
										placeholder="Abteilung"
										maxLength={35}
										ref={register()}
									/>
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									Anrede <span className="mandatory">*</span>
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										name="billing.salutation"
										className="form-control"
										id=""
										ref={register({ required: true })}
									>
										<option value=""></option>
										<option value="F">Frau</option>
										<option value="M">Herr</option>
										<option value="D">Divers</option>
									</select>
									{errors?.billing?.salutation && (
										<div className="form-error">
											Bitte geben Sie Ihre Anrede ein.
										</div>
									)}
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">Titel</label>
								<div className="col-md-5 col-sm-8">
									<select
										name="billing.title"
										className="form-control"
										id=""
										placeholder="Titel"
										ref={register()}
									>
										<option></option>
										<option>Dr.</option>
										<option>Prof.</option>
										<option>Prof. Dr.</option>
										<option>LL. M.</option>
										<option>Ass. Jur.</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									Vorname <span className="mandatory">*</span>
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="billing.firstName"
										className="form-control"
										placeholder="Vorname"
										maxLength={30}
										ref={register({ required: true, maxLength: 30 })}
									/>

									{errors?.billing?.firstName && (
										<div className="form-error">
											Der Vorname darf maximal 30 Zeichen lang sein.
										</div>
									)}
									{CreateApiError("model.Billing.FirstName")}
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									Nachname <span className="mandatory">*</span>
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="billing.lastName"
										className="form-control"
										placeholder="Nachname"
										maxLength={30}
										ref={register({ required: true, maxLength: 30 })}
									/>
									{errors?.billing?.lastName && (
										<div className="form-error">
											Der Nachname darf maximal 30 Zeichen lang sein.
										</div>
									)}
									{CreateApiError("model.Billing.LastName")}
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">Adresszusatz</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="billing.additionalInfo"
										className="form-control"
										placeholder="Adresszusatz"
										maxLength={81}
										ref={register({ maxLength: 81 })}
									/>
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									Straße / Hausnummer <span className="mandatory">*</span>
								</label>
								<div className="col-sm-5 col-xs-1">
									<input
										type="text"
										name="billing.streetName"
										className="form-control"
										placeholder="Straße"
										maxLength={46}
										ref={register({ required: true })}
									/>
									{errors?.billing?.streetName && (
										<div className="form-error">Bitte geben Sie die Straße ein.</div>
									)}
								</div>
								<div className="col-sm-3 col-xs-1">
									<input
										type="text"
										name="billing.streetNumber"
										className="form-control"
										placeholder="Hausnummer"
										ref={register()}
									/>
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									PLZ / Ort<span className="mandatory">*</span>
								</label>
								<div className="col-sm-3 col-xs-1">
									<input
										type="text"
										name="billing.postalCode"
										className="form-control"
										placeholder="PLZ"
										ref={register({
											required: true,
											validate: validateBillingCountry
										})}
									/>
									{errors?.billing?.postalCode && (
										<div className="form-error">
											Bitte geben Sie die valide PLZ ein.
										</div>
									)}
								</div>
								<div className="col-sm-5 col-xs-1">
									<input
										type="text"
										name="billing.city"
										className="form-control"
										placeholder="Ort"
										ref={register({ required: true })}
									/>
									{errors?.billing?.city && (
										<div className="form-error">Bitte geben Sie den Ort ein.</div>
									)}
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									Land: <span className="mandatory">*</span>
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										name="billing.country"
										className="form-control"
										placeholder=""
										ref={register({ required: true })}
									>
										{countryList.map((country) => (
											<option value={country.value}>{country.name}</option>
										))}
									</select>
									{errors?.billing?.country && (
										<div className="form-error">
											Bitte wählen Sie Ihre Stadt aus.
										</div>
									)}
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									E-Mail <span className="mandatory">*</span>
								</label>
								<div className="col-sm-8">
									<input
										type="email"
										name="email"
										className="form-control"
										placeholder="E-Mail"
										maxLength={60}
										ref={register({
											required: true,
											maxLength: 60,
											pattern: /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/
										})}
									/>
									{errors?.email && (
										<div className="form-error">
											Bitte geben Sie Ihre E-Mail ein.
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div
							className="check different__address"
							onClick={() => setuseShippingAddress(!useShippingAddress)}
						>
							<span
								className={`check__box check__elm billing__checkbox ${
									useShippingAddress ? "checked" : ""
								}`}
							></span>
							<span className="check__elm checkbox__label">
								abweichende Rechnungsversandadresse
							</span>
							<input
								type="hidden"
								name="useShippingAddress"
								ref={register()}
								value={String(useShippingAddress)}
							/>
						</div>
					</div>
				</div>
				{useShippingAddress && (
					<div className="address__form">
						<div className="row">
							<div className="col-12">
								<div className="heading">Abweichende Rechnungsversandadresse</div>
							</div>
							<div className="col-sm-12">
								<div className="form-group row">
									<label className="col-sm-4 col-form-label">
										Firma / Name des Selbstständigen
										<span className="mandatory">*</span> <br />
										<span className="form-hint">
											(Bitte tragen Sie bei selbstständiger Tätigkeit hier die
											Firmierung / Ihren Namen, z.B. Rechtsanwältin Erika Musterfrau,
											ein.)
										</span>
									</label>
									<div className="col-sm-8">
										<input
											type="text"
											name="shipping.company"
											className="form-control"
											placeholder="Firmenname"
											ref={register({ required: true })}
										/>
										{errors?.shipping?.company && (
											<div className="form-error">
												Bitte geben Sie Ihren Firmennamen ein.
											</div>
										)}
										{CreateApiError("model.Shipping.Company")}
										<input
											type="text"
											name="shipping.companyAddition1"
											className="form-control mt-3"
											placeholder="Firmenzusatz1"
											ref={register()}
										/>
										{CreateApiError("model.Shipping.CompanyAddition1")}
										<input
											type="text"
											name="shipping.companyAddition2"
											className="form-control mt-3"
											placeholder="Firmenzusatz2"
											ref={register()}
										/>
										{CreateApiError("model.Shipping.CompanyAddition2")}
									</div>
								</div>
								<div className="form-group row">
									<label className="col-sm-4 col-form-label">Abteilung</label>
									<div className="col-sm-8">
										<input
											type="text"
											name="shipping.department"
											className="form-control"
											placeholder="Abteilung"
											ref={register()}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-sm-4 col-form-label">Anrede</label>
									<div className="col-md-5 col-sm-8">
										<select
											name="shipping.salutation"
											className="form-control"
											id=""
											ref={register()}
										>
											<option value=""></option>
											<option value="F">Frau</option>
											<option value="M">Herr</option>
											<option value="D">Divers</option>
										</select>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-sm-4 col-form-label">Titel</label>
									<div className="col-md-5 col-sm-8">
										<select
											name="shipping.title"
											className="form-control"
											id=""
											placeholder="Titel"
											ref={register()}
										>
											<option></option>
											<option>Dr.</option>
											<option>Prof.</option>
											<option>Prof. Dr.</option>
											<option>LL. M.</option>
											<option>Ass. Jur.</option>
										</select>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-sm-4 col-form-label">Vorname</label>
									<div className="col-sm-8">
										<input
											type="text"
											name="shipping.firstName"
											className="form-control"
											placeholder="Vorname"
											ref={register()}
										/>
										{CreateApiError("model.Shipping.FirstName")}
									</div>
								</div>
								<div className="form-group row">
									<label className="col-sm-4 col-form-label">Nachname</label>
									<div className="col-sm-8">
										<input
											type="text"
											name="shipping.lastName"
											className="form-control"
											placeholder="Nachname"
											ref={register()}
										/>
										{CreateApiError("model.Shipping.LastName")}
									</div>
								</div>
								<div className="form-group row">
									<label className="col-sm-4 col-form-label"></label>
									<div className="col-sm-8">
										<div
											className="radio-item d-inline"
											onClick={() => setValue("shipping.shippingtype", "address")}
										>
											<input
												type="radio"
												ref={register}
												name="shipping.shippingtype"
												value={"address"}
												checked={getValues("shipping.shippingtype") === "address"}
											/>
											<label>Adresse</label>
										</div>
										<div
											className="radio-item d-inline ml-3"
											onClick={() => setValue("shipping.shippingtype", "postfach")}
										>
											<input
												type="radio"
												ref={register}
												name="shipping.shippingtype"
												value={"postfach"}
												checked={getValues("shipping.shippingtype") === "postfach"}
											/>
											<label>Postfach</label>
										</div>
									</div>
								</div>
								{type === "address" && (
									<React.Fragment>
										<div className="form-group row">
											<label className="col-sm-4 col-form-label">Adresszusatz</label>
											<div className="col-sm-8">
												<input
													type="text"
													name="shipping.additionalInfo"
													className="form-control"
													placeholder="Adresszusatz"
													ref={register()}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-4 col-form-label">
												Straße / Hausnummer <span className="mandatory">*</span>
											</label>
											<div className="col-sm-5 col-xs-1">
												<input
													type="text"
													name="shipping.streetName"
													className="form-control"
													placeholder="Straße"
													ref={register({ required: true })}
												/>
												{errors?.shipping?.streetName && (
													<div className="form-error">
														Bitte geben Sie die Straße ein.
													</div>
												)}
											</div>
											<div className="col-sm-3 col-xs-1">
												<input
													type="text"
													name="shipping.streetNumber"
													className="form-control"
													placeholder="Hausnummer"
													ref={register()}
												/>
											</div>
										</div>
									</React.Fragment>
								)}

								{type === "postfach" && (
									<div className="form-group row">
										<label className="col-sm-4 col-form-label">
											Postfach<span className="mandatory">*</span>
										</label>
										<div className="col-sm-3 col-xs-1">
											<input
												type="text"
												name="shipping.postfachNo"
												className="form-control"
												placeholder="Postfach"
												ref={register({ required: true })}
											/>
											{errors?.shipping?.postfachNo && (
												<div className="form-error">
													Bitte geben Sie die Postfachnummer ein.
												</div>
											)}
										</div>
									</div>
								)}
								<div className="form-group row">
									<label className="col-sm-4 col-form-label">
										PLZ / Ort<span className="mandatory">*</span>
									</label>
									<div className="col-sm-3 col-xs-1">
										<input
											type="text"
											name="shipping.postalCode"
											className="form-control"
											placeholder="PLZ"
											ref={register({
												required: true,
												validate: validateShippingCountry
											})}
										/>
										{errors?.shipping?.postalCode && (
											<div className="form-error">
												Bitte geben Sie die valide PLZ ein.
											</div>
										)}
									</div>
									<div className="col-sm-5 col-xs-1">
										<input
											type="text"
											name="shipping.city"
											className="form-control"
											placeholder="Ort"
											ref={register({ required: true })}
										/>
										{errors?.shipping?.city && (
											<div className="form-error">Bitte geben Sie den Ort ein.</div>
										)}
									</div>
								</div>
								<div className="form-group row">
									<label className="col-sm-4 col-form-label">
										Land: <span className="mandatory">*</span>
									</label>
									<div className="col-md-5 col-sm-8">
										<select
											name="shipping.country"
											className="form-control"
											id=""
											placeholder=""
											ref={register({ required: true })}
										>
											{countryList.map((country) => (
												<option value={country.value}>{country.name}</option>
											))}
										</select>
										{errors?.shipping?.country && (
											<div className="form-error">
												Bitte wählen Sie Ihre Stadt aus.
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="row pb-4 process__nav">
					<div className="col-12 col-sm-3">
						<BackButton url="/checkout-login" buttonText="Zurück" />
					</div>
					<div className="col-12 col-sm-9 text-right">
						<button
							type="submit"
							className="button button__cta button--red order__button"
						>
							<span className="txt">Weiter zu Teilnehmerdaten</span>
							<i className="fas fa-arrow-right icon-fa icon--white"></i>
						</button>
					</div>
				</div>
			</form>
		</div>
	)
}
