import * as React from "react";
import * as ReactDom from 'react-dom';
import { ICommercetoolsCart } from "../../ts/models/icommercetools-cart";
import { CheckoutBestellubersicht} from "./components/checkout-bestellubersicht";
import {IParticipantResponse} from "../../ts/models/participant-response";
import { GoogleAnalytictsTrackingCheckout} from "../../ts/functions/google-tracking"

export const RenderBestellubersicht = (): void => {
    const checkoutBestellubersicht: HTMLElement = document.querySelector("#checkoutbestelluebersicht");
    
	if (checkoutBestellubersicht){
        const cartJson = checkoutBestellubersicht.getAttribute("data-cart");
        const participantsJson = checkoutBestellubersicht.getAttribute("data-participants");
        const cart = JSON.parse(cartJson) as ICommercetoolsCart;
        const participants = JSON.parse(participantsJson) as IParticipantResponse[];
        GoogleAnalytictsTrackingCheckout(cart, "5");

		ReactDom.render(<CheckoutBestellubersicht Cart={cart} LineItemParticipants ={participants}/>, checkoutBestellubersicht);
	}
};  