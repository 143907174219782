export const validatePostcode = (plz: string, country: string): boolean => {
	const isNumber = /^\d*$/

	if (
		country === undefined ||
		country === "DE" ||
		country === "EE" ||
		country === "ES" ||
		country === "FI" ||
		country === "FR" ||
		country === "IT" ||
		country === "KR"
	) {
		if (plz.length !== 5 || !isNumber.test(plz)) {
			return false
		}
	}
	if (
		country === "AT" ||
		country === "BE" ||
		country === "BG" ||
		country === "CY" ||
		country === "HU" ||
		country === "LU" ||
		country === "SI" ||
		country === "CH" ||
		country === "LI"
	) {
		if (plz.length !== 4 || !isNumber.test(plz)) {
			return false
		}
	}
	return true
}
