import * as React from "react";
import * as ReactDom from 'react-dom';
import { ICommercetoolsCart } from "../../ts/models/icommercetools-cart";
import {IParticipantResponse} from "../../ts/models/participant-response";
import { CheckoutParticipants} from "./components/checkout-participants"
import { GoogleAnalytictsTrackingCheckout} from "../../ts/functions/google-tracking"
import { ICommercetoolsAddress } from "../../ts/models/billing-address";

export const RenderParticipants = (): void => {
    const checkoutParticipants: HTMLElement = document.querySelector("#checkoutParticipants");
    
	if (checkoutParticipants){
        const participantsJson = checkoutParticipants.getAttribute("data-participants");
        const cartJson = checkoutParticipants.getAttribute("data-cart");
        const participantAddressesJson = checkoutParticipants.getAttribute("data-participantAddresses");
        const cart = JSON.parse(cartJson) as ICommercetoolsCart;
        const participants = JSON.parse(participantsJson) as IParticipantResponse[];
        const participantAddresses = JSON.parse(participantAddressesJson) as ICommercetoolsAddress[];
        GoogleAnalytictsTrackingCheckout(cart, "4");

        
		ReactDom.render(<CheckoutParticipants Cart={cart} LineItemParticipants ={participants} ParticipantAddresses={participantAddresses}/>, checkoutParticipants);
	}

};