import * as React from "react"
import { FunctionComponent, useState } from "react"
import axios, { AxiosResponse } from "axios"
import { useForm } from "react-hook-form/dist/index.ie11"
import { INewsletterGrid } from "../../../ts/models/newsletter-grid"
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner"
import * as UserCentrics from "../../../ts/shared/user-centrics"

interface INewsPros {
	NewsletterGrid: INewsletterGrid,
	SiteKey: string
}

declare let grecaptcha: any;

export const Newsletter: FunctionComponent<INewsPros> = (props: INewsPros) => {
	const { register, handleSubmit, errors } = useForm()
	const [subscriptionError, setSubscriptionError] = useState("")
	const [subscriptionSuccess, setSubscriptionSuccess] = useState("")

	const [checkedTrackingPermission, setTrackingPermission] = useState(false)

	function postData(data: Record<string, any>) {
		axios
			.post(`/umbraco/api/newsletter/SendNewsletterEmail`, data)
			.then((result: AxiosResponse) => {
				setSubscriptionSuccess("Vielen Dank für Ihre Anmeldung.")
				HideSpinner()
			})
			.catch((error: any) => {
				HideSpinner()
				if (error?.response?.data?.Message) {
					setSubscriptionError(error.response.data.Message)
				}
			})
	}

	function onSubmit(data: Record<string, any>) {
		if (!UserCentrics.IsRecaptchaAllowed()) {
			return;
		}

		setSubscriptionError("")
		ShowSpinner()

		grecaptcha.ready(function () {
			grecaptcha.execute(props.SiteKey, { action: 'submit' }).then(function (token) {

				if (token === "") {
					HideSpinner();
					return;
				}

				data.recaptchaValue = token;
				postData(data);
			});
		});
	}



	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className="form-horizontal common-form">
				<fieldset>
					<div className="form-group">
						<div className="col-md-8 col-sm-10 col-xs-10">
							<label className="row control-label">E-Mail*</label>
							<div className="row inputGroupContainer">
								<input
									value={JSON.stringify(props.NewsletterGrid.TrackingPermissionCheckBox)}
									ref={register()}
									type="text"
									name="IsTrackingAllowed"
									hidden
								/>
								<input
									value={JSON.stringify(props.NewsletterGrid.ZusatzlicheParameter)}
									ref={register()}
									type="text"
									name="AdditionalParameters"
									hidden
								/>

								<input
									value={props.NewsletterGrid.InxmailSubscription}
									ref={register()}
									type="text"
									name="ListNameInxmail"
									hidden
								/>
								<input
									maxLength={60}
									ref={register({
										required: true,
										max: 60,
										pattern: /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/
									})}
									type="text"
									className="form-control"
									name="email"
								/>
								{errors.email && <div className="form-error">Bitte geben Sie Ihre E-Mail ein.</div>}
							</div>
						</div>
					</div>
					{props.NewsletterGrid.ErweitertesFormular && (
						<div>
							<div className="form-group">
								<div className="col-md-8 col-sm-10 col-xs-10">
									<label className="row control-label">Anrede</label>
									<div className="row inputGroupContainer">
										<select ref={register()} name="salutation" className="form-control minimal">
											<option value="">Bitte wählen</option>
											<option value="F">Frau</option>
											<option value="M">Herr</option>
											<option value="D">Divers</option>
										</select>
									</div>
								</div>
							</div>

							<div className="form-group">
								<div className="col-md-8 col-sm-10 col-xs-10">
									<label className="row control-label">Titel</label>
									<div className="row inputGroupContainer">
										<select
											ref={register()}
											placeholder="Titel"
											name="title"
											className="form-control minimal"
										>
											<option value="">Bitte wählen</option>
											<option />
											<option>Dr.</option>
											<option>Prof.</option>
											<option>Prof. Dr.</option>
											<option>LL. M.</option>
											<option>Ass. Jur.</option>
										</select>
									</div>
								</div>
							</div>
							<div className="form-group">
								<div className="col-md-8 col-sm-10 col-xs-10">
									<label className="row control-label">Vorname</label>
									<div className="row inputGroupContainer">
										<input
											maxLength={30}
											ref={register({ required: true, max: 30 })}
											type="text"
											className="form-control"
											name="firstname"
											id="newsletter_Firstname"
										/>
										{errors.firstname && (
											<div className="form-error">Bitte geben Sie Ihren Vorname ein.</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group" style={{ display: 'none' }}>
								<div className="col-md-8 col-sm-10 col-xs-10">
									<label className="row control-label">Name</label>
									<div className="row inputGroupContainer">
										<input
											maxLength={30}
											ref={register({ required: false, max: 30 })}
											type="text"
											autoComplete="off"
											className="form-control"
											name="Name"
											id="newsletter_Name"
										/>
									</div>
								</div>
							</div>
							<div className="form-group">
								<div className="col-md-8 col-sm-10 col-xs-10">
									<label className="row control-label">Nachname</label>
									<div className="row inputGroupContainer">
										<input
											maxLength={30}
											ref={register({ required: true, max: 30 })}
											type="text"
											className="form-control"
											name="lastname"
											id="newsletter_Lastname"
										/>
										{errors.lastname && (
											<div className="form-error">Bitte geben Sie Ihren Nachname ein.</div>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
					{props.NewsletterGrid.TrackingPermissionCheckBox && (
						<div className="form-group">
							<div className="col-md-8 col-sm-10 col-xs-10">
								<div className="check newsletter">
									<input
										type="hidden"
										name="IsTrackingAllowed"
										ref={register({
											required: true,
											validate: (value) => value === "true"
										})}
										value={String(checkedTrackingPermission)}
									/>
									<span
										className={`check__box check__elm ${checkedTrackingPermission ? "checked" : ""
											}`}
										onClick={() => setTrackingPermission(!checkedTrackingPermission)}
									 />
									<span className="check__elm checkbox__label d-inline">
										{props.NewsletterGrid.TextTrackingPermission}
									</span>
									{errors?.IsTrackingAllowed && (
										<div className="form-error">
											Bitte bestätigen Sie vor der Buchung noch, dass Sie trcking erlauben.
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					<div className="gRecaptchaWrapper" style={{ display: 'none' }}>
						<div className="gRecaptcha" />
					</div>

					<div className="form-group form-success">
						<div className="col-md-8 col-sm-10 col-xs-10">
							{subscriptionSuccess != "" && (
								<div id="Newsletter_Email_Success" className="hide">
									<span id="newsletterFormValidationSuccess">{subscriptionSuccess}</span>
								</div>
							)}


						</div>
					</div>
					<div>
						{subscriptionError != "" && (
							<div id="Newsletter_Email_Server_Error" className="hide form-error">
								<span className="field-validation-error">
									{subscriptionError}
								</span>
							</div>
						)}
					</div>
					<button className="button button__cta button--red order__button">
						<span className="">{props.NewsletterGrid.ButtonName}</span>
						<i className="fas fa-arrow-right icon-fa icon--white" />
					</button>
				</fieldset>
			</form>
		</>
	)
}
