import * as React from "react"
import { FunctionComponent, useState } from "react"
import axios, { AxiosResponse } from "axios"
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner"
interface IProps {
	PageAlias: string
}

export const Logout: FunctionComponent<IProps> = (props: IProps) => {
	const [hover, setHover] = useState(false)

	function Logout() {
		ShowSpinner()
		axios
			.post(`/umbraco/api/Auth/Logout`)
			.then((result: AxiosResponse) => {
				HideSpinner()
				window.location.href = "/"
			})
			.catch((error: any) => {
				HideSpinner()
			})
	}
	return (
		<React.Fragment>
			<div>
				<a
					href="/meine-daten/logout"
					className={`link icon__wrapper iconHolder  ${
						props.PageAlias == "logout" ? "activate" : ""
					}`}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					<span className="table-icon">
						<img
							src="../../assets/icons/svg/arrows/logout-grey.svg"
							className={`icon ${hover ? "d-none" : "d-block"}`}
						/>
						<img
							src="../../assets/icons/svg/arrows/logout-red.svg"
							className={`icon ${hover ? "d-block" : "d-none"}`}
						/>
					</span>
					<span onClick={() => Logout()}>Abmelden</span>
				</a>
			</div>
		</React.Fragment>
	)
}
