import * as React from "react";
import * as ReactDom from "react-dom";
import { RegisterForm } from "./components/register-form";
import { ICommercetoolsCart } from "../../ts/models/icommercetools-cart";
import { GoogleAnalytictsTrackingCheckout } from "../../ts/functions/google-tracking";

export const RenderRegisterForm = (): void => {
	const registerForm: HTMLElement = document.querySelector("#registerForm");
	if (registerForm) {
		const cartJson = registerForm.getAttribute("data-cart");
		const siteKey = registerForm.getAttribute("data-sitekey");
		const isCheckout = registerForm.getAttribute("data-ischeckout") === "true";
		const cart = JSON.parse(cartJson) as ICommercetoolsCart;
		GoogleAnalytictsTrackingCheckout(cart, "2");
		const redirectUrl = registerForm.getAttribute("data-redirecturl");
		ReactDom.render(
			<RegisterForm successRedirectUrl={redirectUrl} siteKey={siteKey} isCheckout={isCheckout} />,
			registerForm
		);
	}
};
