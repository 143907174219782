import * as React from "react"
import { FunctionComponent, useState } from "react"
import { useForm } from "react-hook-form/dist/index.ie11"
import axios from "axios"
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner"
import * as UserCentrics from "../../../ts/shared/user-centrics"

interface IProps {
	SiteKey: string;
}

declare var grecaptcha: any;

export const PasswordVergessenForm: FunctionComponent<IProps> = (props: IProps) => {
	const { register, handleSubmit, errors, reset } = useForm({
		defaultValues: {
			username: ""
			
		}});

	const [passswordResetError, setPassswordResetError] = useState(false)
	const [passwordResetSuccess, setPasswordSuccessReset] = useState(false);

	function postData(password: Record<string, any>) {
		axios
			.post(`/umbraco/api/PasswordReset/passwordVergessenEmailSubmit`, password)
			.then(() => {
				HideSpinner()
				setPasswordSuccessReset(true);
				reset({})
			})
			.catch((error: any) => {
				HideSpinner()
				if (error?.response?.data?.Message) {
					setPassswordResetError(true);
				}
			})
	}

	function onSubmit(password: Record<string, any>) {
		if (!UserCentrics.IsRecaptchaAllowed()) {
			return;
		}

		ShowSpinner()

		grecaptcha.ready(function () {
			grecaptcha.execute(props.SiteKey, { action: 'submit' }).then(function (token) {

				if (token === "") {
					HideSpinner();
					return;
				}

				password.token = token;
				postData(password);
			});
		});

	}

	

	return (
		<React.Fragment>
			<form onSubmit={handleSubmit(onSubmit)}>
			{passwordResetSuccess && (<div className="form-success">E-Mail mit Link zum Zurücksetzen Ihres Passwortes wurde erfolgreich versandt!</div>)}
            <div className="row sign-up__form">
					<div className="col-lg-12 col-sm-12">
						<div className="form-group row">					
							<label className="col-sm-6  col-lg-6 col-form-label">
                            E-Mail:<span className="mandatory">*</span>
							</label>
							<div className="col-sm-6">
								<input
									type="input"
									className="form-control"
									name="Username"
									placeholder="E-Mail/Benutzername"
									maxLength={60 }
									ref={register({
										required: true,
										max:60
									})}
								/>
								{errors.username && (
									<div className="form-error">Bitte geben Sie Ihre  E-Mail/Benutzername ein.</div>
								)}
							</div>
						</div>
						
					</div>
				</div>

				<div className="row sign-up__form " style={{display: 'none'}}>
					<div className="col-lg-12 col-sm-12">
						<div className="form-group row">					
							<label className="col-sm-6  col-lg-6 col-form-label">
                            E-Mail:<span className="mandatory">*</span>
							</label>
							<div className="col-sm-6">
								<input
									type="input"
									className="form-control"
									name="Mail"
									placeholder="E-Mail/Benutzername"
									maxLength={60 }
									ref={register({
										required: false,
										max:60
									})}
								/>
							</div>
						</div>
						
					</div>
				</div>

				<div className="row gRecaptchaWrapper" style={{ display: 'none' }}>
					<div className="gRecaptcha">
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-sm-6"></div>
					<div className="col-12 col-sm-6 text-right">
						<button className="button button__cta button--red order__button">
							<span className="txt">Abschicken</span>
							<i className="fas fa-arrow-right icon-fa icon--white"></i>
						</button>
						{passswordResetError && <div className="form-error">{passswordResetError}</div>}
					</div>
				</div>
			</form>
		</React.Fragment>
	)
}
