import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import Modal from "react-bootstrap/Modal";
import { ICommercetoolsCart } from "../../../ts/models/icommercetools-cart";
import { SeminarInfo } from "./seminar-info";
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner";
import {
	ICreateParticipantResponse,
	IParticipantResponse
} from "../../../ts/models/participant-response";
import { IParticipantData } from "../../../ts/models/participant-data";
import { BackButton } from "../../shared/checkout/back-button";
import { IFormError } from "../../../ts/models/error/form-error";
import { ShowPopupError } from "../../../ts/components/error-popup";
import { updateLineItemTracking } from "../../../ts/functions/google-tracking";
import { ICommercetoolsAddress } from "../../../ts/models/billing-address";
import { IResponseModelState } from "../../../ts/models/response-model-state";
import { AddParticipantModal } from "./addparticipant-modal";
import { ParticipantsMissingModal } from "./participants-missing-modal";
import { ILineitem } from "../../../ts/models/line-item";

interface ICartPros {
	Cart: ICommercetoolsCart;
	LineItemParticipants: IParticipantResponse[];
	ParticipantAddresses: ICommercetoolsAddress[];
}

export const CheckoutParticipants: FunctionComponent<ICartPros> = (props: ICartPros) => {
	const [cart, setCart] = useState(props.Cart);
	const [currentParticipant, setCurrentParticipant] = useState<IParticipantData>();
	const [lineItemParticipants, setLineItemParticipants] = useState(props.LineItemParticipants);

	const [participantAddresses, setParticipantAddresses] = useState(props.ParticipantAddresses);
	const [showForm, setShowForm] = useState(false);
	const [participantsMissingError, setParticipantsMissingError] = useState(false);
	const [showparticipantsMissingErrorModal, setshowparticipantsMissingErrorModal] = useState(false);
	const [allowMoveToNextStep, setAllowMoveToNextStep] = useState(false);
	const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
	const [currentLinteItemId, setCurrentLinteItemId] = useState<string>();
	const [currentAddressId, setCurrentAddressId] = useState<string>();
	const [currentFormValues, setCurrentFormValues] = useState<Record<string, any>>();
	const [modelStateErrors, setModelStateErrors] = useState<IResponseModelState[]>();

	const ShowQuantityModal = (lineItemId: string, lineItemQuantity: number) => {
		const participants = lineItemParticipants.find((x) => x.LineItemId === lineItemId);
		if (
			participants !== undefined &&
			participants.ParticipantData != null &&
			participants.ParticipantData.length === lineItemQuantity
		) {
			return true;
		}
		return false;
	};

	const MoveToNextStep = () => {
		setParticipantsMissingError(false);
		let particpantsValid = true;
		cart.Cart.LineItems.forEach((lineItem) => {
			const participants = lineItemParticipants.filter((x) => x.LineItemId === lineItem.Id)[0];
			if (participants?.ParticipantData?.length !== lineItem.Quantity) {
				setParticipantsMissingError(true);
				particpantsValid = false;

				if (participants?.ParticipantData?.length > 0) {
					setshowparticipantsMissingErrorModal(true);
				}
			}
		});
		setAllowMoveToNextStep(false);
		if (particpantsValid) {
			window.location.href = "/checkout-bestellubersicht";
		}
	};

	const RemoveMissingParticipants = async () => {
		const participantToChange = new Array<{
			lineItem: ILineitem;
			participants: IParticipantResponse;
		}>();
		cart.Cart.LineItems.forEach((lineItem) => {
			const participants = lineItemParticipants.filter((x) => x.LineItemId === lineItem.Id)[0];
			if (participants?.ParticipantData?.length !== lineItem.Quantity) {
				participantToChange.push({ lineItem, participants });
			}
		});
		for (const element of participantToChange) {
			ShowSpinner();
			await updateParticipantQuantity(
				element.lineItem.Id,
				element.participants.ParticipantData.length,
				element.lineItem.Product.ProductId,
				"eec.removeFromCart"
			);
		}
		setParticipantsMissingError(false);
		setAllowMoveToNextStep(true);
	};

	useEffect(() => {
		if (allowMoveToNextStep) {
			MoveToNextStep();
		}
	}, [allowMoveToNextStep]);

	const AddParticipantWithQuantity = () => {
		const lineItem = cart.Cart.LineItems.find((x) => x.Id === currentLinteItemId);

		updateLineItemTracking(cart, 1, currentLinteItemId, "eec.addToCart");
		if (currentAddressId != null) {
			AddParticipantToLineItem(
				currentAddressId,
				cart.CartId,
				currentLinteItemId,
				true,
				lineItem.Product.ProductId
			);
		} else {
			onFormSubmit(currentFormValues, true, lineItem.Product.ProductId);
		}

		setShowAddParticipantModal(false);
	};

	const AddParticipantToLineItem = (
		addressId: string,
		cartId: string,
		lineItemId: string,
		increaseQuantity?: boolean,
		productId?: number
	) => {
		const lineItemQuantity = cart.Cart.LineItems.find((x) => x.Id === lineItemId).Quantity;
		if (ShowQuantityModal(lineItemId, lineItemQuantity) && !increaseQuantity) {
			setCurrentAddressId(addressId);
			setCurrentLinteItemId(lineItemId);
			setShowAddParticipantModal(true);
			return;
		}

		const data = {
			CartId: cartId,
			lineItemId: lineItemId,
			Id: addressId,
			ProductId: productId,
			Quantity: lineItemQuantity + 1,
			IncreaseQuantity: increaseQuantity
		};
		ShowSpinner();
		axios
			.post(`/umbraco/api/participants/CreateParticipant`, data)
			.then((result: AxiosResponse<ICreateParticipantResponse>) => {
				const otherParticipants = lineItemParticipants.filter(
					(x) => x.LineItemId !== result.data.Participants[0].LineItemId
				);
				setLineItemParticipants(result.data.Participants.concat(otherParticipants));
				setParticipantAddresses(result.data.ParticipantAddresses);
				if (result.data.Cart != null) {
					setCart(result.data.Cart);
				}
				setCurrentLinteItemId(null);
				setCurrentAddressId(null);
				HideSpinner();
				setShowForm(false);
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError;
					setModelStateErrors(object.ValidationErrors);
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner();
			});
	};

	function onFormSubmit(data: Record<string, any>, increaseQuantity?: boolean, productId?: number) {
		const lineItemQuantity = cart.Cart.LineItems.find((x) => x.Id === data.lineItemId).Quantity;
		if (ShowQuantityModal(data.lineItemId, lineItemQuantity) && !increaseQuantity) {
			setShowAddParticipantModal(true);
			setCurrentFormValues(data);
			setCurrentLinteItemId(data.lineItemId);
			return;
		}

		data.Quantity = lineItemQuantity + 1;
		data.IncreaseQuantity = increaseQuantity;
		data.ProductId = productId;

		ShowSpinner();
		const url = `/umbraco/api/participants/CreateParticipant`;
		axios
			.post(url, data)
			.then((result: AxiosResponse<ICreateParticipantResponse>) => {
				const otherParticipants = lineItemParticipants.filter(
					(x) => x.LineItemId !== result.data.Participants[0].LineItemId
				);
				setLineItemParticipants(result.data.Participants.concat(otherParticipants));
				setParticipantAddresses(result.data.ParticipantAddresses);
				if (result.data.Cart != null) {
					setCart(result.data.Cart);
				}
				HideSpinner();
				setShowForm(!showForm);
				setParticipantsMissingError(false);
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError;
					setModelStateErrors(object.ValidationErrors);
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner();
			});
	}

	function updateParticipantQuantity(
		lineItemId: string,
		quantity: number,
		productId: number,
		eventName: string
	): Promise<void> {
		if (quantity === 0) {
			return;
		}

		ShowSpinner();

		const data = { lineItemId, quantity, productId };

		updateLineItemTracking(cart, 1, lineItemId, eventName);

		return axios
			.post<ICommercetoolsCart>(`/umbraco/api/lineItem/Update`, data)
			.then((result: AxiosResponse<ICommercetoolsCart>) => {
				HideSpinner();
				setCart(result.data);
				setParticipantsMissingError(false);
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError;
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner();
			});
	}

	function removeParticipant(
		cartId: string,
		lineItemId: string,
		participantId: string,
		quantity: number,
		productId: number
	) {
		ShowSpinner();

		if (participantId !== null) {
			const data = {
				CartId: cartId,
				LineItemId: lineItemId,
				ParticipantId: participantId
			};
			axios
				.post(`/umbraco/api/participants/DeleteParticipant`, data)
				.then((result: AxiosResponse<IParticipantResponse[]>) => {
					updateParticipantQuantity(lineItemId, quantity, productId, "eec.removeFromCart");
					const otherParticipants = lineItemParticipants.filter(
						(x) => x.LineItemId !== result.data[0].LineItemId
					);
					setLineItemParticipants(result.data.concat(otherParticipants));

					HideSpinner();
				})
				.catch((error: any) => {
					if (error?.response?.data) {
						const object = JSON.parse(error?.response?.data?.Message) as IFormError;
						if (object.ErrorMessage) {
							ShowPopupError(object.ErrorMessage);
						}
					}
					HideSpinner();
				});
		} else {
			updateParticipantQuantity(lineItemId, quantity, productId, "eec.removeFromCart");
		}
	}

	return (
		<div className="container checkout__area">
			<div className="row">
				<div className="col-12">
					<div className="participants">
						<h1 className="heading">Teilnehmerdaten ausfüllen</h1>
					</div>
				</div>
			</div>

			<section className="participants seminar">
				<SeminarInfo
					cart={cart}
					showForm={showForm}
					updateParticipantQuantity={updateParticipantQuantity}
					currentParticipant={currentParticipant}
					onRemoveParticipant={removeParticipant}
					lineItemParticipants={lineItemParticipants}
					onFormSubmit={onFormSubmit}
					participantAddresses={participantAddresses}
					addParticipant={AddParticipantToLineItem}
					modelStateErrors={modelStateErrors}
				/>
			</section>

			<div className="row pb-4 process__nav">
				<div className="col-12 col-sm-3">
					<BackButton url="/checkout-adressen" buttonText="Zurück" />
				</div>
				<div className="col-12 col-sm-9 text-right">
					<button
						onClick={MoveToNextStep}
						className="button button__cta button--red order__button"
						type="button"
					>
						<span className="txt">Zur Bestellübersicht</span>
						<i className="fas fa-arrow-right icon-fa icon--white" />
					</button>
					{participantsMissingError && (
						<p className="form-error">
							Bitte fügen Sie noch weitere Teilnehmer hinzu, bevor Sie die Buchung abschließen.
						</p>
					)}
				</div>
			</div>
			<AddParticipantModal
				ShowModal={showAddParticipantModal}
				AddParticipantWithQuantity={AddParticipantWithQuantity}
				SetShowModal={setShowAddParticipantModal}
			/>
			<ParticipantsMissingModal
				ShowModal={showparticipantsMissingErrorModal}
				RemoveMissingParticipants={RemoveMissingParticipants}
				SetShowModal={setshowparticipantsMissingErrorModal}
			/>
		</div>
	);
};
