const spinner: HTMLElement = document.querySelector(
	"#spinnerModal"
);

export function HideSpinner(): void {
	spinner.classList.add("hide");
}

export function ShowSpinner(): void {
	spinner.classList.remove("hide");
}