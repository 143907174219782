import * as React from "react";
import * as ReactDom from "react-dom";
import { LoginForm } from "./components/login-form";

export const RenderLoginForm = (): void => {
	const loginForm: HTMLElement = document.querySelector("#loginForm");
	if (loginForm) {
		const redirectUrl = loginForm.getAttribute("data-redirecturl");
		const siteKey = loginForm.getAttribute("data-sitekey");
		const isCheckout = loginForm.getAttribute("data-ischeckout") === "true";
		ReactDom.render(
			<LoginForm
				successRedirectUrl={redirectUrl}
				resetPasswordUrl="/"
				SiteKey={siteKey}
				isCheckout={isCheckout}
			/>,
			loginForm
		);
	}
};
