import * as React from "react"
import { FunctionComponent, useState } from "react"
import { useForm } from "react-hook-form/dist/index.ie11"
import axios from "axios"
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner"

interface IProps {
	Token: string;
}

export const PasswordSetzenForm: FunctionComponent<IProps> = (props: IProps) => {
	const { register, handleSubmit, errors, reset, watch } = useForm();

	const [passswordResetError, setPassswordResetError] = useState(false);
	const [passwordResetSuccess, setPasswordSuccessReset] = useState(false);

	function onSubmit(password: Record<string, any>) {
		ShowSpinner()
		axios
			.post(`/umbraco/api/PasswordReset/PasswortSubmit`, password)
			.then(() => {
				HideSpinner();
				setPassswordResetError(false);
				setPasswordSuccessReset(true);
				reset({});
				setTimeout(() => window.location.href = "/login", 2000);
				;
			})
			.catch((error: any) => {
				HideSpinner()
				if (error?.response?.data?.Message) {
					setPassswordResetError(true);
				}
			})
	}

	return (
		<React.Fragment>
			<form onSubmit={handleSubmit(onSubmit)}>
			{passwordResetSuccess && (<div className="form-success">Passwort erfolgreich zurückgesetzt!</div>)}
            <div className="form-group row">
					<label className="col-sm-4 col-form-label">Passwort<span className="mandatory">*</span></label>
					<div className="col-sm-8">
					<input type="text" name="Token" className="form-control" id="" value={props.Token}
										ref={register()} hidden />
						<input type="password" name="Password" className="form-control" id="" placeholder="Neues Passwort" ref={register({ required: true, minLength: 8, pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,64}$/})}
								/>
								{errors.Password && (
									<div className="form-error">Bitte geben Sie ein gültiges Passwort ein.</div>
								)}
					</div>
				</div>
				<div className="form-group row">
					<label  className="col-sm-4 col-form-label">Passwort wiederholen<span className="mandatory">*</span></label>
					<div className="col-sm-8">
						<input type="password" className="form-control" id="" name="PasswordRepeat" placeholder="Neues Passwort wiederholen" ref={register({
										required: true,
										validate: (value) => value === watch("Password")
									})}
								/>
								{errors.PasswordRepeat && (
									<div className="form-error">Passwörter stimmen nicht überein.</div>
								)}
																<p className="info__text">
									Mindestanforderungen an das Passwort: mind. 8 Zeichen, ein
									Kleinbuchstabe, ein Großbuchstabe, eine Ziffer
								</p>
					</div>
				</div>
				<p>
					<button className="button button__cta button--red order__button mr-3">
						<span className="">
							Speichern
						</span>
					</button>
                    {passswordResetError && <div className="form-error">{passswordResetError}</div>}
					<button className="button button__cta button--grey order__button">
						<span className="">
							Abbrechen
						</span>
					</button>
				</p>
			</form>
		</React.Fragment>
	)
}



