declare const UC_UI: any;
declare const grecaptcha: any;

const recaptchaId = "Hko_qNsui-Q";
const googleMapsId = "S1pcEj_jZX";
const youtubeId = "BJz7qNsdj-7";
const yumpuId = "6LdBYXdAl";
const yumpuPlaceholder = `yumpu${yumpuId}`;

let recaptchaAllowed = false;

const createPlaceholderHtml = (templateId: string, className: string = null) : string => {
    return `
    <div class="ucConsent contentCtrls ${className ?? templateId}">
        <p class="heading">Wir benötigen Ihre Zustimmung, um einen Service zu laden!</p>
        <p>
            Wir verwenden an dieser Stelle einen Service, um Inhalte einzubetten. 
            Dieser Service kann Daten zu Ihren Aktivitäten sammeln. Bitte lesen Sie die Details durch und stimmen Sie der Nutzung des Service zu, um diese Inhalte anzuzeigen.
        </p>
        <a class="ucAgree button button__cta button--red" onclick="UC_UI.acceptService('${templateId}'); return false;"><span class="txt">Zustimmen</span></a>
        <a class="moreInfo link" onclick="UC_UI.showSecondLayer('${templateId}'); return false;">Mehr Informationen</a>
    </div>`
}

const checkForYouTubeIframe = (iframe: HTMLIFrameElement, youTubeAllowed: boolean): void => {
    const ucSrc = iframe.getAttribute("uc-src")
    if (ucSrc && ucSrc.indexOf("youtube") !== -1) {
        if (youTubeAllowed) {
            iframe.setAttribute("src", iframe.getAttribute("uc-src"));   
        }
        else {
            iframe.insertAdjacentHTML("afterend", createPlaceholderHtml(youtubeId))
            iframe.hidden = true;
        }
    }   
}

const checkForGoogleMapsIframe = (iframe: HTMLIFrameElement, googleMapsAllowed: boolean): void => {
    const ucSrc = iframe.getAttribute("uc-src")
    if (ucSrc && ucSrc.indexOf("google.com/maps") !== -1) {
        if (googleMapsAllowed) {
            iframe.setAttribute("src", iframe.getAttribute("uc-src"));   
        }
        else {
            iframe.insertAdjacentHTML("afterend", createPlaceholderHtml(googleMapsId))
            iframe.hidden = true;
        }  
    }
}

const checkForYumpuIframe = (iframe: HTMLIFrameElement, yumpuAllowed: boolean): void => {
    const ucSrc = iframe.getAttribute("uc-src")
    if (ucSrc && (ucSrc.indexOf("yumpu") !== -1 || ucSrc.indexOf("neuheiten.beck") !== -1)) {
        if (yumpuAllowed) {
            iframe.setAttribute("src", iframe.getAttribute("uc-src"));   
        }
        else {
            iframe.insertAdjacentHTML("afterend", createPlaceholderHtml(yumpuId, yumpuPlaceholder));
            iframe.hidden = true;
        }  
    }
}

const showrecaptcha = (): void => {
	document.querySelectorAll(".gRecaptchaWrapper").forEach(function (recaptchaWrapper: HTMLDivElement) {
		recaptchaWrapper.style.display = 'flex';
	})
}

const hiderecaptcha = (): void => {
	document.querySelectorAll(".gRecaptchaWrapper").forEach(function (recaptchaWrapper: HTMLDivElement) {
		recaptchaWrapper.style.display = 'none';
	})
}

const checkForRecaptcha = (isRecaptchaAllowed: boolean): void => {

	document.querySelectorAll(".gRecaptcha").forEach(function (recaptcha) {
		recaptcha.innerHTML = "";
		recaptcha.innerHTML = createPlaceholderHtml(recaptchaId);
	})	
	if (!isRecaptchaAllowed) {
		showrecaptcha();
    } 
    else {
		hiderecaptcha();
    }  
}

const showGoogleMapsIframe = (iframe: HTMLElement): void => {
    const ucSrc = iframe.getAttribute("uc-src")
    if (ucSrc && ucSrc.indexOf("google.com/maps") !== -1) {
        iframe.setAttribute("src", iframe.getAttribute("uc-src"));
        iframe.hidden = false;
        document.querySelector(`.${googleMapsId}`)?.remove();
    }  
}

const showYoutubeIframe = (iframe: HTMLElement): void => {
    const ucSrc = iframe.getAttribute("uc-src")
    if (ucSrc && ucSrc.indexOf("youtube") !== -1) {
        iframe.setAttribute("src", iframe.getAttribute("uc-src"));
        iframe.hidden = false;
        document.querySelector(`.${youtubeId}`)?.remove();
    }  
}

const showYumpuIframe = (iframe: HTMLElement): void => {
    const ucSrc = iframe.getAttribute("uc-src")
    if (ucSrc && (ucSrc.indexOf("yumpu") !== -1 || ucSrc.indexOf("neuheiten.beck") !== -1)) {
        iframe.setAttribute("src", iframe.getAttribute("uc-src"));
        iframe.hidden = false;
        document.querySelector(`.${yumpuPlaceholder}`)?.remove();
    }  
}

const addEventListeners = (): void => {

	window.addEventListener("onConsentChanged", function (e: any) {
		const iframes = document.querySelectorAll('iframe');

		if (e.detail.event === "consent_status" && e.detail["Google Maps"]) {
			iframes.forEach(function (iframe) {
				showGoogleMapsIframe(iframe);
			});
		}
		if (e.detail.event === "consent_status" && e.detail["YouTube Video"]) {
			iframes.forEach(function (iframe) {
				showYoutubeIframe(iframe);
			});
		}
		if (e.detail.event === "consent_status" && e.detail.yumpu) {
			iframes.forEach(function (iframe) {
				showYumpuIframe(iframe);
			});
		}
        if (e.detail.event === "consent_status" && e.detail.reCAPTCHA) {
			recaptchaAllowed = e.detail.reCAPTCHA;
		}

		if (e.detail.event === 'consent_status' && e.detail.action === 'onUpdateServices') {
			if (!e.detail.reCAPTCHA) {
				document.location.reload();
			} else {
				hiderecaptcha();
			}
		}
	});
}

export const initUserCentrics = (): void => {

	addEventListeners();

    if ((window as { [key: string]: any }).UC_UI !== undefined && UC_UI.isInitialized) {
        const youTubeAllowed = UC_UI.getServicesBaseInfo().find((service: any) => service.id === youtubeId).consent.status;
        const googleMapsAllowed = UC_UI.getServicesBaseInfo().find((service: any) => service.id === googleMapsId).consent.status;
        recaptchaAllowed = UC_UI.getServicesBaseInfo().find((service: any) => service.id === recaptchaId).consent.status;
        const yumpuAllowed = UC_UI.getServicesBaseInfo().find((service: any) => service.id === yumpuId).consent.status;
        const iframes = document.querySelectorAll('iframe');
        iframes.forEach(function(iframe) {
            checkForYouTubeIframe(iframe, youTubeAllowed);
            checkForGoogleMapsIframe(iframe, googleMapsAllowed);
            checkForYumpuIframe(iframe, yumpuAllowed);
        });
        checkForRecaptcha(recaptchaAllowed);
    } else {
        setTimeout(initUserCentrics, 100);
    }
};

export const IsRecaptchaAllowed = (): boolean => {
	return recaptchaAllowed;
}

document.addEventListener("DOMContentLoaded", () => {
    initUserCentrics();
});

