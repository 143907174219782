import * as React from "react"
import { ChangeEvent, FunctionComponent, useState } from "react"
import { ICommercetoolsCart } from "../../../ts/models/icommercetools-cart"

interface ICartPros {
    Cart: ICommercetoolsCart;
}

export const BasketTotalPrice: FunctionComponent<ICartPros> = (
    props: ICartPros
) => {

    const cart = props.Cart

    return (
        <div className="total text-right">
            <div className="row">
                <div className="col-md-6 col-lg-8"></div>
                <div className="col-md-4 col-lg-2 pr-4">
                    <span className="price">Gesamtbetrag</span>
                    <span>zzgl. gesetzl. MwSt.</span>
                </div>
                <div className="col-md-2 pr-4"><span className="price">{(cart.Cart.TotalNetPrice.Amount / 100).toLocaleString('de-De', { style: 'currency', currency: cart.Cart.TotalNetPrice.Currency.CurrencyISOCode })}</span></div>
            </div>
        </div>
    )
}