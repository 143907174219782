import * as React from "react";
import * as ReactDom from 'react-dom';
import { FlapOutReact } from "./components/flap-out-react";

export const RenderFlapOut = (): void => {
	const flapOutElement: NodeListOf<HTMLElement> = document.querySelectorAll(".flapOutReact");
	if (flapOutElement){
		flapOutElement.forEach(element => ReactDom.render(<FlapOutReact />, element));
	}

};
