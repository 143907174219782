import * as React from "react";
import * as ReactDom from 'react-dom';
import { ICustomer } from "../../ts/models/customer";
import { PersonlicheDaten } from "./components/personliche-daten";

export const RenderMeineDatenPersonlicheDaten= (): void => {
    const personlicheDaten: HTMLElement = document.querySelector("#customer");
	if (personlicheDaten){
        
        const customerJson = personlicheDaten.getAttribute("data-customer");
        const customer = JSON.parse(customerJson) as ICustomer;

		ReactDom.render(<PersonlicheDaten Customer={customer}/>, personlicheDaten);
	}  
};