import * as React from "react";
import { FunctionComponent, useState } from "react";
import { ICommercetoolsAddress } from "../../../ts/models/billing-address";
import { ILineitem } from "../../../ts/models/line-item";
import { IParticipantData } from "../../../ts/models/participant-data";

interface IProps {
	cartId: string;
	lineItemId: string;
	quantity: number;
	productId: number;
	counter: number;
	participant: IParticipantData;
	onRemoveParticipant: Function;
}

export const ParticipantBox: FunctionComponent<IProps> = (props: IProps) => {
	return (
		<>
			{props.participant && (
				<div className="col-lg-4 col-sm-12">
					<div className="participant__box empty">
						<div>
							<div className="headline">Teilnehmer {props.counter}</div>
						</div>
						<div className="content">
							<p>
								<span className="d-block name">
									<span className="anrede">
										{props.participant.Salutation == "F"
											? "Frau"
											: props.participant.Salutation == "M"
											? "Herr"
											: "Divers"}{" "}
									</span>
									<span className="titel">{props.participant.Title} </span>{" "}
									{props.participant.FirstName} {props.participant.LastName}
								</span>
								<span className="d-block additional_info">{props.participant.Professions}</span>
								<span className="d-block additional_info">{props.participant.Company}</span>
								<span className="d-block additional_info">
									{props.participant.CompanyAddition1}
								</span>
								<span className="d-block additional_info">
									{props.participant.CompanyAddition2}
								</span>
								<span className="d-block abteilung">{props.participant.Department}</span>
								<span className="d-block strasse">
									{props.participant.StreetName} {props.participant.StreetNumber}
								</span>
								<span className="d-block ort">
									{props.participant.PostalCode} {props.participant.City}
								</span>
								<span className="d-block land">{props.participant.Country}</span>

								<span className="d-block additional_info">{props.participant.Email}</span>
								<span className="d-block additional_info">{props.participant.Phone}</span>
							</p>
							<span className="text-right d-block edit">
								<button
									onClick={() =>
										props.onRemoveParticipant(
											props.cartId,
											props.lineItemId,
											props.participant.Id,
											props.quantity - 1,
											props.productId
										)
									}
									className="clear link"
									type="button"
								>
									entfernen
								</button>
							</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
