import { buildTrboRequestUrl } from "../shared/Trbo/trbo-request-builder";
import { mapTrboResponseToProduktkarussellModel } from "../shared/Trbo/trbo-response-mapper";
import {
	IProduktkarussellModel
} from "../models/i-product-carousel";
import { CreateSliderContent } from "./trbo-slider";
import { PromotionviewTracking } from "../functions/google-tracking";

declare const UC_UI: any;
declare const _trbo: any;
const trboId = 'ryxCCtrEX';

export const RenderTrboSliders = (): void => {
	const carousels = document.querySelectorAll(".trbo_slider__carousel");
	if (carousels && carousels.length > 0) {
		if ((window as { [key: string]: any }).UC_UI !== undefined && UC_UI.isInitialized) {
			if (UC_UI.getServicesBaseInfo().find((service: any) => service.id === trboId).consent.status && (window as { [key: string]: any })._trbo === undefined) {
				setTimeout(() => { RenderTrboSliders() }, 100);
				return;
			}

			carousels.forEach(carousel => {
				if (!UC_UI.getServicesBaseInfo().find((service: any) => service.id === trboId).consent.status) {
					carousel.parentElement.style.display = "none";
					return;
				}	

				const trboSlot = carousel.getAttribute("data-trbo-slot");

				if (trboSlot) {
					const url = buildTrboRequestUrl(trboSlot);
					fetch(encodeURI(url)).then((response) => {
						response.json().then((data) => {
							const model = mapTrboResponseToProduktkarussellModel(data) as IProduktkarussellModel;
							if (model && model.karussellItems && model.karussellItems.length > 0) {

								carousel.parentElement.style.display = "block";

								const slide = document.querySelector(".trbo-slide");

								if (slide) {
									CreateSliderContent(model, carousel, true);
								}

								PromotionviewTracking();
							}
						})
						.catch(error => {
							carousel.parentElement.style.display = "none";
							console.log(error)
						});
					}).catch(error => {
						carousel.parentElement.style.display = "none";
						console.log(error);
					});
				}
			});
		}
		else {
			setTimeout(() => { RenderTrboSliders() }, 100);
		}
	}
}
