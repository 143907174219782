const CLASSNAME_TO_LAZY_LOAD = "lazy-load";

// All lazy-load images
const lazyImages: NodeListOf<HTMLImageElement> = document.querySelectorAll(
	`img.${CLASSNAME_TO_LAZY_LOAD}`
);

export const LazyLoad = (): void => {
	if (window.IntersectionObserver) {
		console.log("Observer loaded");
		const lazyImageObserver: IntersectionObserver = new IntersectionObserver(
			(entries) => {
				entries.forEach((image: IntersectionObserverEntry) => {
					const lazyImage: HTMLImageElement = image.target as HTMLImageElement;
					lazyImage.src = lazyImage.dataset.lazysrc;
					if (lazyImage.dataset.lazysrcset) {
						lazyImage.srcset = lazyImage.dataset.lazysrcset;
					}
					lazyImage.classList.remove(CLASSNAME_TO_LAZY_LOAD);
					lazyImageObserver.unobserve(lazyImage);
				});
			}
		);

		lazyImages.forEach((lazyImage: HTMLImageElement) => {
			lazyImageObserver.observe(lazyImage);
		});
	}
};
