import Swiper, { SwiperOptions } from "swiper";
import { PromotionviewTracking } from "../functions/google-tracking";

// Swiper selectors
const SWIPER_PAGINATION = ".swiper-pagination";
const SWIPER_NEXT_SELECTOR = ".swiper-button-next";
const SWIPER_PREV_SELECTOR = ".swiper-button-prev";

const BANNER_CLASSNAME = ".slider__banner";
const CAROUSEL_CLASSNAME = ".slider__carousel";
const QUOTES_CLASSNAME = ".slider__quotes";

const bannerSlider: NodeListOf<HTMLElement> = document.querySelectorAll(
	".slider__banner"
);

const SwiperOptionsBanner: SwiperOptions = {
	loop: true,
	pagination: {
		el: SWIPER_PAGINATION,
		clickable: true
	},
	navigation: {
		nextEl: SWIPER_NEXT_SELECTOR,
		prevEl: SWIPER_PREV_SELECTOR
	},
	autoplay: {
		delay: 7000,
		disableOnInteraction: false
	},
	watchOverflow: true
};

const SwiperOptionsQuotes: SwiperOptions = {
	loop: true,
	autoplay: {
		delay: 7000,
		disableOnInteraction: false
	}
};

const SwiperOptionsCarousel: SwiperOptions = {
	loop: false,
	pagination: {
		el: SWIPER_PAGINATION,
		clickable: true
	},
	navigation: {
		nextEl: SWIPER_NEXT_SELECTOR,
		prevEl: SWIPER_PREV_SELECTOR
	},
	breakpoints: {
		"576": {
			slidesPerView: 1,
			slidesPerGroup: 1,
			spaceBetween: 10
		},
		"768": {
			slidesPerView: 2,
			slidesPerGroup: 2,
			spaceBetween: 20
		},
		"992": {
			slidesPerView: 3,
			slidesPerGroup: 3,
			spaceBetween: 40
		},
		"1200": {
			slidesPerView: 4,
			slidesPerGroup: 4,
			spaceBetween: 15
		}
	},
	observer: true,
	observeParents: true
};

export const SwiperSlider = (): void => {
	bannerSlider.forEach(banner => {
		const slideCount = parseInt(banner.getAttribute("data-slidecount"), 10);
		SwiperOptionsBanner.loop = slideCount > 1;
		const slide = new Swiper(banner, SwiperOptionsBanner)
		slide.on("transitionEnd", function() {
			PromotionviewTracking()
		})
	});

	((): Swiper => new Swiper(CAROUSEL_CLASSNAME, SwiperOptionsCarousel))();
	((): Swiper => new Swiper(QUOTES_CLASSNAME, SwiperOptionsQuotes))();
};
