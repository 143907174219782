import * as React from "react"
import { FunctionComponent, useState } from "react"
import { IParticipantData } from "../../../ts/models/participant-data"

interface IProps {
	counter: number
	participant: IParticipantData

}

export const ParticipantBox: FunctionComponent<IProps> = (props: IProps) => {
	return (
		<React.Fragment>
			<div className="col-lg-6 col-sm-12">
				<div className="participant__box">
					<div className="headline">
						Teilnehmer {props.counter}
									</div>
					<div className="content">
							<p>
								<span className="d-block name">
									<span className="anrede">
										{props.participant.Salutation == "F"
											? "Frau"
											: props.participant.Salutation == "M"
											? "Herr"
											: "Divers"}{" "}
									</span>
									<span className="titel">{props.participant.Title} </span>{" "}
									{props.participant.FirstName} {props.participant.LastName}
								</span>
								<span className="d-block additional_info">
									{props.participant.Company}
								</span>
								<span className="d-block additional_info">
									{props.participant.CompanyAddition1}
								</span>
								<span className="d-block additional_info">
									{props.participant.CompanyAddition2}
								</span>
								<span className="d-block abteilung">
									{props.participant.Department}
								</span>
								<span className="d-block strasse">
									{props.participant.StreetName} {props.participant.StreetNumber}
								</span>
								<span className="d-block ort">
									{props.participant.PostalCode} {props.participant.City}
								</span>
								<span className="d-block land">{props.participant.Country}</span>

								<span className="d-block additional_info">
									{props.participant.Email}
								</span>
								<span className="d-block additional_info">
									{props.participant.Phone}
								</span>
							</p>
					</div>
				</div>
			</div>

		</React.Fragment>
	)
}
