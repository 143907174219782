import * as React from "react"
import { FunctionComponent } from "react"
import {
	EAutoSuggestType,
	IFactFinderAutoSuggest,
	IFactFinderAutoSuggestWrapper
} from "../../interfaces/i-fact-finder-auto-suggest"
import { FlapOutDefaultResult } from "./flap-out-default-result"
import { FlapOutProductResult } from "./flap-out-product-result"

interface IFlapOutDisplayFilterProps {
	searchResult: IFactFinderAutoSuggestWrapper
	filterType: EAutoSuggestType
	headline: string,
	searchQuery: string
}

export const FlapOutDisplayFilter: FunctionComponent<IFlapOutDisplayFilterProps> = (
	props: IFlapOutDisplayFilterProps
) => {
	const { searchResult, filterType, headline, searchQuery } = props

	return (
		<>
		{searchResult.Suggestions.filter((suggestion: IFactFinderAutoSuggest) =>
					suggestion.Type === EAutoSuggestType[filterType]).length > 0 && 
			<div className="autosuggest__section">
				<p className="autosuggest__cat">{headline}</p>
				{searchResult.Suggestions.filter(
					(suggestion: IFactFinderAutoSuggest) =>
						suggestion.Type === EAutoSuggestType[filterType]
				).map((suggestion: IFactFinderAutoSuggest) => {
					return (
						<>
							{(filterType === EAutoSuggestType.category ||
								filterType === EAutoSuggestType.searchTerm) && (
								<FlapOutDefaultResult searchQuery={searchQuery} suggestion={suggestion} />
							)}
							{filterType === EAutoSuggestType.productName && (
								<FlapOutProductResult searchQuery={searchQuery} suggestion={suggestion} />
							)}
						</>
					)
				})}
			</div>
		}
		</>
	)
}
