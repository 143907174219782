import * as React from "react"
import { FunctionComponent } from "react"
import { IFlapOutResultProps } from "../../interfaces/i-fact-finder-auto-suggest"
import { getHighlightedText } from "../../../helper/select-text";

export const FlapOutDefaultResult: FunctionComponent<IFlapOutResultProps> = (
	props: IFlapOutResultProps,
) => {
	const { suggestion, searchQuery } = props

	return (
		<p className="autosuggest__item">
			<a
				href={suggestion.Attributes.DeepLink != null ? `${suggestion.Attributes.DeepLink}` : `/suche?query=${suggestion.Name}&userInput=${searchQuery}`}
				rel="noopener noreferrer"
				className="autosuggest__link link"
			>
				<span className="">{getHighlightedText(suggestion.Name, searchQuery)}</span>
			</a>
		</p>
	)
}
