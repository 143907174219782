import * as React from "react"
import { FunctionComponent } from "react"
import { IFlapOutResultProps } from "../../interfaces/i-fact-finder-auto-suggest"

export const FlapOutProductResult: FunctionComponent<IFlapOutResultProps> = (
	props: IFlapOutResultProps
) => {
	const { suggestion } = props

	return (
		<div className="autosuggest__item--event">
			<a href={suggestion.Attributes.DeepLink} rel="noopener noreferrer" className="autosuggest__link link">
				<p className="autosuggest__type">{suggestion.Attributes.SeminarTyp}</p>
				<p className="autosuggest__title">{suggestion.Name}</p>
				<p className="autosuggest__descr">
					{suggestion.Attributes.Untertitel}
				</p>
			</a>
		</div>
	)
}
