import axios, { AxiosResponse } from "axios"

const countItemElements: NodeListOf<HTMLElement> = document.querySelectorAll(
	".cart__indicator"
)

export const ItemCountForBasket = () =>{
    if(countItemElements.length > 0){
        axios
        .get(`/umbraco/api/miniCart/GetItemCountForBasket`)
        .then((result: AxiosResponse<number>) => {
            if(result.data > 0){
                countItemElements.forEach(countEl => {
                    if(countEl.hasAttribute("hidden")){
                        countEl.removeAttribute("hidden");
                    }
                    countEl.innerHTML = result.data.toString();
                });
            }else{
                countItemElements.forEach(countEl => {
                    countEl.setAttribute("hidden" , "true");
                });            
            }        
        })
        .catch((error: any) => {
        })       
    }
}