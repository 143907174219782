import * as React from "react";
import { FunctionComponent, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useForm } from "react-hook-form/dist/index.ie11";
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner";
import { ICommercetoolsCart } from "../../../ts/models/icommercetools-cart";
import { AddressBox } from "./bestellubersicht-address";
import { ParticipantBox } from "./bestellubersicht-participant";
import { BasketLineItem } from "./bestellubersicht-line-item";
import { ILineitem } from "../../../ts/models/line-item";
import { IParticipantResponse } from "../../../ts/models/participant-response";
import { BestellubersichtTotalPrice } from "./bestellubersicht-total-prices";
import { BackButton } from "../../shared/checkout/back-button";
import { IFormError } from "../../../ts/models/error/form-error";
import { ShowPopupError } from "../../../ts/components/error-popup";

interface IProps {
	Cart: ICommercetoolsCart;
	LineItemParticipants: IParticipantResponse[];
}

export const CheckoutBestellubersicht: FunctionComponent<IProps> = (props: IProps) => {
	const { register, handleSubmit, errors } = useForm({});

	const [checkedAGB, setCheckedAGB] = useState(false);

	const [checkedNewsletter, setCheckedNewsletter] = useState(false);

	const [cart, setCart] = useState(props.Cart);

	function CreateParticipantBox(lineItem: ILineitem): any[] {
		const participantsBoxes: React.ReactElement[] = [];
		let counter = 0;
		if (props.LineItemParticipants.length != 0) {
			props.LineItemParticipants.map((lineItemwWithParcipants: IParticipantResponse) => {
				if (lineItemwWithParcipants.LineItemId == lineItem.Id) {
					lineItemwWithParcipants.ParticipantData.map((participant, i) => {
						counter += 1;
						participantsBoxes.push(
							<ParticipantBox
								key={`${lineItem.Id}-${counter}`}
								participant={participant}
								counter={counter}
							/>
						);
					});
				}
			});
		}
		return participantsBoxes;
	}

	function GetCart() {
		axios
			.get(`/umbraco/api/cart/GetCart`)
			.then((result: AxiosResponse<ICommercetoolsCart>) => {
				setCart(result.data);
			})
			.catch(() => {});
	}

	function SubmitOrder(form: Record<string, any>) {
		ShowSpinner();
		const data = {
			Comment: form.commentbox,
			IsAGBChecked: form.checkedAGB === "true",
			IsNewsletterChecked: form.checkedNewsletter === "true"
		};
		axios
			.post(`/umbraco/api/cart/SubmitOrder`, data)
			.then((result: AxiosResponse<ICommercetoolsCart>) => {
				HideSpinner();
				localStorage.setItem("thankyouCart", JSON.stringify(result));
				window.location.href = "/danke";
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError;
					if (object.ErrorMessage) {
						GetCart();
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner();
			});
	}

	function CountParticipantsLineItem(lineItem: ILineitem): number {
		let counter = 0;
		if (props.LineItemParticipants.length !== 0) {
			props.LineItemParticipants.map((lineItemwWithParcipants: IParticipantResponse) => {
				if (lineItemwWithParcipants.LineItemId === lineItem.Id) {
					counter = lineItemwWithParcipants.ParticipantData.length;
				}
			});
		}
		return counter;
	}

	return (
		<div className="container">
			<hr />
			<div className="row">
				<section aria-labelledby="bestelldaten_header" className="col-lg-4 col-sm-12 col-xs-12">
					<div className="heading" id="bestelldaten_header">
						Ihre Bestelldaten
					</div>
					<AddressBox address={cart.CurrentAddresses.BillingAddress} title="Rechnungsadresse" />

					{cart.CurrentAddresses.IsShippingAddressSameAsBillingAddress === false && (
						<AddressBox
							address={cart.CurrentAddresses.ShippingAddress}
							title="Rechnungsversandadresse"
						/>
					)}
				</section>

				<section aria-label="Warenkorb" className="col-lg-8 col-sm-12 col-xs-12">
					<div className="heading">Ihr Warenkorb beinhaltet folgende Veranstaltungen</div>
					{cart.Cart.LineItems.map((lineItem: ILineitem, i) => (
						<section className="participants seminar">
							<div className="header">
								<div className="row">
									<div className="col-md-5 pl-4 head-cell ">Veranstaltung {i + 1}</div>
									<div className="col-md-2 pl-4 head-cell d-none d-lg-block">Teilnehmer</div>
									<div className="col-md-2 pl-4 head-cell d-none d-lg-block">Einzelpreis</div>
									<div className="col-md-3 pr-4 text-right head-cell d-none d-lg-block">
										Gesamtpreis
									</div>
								</div>
							</div>
							<div className="seminar__info checkout__area">
								<BasketLineItem
									lineItem={lineItem}
									participantsCounter={CountParticipantsLineItem(lineItem)}
								/>
							</div>

							<div className="row participant__boxes px-2">
								{CreateParticipantBox(lineItem)}
								<div className="col-12 text-right">
									<a href="/checkout-teilnehmer" className="link d-block edit">
										Teilnehmeranzahl/-daten bearbeiten
									</a>
								</div>
							</div>
						</section>
					))}

					<form aria-label="Bestellung abschließen" onSubmit={handleSubmit(SubmitOrder)}>
						<div className="checkout__area">
							<hr />
							<BestellubersichtTotalPrice Cart={cart} />

							<div className="order__comment">
								<div className="row">
									<div className="col-12">
										<label htmlFor="message_input_order">Ihre Mitteilung an uns</label>
										<textarea
											name="commentbox"
											id="message_input_order"
											ref={register()}
											placeholder="Ihre Mitteilung an uns"
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="check agb">
										<input
											type="hidden"
											name="checkedAGB"
											id="agb_input_order"
											ref={register({
												required: true,
												validate: (value) => value === "true"
											})}
											value={String(checkedAGB)}
										/>
										<span
											className={`check__box check__elm ${checkedAGB ? "checked" : ""}`}
											onClick={() => setCheckedAGB(!checkedAGB)}
										/>
										<label
											htmlFor="agb_input_order"
											className="check__elm checkbox__label d-inline"
										>
											Ich habe die
											<a href="/content/allgemeine-geschaftsbedingungen"> AGB </a>und{" "}
											<a href="/content/datenschutzerklarung">Datenschutzbedingungen </a> der
											BeckAkademie Seminare zur Kenntnis genommen.
										</label>
										{errors?.checkedAGB && (
											<div className="form-error">
												Bitte bestätigen Sie vor der Buchung noch, dass Sie unsere AGB und
												Datenschutzbestimmungen gelesen haben.
											</div>
										)}
									</div>
									<div className="check newsletter">
										<input
											type="hidden"
											id="newsletter_input_order"
											name="checkedNewsletter"
											ref={register()}
											value={String(checkedNewsletter)}
										/>
										<span
											className={`check__box check__elm ${checkedNewsletter ? "checked" : ""}`}
											onClick={() => setCheckedNewsletter(!checkedNewsletter)}
										/>
										<label
											htmlFor="newsletter_input_order"
											className="check__elm checkbox__label d-inline"
										>
											Mit der Zusendung von Newslettern zu Interessensgebieten bin ich
											einverstanden. Meine Daten werden lediglich zur Personalisierung des
											Newsletters verwendet und werden nicht an Dritte weitergegeben. Sie können der
											Verwendung Ihrer Adressdaten zu dem oben genannten Zweck jederzeit - z.B. mit
											einem einfachen Klick auf den Abmeldelink in unseren Newslettern -
											widersprechen.
										</label>
									</div>
								</div>
							</div>
						</div>

						<div className="row pb-4 process__nav">
							<div className="col-sm-3 col-12">
								<BackButton url="/checkout-teilnehmer" buttonText="Zurück" />
							</div>
							<div className="col-sm-9 col-12 text-right">
								<button className="button button__cta button--red order__button" type="submit">
									<span className="txt">Kostenpflichtig buchen</span>
									<i className="fas fa-arrow-right icon-fa icon--white" />
								</button>
							</div>
						</div>
					</form>
				</section>
			</div>
		</div>
	);
};
