export const countryList: ICountryDropdown[] = [
	{ vatNeeded: false, ignoreVatValidation: false, name: "Deutschland", value: "DE" },
	{ vatNeeded: true, ignoreVatValidation: false, name: "Österreich", value: "AT" },
	{ vatNeeded: true, ignoreVatValidation: true, value: "CH", name: "Schweiz" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "AF", name: "Afghanistan" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "EG", name: "Ägypten" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "AL", name: "Albanien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "DZ", name: "Algerien" },
	{
		vatNeeded: false,
		ignoreVatValidation: false,
		value: "VI",
		name: "Amerikanische Jungferninseln"
	},
	{ vatNeeded: false, ignoreVatValidation: false, value: "AD", name: "Andorra" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "AO", name: "Angola" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "AI", name: "Anguilla" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "AG", name: "Antigua und Barbuda" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GQ", name: "Äquatorialguinea" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "AR", name: "Argentinien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "AM", name: "Armenien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "AW", name: "Aruba" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "AZ", name: "Aserbaidschan" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "ET", name: "Äthiopien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "AU", name: "Australien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BS", name: "Bahamas" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BH", name: "Bahrain" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BD", name: "Bangladesch" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BB", name: "Barbados" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "BE", name: "Belgien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BZ", name: "Belize" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BJ", name: "Benin" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BM", name: "Bermuda" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BT", name: "Bhutan" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BO", name: "Bolivien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BA", name: "Bosnien-Herzegowina" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BW", name: "Botswana" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BR", name: "Brasilien" },
	{
		vatNeeded: false,
		ignoreVatValidation: false,
		value: "IO",
		name: "Brit. Territorium im Ind. Ozean"
	},
	{ vatNeeded: false, ignoreVatValidation: false, value: "VG", name: "Britische Jungferninseln" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BN", name: "Brunei Darussalam" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "BG", name: "Bulgarien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BF", name: "Burkina Faso" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "BI", name: "Burundi" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CL", name: "Chile" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CK", name: "Cook Inseln" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CR", name: "Costa Rica" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CI", name: "Cote d'Ivoire" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "DK", name: "Dänemark" },
	{
		vatNeeded: false,
		ignoreVatValidation: false,
		value: "CD",
		name: "Demokratische Republik Kongo"
	},
	{ vatNeeded: false, ignoreVatValidation: false, value: "DM", name: "Dominica" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "DO", name: "Dominikanische Republik" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "DJ", name: "Dschibuti" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "EC", name: "Ecuador" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SV", name: "El Salvador" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "ER", name: "Eritrea" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "EE", name: "Estland" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "FK", name: "Falklandinseln" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "FO", name: "Färöer" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "FJ", name: "Fidschi" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "FI", name: "Finnland" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "FR", name: "Frankreich" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GF", name: "Französisch-Guayana" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "PF", name: "Französisch-Polynesien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GA", name: "Gabun" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GM", name: "Gambia" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GE", name: "Georgien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GH", name: "Ghana" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GI", name: "Gibraltar" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GD", name: "Grenada" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "GR", name: "Griechenland" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GL", name: "Grönland" },
	{ vatNeeded: true, ignoreVatValidation: true, value: "GB", name: "Grossbritannien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GP", name: "Guadeloupe" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GT", name: "Guatemala" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GN", name: "Guinea" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GW", name: "Guinea-Bissau" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GY", name: "Guyana" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "HT", name: "Haiti" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "HN", name: "Honduras" },
	{
		vatNeeded: false,
		ignoreVatValidation: false,
		value: "HK",
		name: "Hong Kong/Volksrepublik China"
	},
	{ vatNeeded: false, ignoreVatValidation: false, value: "IN", name: "Indien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "ID", name: "Indonesien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "IQ", name: "Irak" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "IE", name: "Irland" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "IS", name: "Island" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "IL", name: "Israel" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "IT", name: "Italien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "JM", name: "Jamaika" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "JP", name: "Japan" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "YE", name: "Jemen" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "JO", name: "Jordanien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "KY", name: "Kaimaninseln" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "KH", name: "Kambodscha" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CM", name: "Kamerun" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CA", name: "Kanada" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CV", name: "Kap Verde" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "KZ", name: "Kasachstan" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "QA", name: "Katar" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "KE", name: "Kenia" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "KG", name: "Kirgisistan" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "KI", name: "Kiribati" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CO", name: "Kolumbien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "KM", name: "Komoren" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CG", name: "Kongo" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "HR", name: "Kroatien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CU", name: "Kuba" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "KW", name: "Kuwait" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "LA", name: "Laos" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "LS", name: "Lesotho" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "LV", name: "Lettland" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "LB", name: "Libanon" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "LR", name: "Liberia" },
	{
		vatNeeded: false,
		ignoreVatValidation: false,
		value: "LY",
		name: "Libysch-Arabische Dschamahirija"
	},
	{ vatNeeded: true, ignoreVatValidation: true, value: "LI", name: "Liechtenstein" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "LT", name: "Litauen" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "LU", name: "Luxemburg" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MO", name: "Macau" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MG", name: "Madagaskar" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MW", name: "Malawi" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MY", name: "Malaysia" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MV", name: "Malediven" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "ML", name: "Mali" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "MT", name: "Malta" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MA", name: "Marokko" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MQ", name: "Martinique" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MR", name: "Mauretanien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MU", name: "Mauritius" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "YT", name: "Mayotte" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MK", name: "Mazedonien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MX", name: "Mexiko" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "FM", name: "Mikronesien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MD", name: "Moldau" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MC", name: "Monaco" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MN", name: "Mongolei" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "ME", name: "Montenegro" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MS", name: "Montserrat" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MZ", name: "Mosambik" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "MM", name: "Myanmar" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "NA", name: "Namibia" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "NR", name: "Nauru" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "NP", name: "Nepal" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "NC", name: "Neukaledonien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "NZ", name: "Neuseeland" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "NI", name: "Nicaragua" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "NL", name: "Niederlande" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "NE", name: "Niger" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "NG", name: "Nigeria" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "NO", name: "Norwegen" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "OM", name: "Oman" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "PA", name: "Panama" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "PG", name: "Papua-Neuguinea" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "PY", name: "Paraguay" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "PE", name: "Peru" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "PH", name: "Philippinen" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "PN", name: "Pitcairninseln" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "PL", name: "Polen" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "PT", name: "Portugal" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "PR", name: "Puerto Rico" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "KR", name: "Republik Korea" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "RW", name: "Ruanda" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "RO", name: "Rumänien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "EH", name: "Sahara" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SB", name: "Salomonen" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "ZM", name: "Sambia" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "WS", name: "Samoa" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SM", name: "San Marino" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "ST", name: "Sao Tome und Principe" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SA", name: "Saudi-Arabien" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "SE", name: "Schweden" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SN", name: "Senegal" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "RS", name: "Serbien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SC", name: "Seychellen" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SL", name: "Sierra Leone" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "ZW", name: "Simbabwe" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SG", name: "Singapur" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "SK", name: "Slowakei" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "SI", name: "Slowenien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SO", name: "Somalia" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "ES", name: "Spanien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "LK", name: "Sri Lanka" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "KN", name: "St. Kitts und Nevis" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "LC", name: "St. Lucia" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "PM", name: "St. Pierre und Miquelon" },
	{
		vatNeeded: false,
		ignoreVatValidation: false,
		value: "VC",
		name: "St. Vincent und die Grenadinen"
	},
	{ vatNeeded: false, ignoreVatValidation: false, value: "ZA", name: "Südafrika" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SD", name: "Sudan" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "GS", name: "Südgeorgien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SR", name: "Suriname" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SZ", name: "Swasiland" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TJ", name: "Tadschikistan" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TW", name: "Taiwan (ROC)" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TZ", name: "Tansania" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TH", name: "Thailand" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TG", name: "Togo" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TO", name: "Tonga" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TT", name: "Trinidad und Tobago" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "SH", name: "Tristan da Cunha" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TD", name: "Tschad" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CZ", name: "Tschechische Republik" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TN", name: "Tunesien" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TR", name: "Türkei" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TM", name: "Turkmenistan" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TC", name: "Turks- und Caicosinseln" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "TV", name: "Tuvalu" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "UG", name: "Uganda" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "UA", name: "Ukraine" },
	{ vatNeeded: true, ignoreVatValidation: false, value: "HU", name: "Ungarn" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "UY", name: "Uruguay" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "UZ", name: "Usbekistan" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "VU", name: "Vanuatu" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "VA", name: "Vatikanstadt" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "VE", name: "Venezuela" },
	{
		vatNeeded: false,
		ignoreVatValidation: false,
		value: "AE",
		name: "Vereinigte Arabische Emirate"
	},
	{
		vatNeeded: false,
		ignoreVatValidation: false,
		value: "US",
		name: "Vereinigte Staaten von Amerika"
	},
	{ vatNeeded: false, ignoreVatValidation: false, value: "VN", name: "Vietnam" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "CN", name: "Volksrepublik China" },
	{ vatNeeded: false, ignoreVatValidation: false, value: "WF", name: "Wallis und Futuna" },
	{
		vatNeeded: false,
		ignoreVatValidation: false,
		value: "CF",
		name: "Zentralafrikanische Republik"
	},
	{ vatNeeded: true, ignoreVatValidation: false, value: "CY", name: "Zypern" }
]

export const professionList: IDropdownVaule[] = [
	{ value: "", name: "" },
	{ value: "F16", name: "Aufsichtsrat/Aufsichtsrätin" },
	{ value: "F091", name: "Betriebsrat/Betriebsrätin" },
	{ value: "BE64", name: "Datenschutzbeauftragte/r" },
	{ value: "BE813101", name: "FA/in Arbeitsrecht" },
	{ value: "BE813111", name: "FA/in Bau- und Architektenrecht" },
	{ value: "BE813112", name: "FA/in Erbrecht" },
	{ value: "BE813105", name: "FA/in Familienrecht" },
	{ value: "BE813117", name: "FA/in Gewerblicher Rechtsschutz" },
	{ value: "BE813116", name: "FA/in Handels- und Gesellschaftsrecht" },
	{ value: "BE813107", name: "FA/in Insolvenzrecht" },
	{ value: "BE813118", name: "FA/in IT-Recht" },
	{ value: "BE813114", name: "FA/in Medizinrecht" },
	{ value: "BE813108", name: "FA/in Miet- und Wohnungseigentumsrecht" },
	{ value: "BE813103", name: "FA/in Sozialrecht" },
	{ value: "BE813102", name: "FA/in Steuerrecht" },
	{ value: "BE813106", name: "FA/in Strafrecht" },
	{ value: "BE813115", name: "FA/in Urheber- und Medienrecht" },
	{ value: "BE813110", name: "FA/in Verkehrsrecht" },
	{ value: "BE813109", name: "FA/in Versicherungsrecht" },
	{ value: "BE72205", name: "Fachberater/in Unternehmensnachfolge" },
	{ value: "F12", name: "Geschäftsführer/in, Inhaber/in" },
	{ value: "BE724", name: "Insolvenzverwalter/in" },
	{ value: "F2041", name: "Justitiar/in" },
	{ value: "F202", name: "Kaufmännische/r Leiter/in" },
	{ value: "F209|F2091", name: "Leiter/in Compliance" },
	{ value: "F2021", name: "Leiter/in Rechnungswesen" },
	{ value: "F2042", name: "Leiter/in Recht" },
	{ value: "F2023", name: "Leiter/in Steuern" },
	{ value: "BE8132", name: "Notar/in" },
	{ value: "F201", name: "Personalleiter/in" },
	{ value: "F2012", name: "Personalreferent/in (Personalentwicklung)" },
	{ value: "BE8131", name: "Rechtsanwalt/Rechtsanwältin" },
	{ value: "BE722", name: "Steuerberater/in" },
	{ value: "BE725", name: "Unternehmensberater/in" },
	{ value: "F11", name: "Vorstand/Vorständin" },
	{ value: "BE8139", name: "Wirtschaftsjurist/in" },
	{ value: "BE721", name: "Wirtschaftsprüfer/in" }
]

export const fullProfessionList: IDropdownVaule[] = [
	{ value: "", name: "" },
	{ value: "F16", name: "Aufsichtsrat/Aufsichtsrätin" },
	{ value: "F091", name: "Betriebsrat/Betriebsrätin" },
	{ value: "BE64", name: "Datenschutzbeauftragte/r" },
	{ value: "BE872", name: "Dozent/in Hochschullehrer" },
	{ value: "BE813101", name: "FA/in Arbeitsrecht" },
	{ value: "BE813111", name: "FA/in Bau- und Architektenrecht" },
	{ value: "BE813112", name: "FA/in Erbrecht" },
	{ value: "BE813105", name: "FA/in Familienrecht" },
	{ value: "BE813117", name: "FA/in Gewerblicher Rechtsschutz" },
	{ value: "BE813116", name: "FA/in Handels- und Gesellschaftsrecht" },
	{ value: "BE813107", name: "FA/in Insolvenzrecht" },
	{ value: "BE813118", name: "FA/in IT-Recht" },
	{ value: "BE813114", name: "FA/in Medizinrecht" },
	{ value: "BE813108", name: "FA/in Miet- und Wohnungseigentumsrecht" },
	{ value: "BE813103", name: "FA/in Sozialrecht" },
	{ value: "BE813102", name: "FA/in Steuerrecht" },
	{ value: "BE813106", name: "FA/in Strafrecht" },
	{ value: "BE813115", name: "FA/in Urheber- und Medienrecht" },
	{ value: "BE813110", name: "FA/in Verkehrsrecht" },
	{ value: "BE813109", name: "FA/in Versicherungsrecht" },
	{ value: "BE72205", name: "Fachberater/in Unternehmensnachfolge" },
	{ value: "BE7", name: "Freie Berufe" },
	{ value: "F12", name: "Geschäftsführer/in, Inhaber/in" },
	{ value: "BE724", name: "Insolvenzverwalter/in" },
	{ value: "F2041", name: "Justitiar/in" },
	{ value: "F202|F2091", name: "Kaufmännische/r Leiter/in" },
	{ value: "F209", name: "Leiter/in Compliance" },
	{ value: "F2022", name: "Leiter/in Finanzen" },
	{ value: "F2021", name: "Leiter/in Rechnungswesen" },
	{ value: "F2042", name: "Leiter/in Recht" },
	{ value: "F2023", name: "Leiter/in Steuern" },
	{ value: "BE8132", name: "Notar/in" },
	{ value: "BE8135", name: "Patentanwalt/Patentanwältin" },
	{ value: "F201", name: "Personalleiter/in" },
	{ value: "F2012", name: "Personalreferent/in (Personalentwicklung)" },
	{ value: "BE8131", name: "Rechtsanwalt/Rechtsanwältin" },
	{ value: "BE8120", name: "Rechtspfleger/in" },
	{ value: "BE8133", name: "Referendar/in" },
	{ value: "BE8111", name: "Richter/in" },
	{ value: "BE76", name: "Staatsbedienstete" },
	{ value: "BE722", name: "Steuerberater/in" },
	{ value: "BE892", name: "Student/in Jura" },
	{ value: "BE891", name: "Student/in Wirtschaft" },
	{ value: "BE725", name: "Unternehmensberater/in" },
	{ value: "F11", name: "Vorstand/Vorständin" },
	{ value: "BE8139", name: "Wirtschaftsjurist/in" },
	{ value: "BE721", name: "Wirtschaftsprüfer/in" }
]

interface IDropdownVaule {
	name: string
	value: string
}

interface ICountryDropdown extends IDropdownVaule {
	vatNeeded: boolean
	ignoreVatValidation: boolean
}
