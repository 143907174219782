import * as React from "react"
import { FunctionComponent } from "react"
import { ICommercetoolsAddress } from "../../../ts/models/billing-address"

interface IProps {
	address: ICommercetoolsAddress
	title: string
}

export const AddressBox: FunctionComponent<IProps> = (props: IProps) => {
	return (
		<React.Fragment key={props.address.Id}>
			<div className="order__info">
				<div className="billing__box">
					<div className=" headline no-radio">
						<span>{props.title}</span>
					</div>
					<div className="content">
						<p>
							<span className="d-block name">
								<span className="anrede">
									{props.address.Salutation == "M"
										? "Herr"
										: props.address.Salutation == "F"
										? "Frau"
										: ""}{" "}
								</span>
								<span className="titel">{props.address.Title} </span>
								{props.address.FirstName} {props.address.LastName}
							</span>
							<span className="d-block additional_info">
								{props.address.Company}
							</span>
							<span className="d-block additional_info">
								{props.address.CompanyAddition1}
							</span>
							<span className="d-block additional_info">
								{props.address.CompanyAddition2}
							</span>
							{props.address.CustomerNo && (
								<span className="d-block kundennummer">
									Kd-Nr: {props.address.CustomerNo}
								</span>
							)}
							<span className="d-block abteilung">{props.address.Department}</span>
							<span className="d-block adresszusatz">
								{props.address.AdditionalInfo}
							</span>
							<span className="d-block strasse">
								{props.address.StreetName} {props.address.StreetNumber}
								{props.address.PostfachNo && (`Postfach ${props.address.PostfachNo}`) }
							</span>
							<span className="d-block ort">
								{props.address.PostalCode} {props.address.City}
							</span>
							<span className="d-block land">{props.address.Country}</span>
						</p>
					</div>
				</div>
				<a href="/checkout-adressen" className="link text-right d-block">
					{props.title} bearbeiten
				</a>
			</div>
		</React.Fragment>
	)
}
