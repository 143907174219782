import * as React from "react";
import { FunctionComponent, useState, useRef, createRef } from "react";
import axios, { AxiosResponse } from "axios";
import { ICommercetoolsAddress } from "../../../ts/models/billing-address";
import { DeliveryAddresses } from "./delivery-addresses";
import { BillingAddresses } from "./billing-addresses";
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner";
import { ICommercetoolsCart } from "../../../ts/models/icommercetools-cart";
import { BackButton } from "../../shared/checkout/back-button";
import { ICustomer } from "../../../ts/models/customer";
import { IFormError } from "../../../ts/models/error/form-error";
import { ShowPopupError } from "../../../ts/components/error-popup";
import { IResponseModelState } from "../../../ts/models/response-model-state";

interface IProps {
	BillingAddresses: ICommercetoolsAddress[];
	ShippingAddresses: ICommercetoolsAddress[];
	Cart: ICommercetoolsCart;
	Customer: ICustomer;
	IsPurchaser: boolean;
	IsEmployee: boolean;
}

export const CheckoutAddresses: FunctionComponent<IProps> = (props: IProps) => {
	const scrollToBillingForm = createRef<HTMLDivElement>();
	const scrollToShippingForm = createRef<HTMLDivElement>();

	const [showValidationError, setShowValidationError] = useState(false);

	const [existingBillingAddresses, setExistingBillingAddresses] = useState(props.BillingAddresses);

	const [showDeliveryAddresses, setShowDeliveryAddresses] = useState(
		props.Cart.CurrentAddresses != null &&
			!props.Cart.CurrentAddresses?.IsShippingAddressSameAsBillingAddress
	);

	const [currentBillingAddress, setcurrentBillingAddress] = useState(
		props.Cart.CurrentAddresses?.BillingAddress.Id ?? existingBillingAddresses.length > 0
			? existingBillingAddresses[0]?.Id
			: ""
	);

	const [currentEditBillingAddress, setcurrentEditBillingAddress] = useState<
		ICommercetoolsAddress
	>();

	const [existingShippingAddresses, setExistingShippingAddresses] = useState(
		props.ShippingAddresses
	);

	const [currentShippingAddress, setcurrentShippingAddress] = useState(
		props.Cart.CurrentAddresses?.ShippingAddress.Id ?? existingShippingAddresses?.length === 1
			? existingShippingAddresses[0]?.Id
			: ""
	);

	const [currentEditShippingAddress, setcurrentEditShippingAddress] = useState<
		ICommercetoolsAddress
	>();

	const [modelStateErrorsShipping, setModelStateErrorsShipping] = useState<IResponseModelState[]>();

	const [modelStateErrorsBilling, setModelStateErrorsBilling] = useState<IResponseModelState[]>();

	const [showBillingForm, setShowBillingForm] = useState(existingBillingAddresses.length < 1);

	const [showShippingForm, setShowShippingForm] = useState(existingShippingAddresses.length < 1);

	function editAddress(addressId: string) {
		setcurrentEditBillingAddress(existingBillingAddresses.find((el) => el.Id === addressId));
		setShowBillingForm(true);
		if (scrollToBillingForm.current) {
			scrollToBillingForm.current.scrollIntoView({
				behavior: "smooth"
			});
		}
	}

	function editShippingAddress(addressId: string) {
		setcurrentEditShippingAddress(existingShippingAddresses.find((el) => el.Id === addressId));
		setShowShippingForm(true);
		if (scrollToShippingForm.current) {
			scrollToShippingForm.current.scrollIntoView({
				behavior: "smooth"
			});
		}
	}

	function removeBillingAddress(addressId: string) {
		ShowSpinner();
		const data = { addressId };
		axios
			.post(`/umbraco/api/address/deleteBillingAddress`, data)
			.then((result: AxiosResponse<ICommercetoolsAddress[]>) => {
				setExistingBillingAddresses(result.data);
				if (addressId === currentBillingAddress) {
					setcurrentBillingAddress(null);
				}

				HideSpinner();
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError;
					setModelStateErrorsBilling(object.ValidationErrors);
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner();
			});
	}

	function removeShippingAddress(addressId: string) {
		ShowSpinner();
		const data = { addressId };
		axios
			.post(`/umbraco/api/address/deleteShippingAddress`, data)
			.then((result: AxiosResponse<ICommercetoolsAddress[]>) => {
				setExistingShippingAddresses(result.data);
				if (addressId === currentShippingAddress) {
					setcurrentShippingAddress(null);
				}
				HideSpinner();
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError;
					setModelStateErrorsShipping(object.ValidationErrors);
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner();
			});
	}

	function onBillingAddressFormSubmit(data: Record<string, any>) {
		ShowSpinner();
		const url: string =
			data.id !== null && data.id !== ""
				? `/umbraco/api/address/UpdateBillingAddress`
				: `/umbraco/api/address/createBillingAddress`;
		axios
			.post(url, data)
			.then((result: AxiosResponse<ICommercetoolsAddress[]>) => {
				setExistingBillingAddresses(result.data);
				if (result.data.length === 1) {
					setcurrentBillingAddress(result.data[0].Id);
				}
				HideSpinner();
				setShowBillingForm(false);
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError;
					setModelStateErrorsBilling(object.ValidationErrors);
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner();
			});
	}

	function onShippingAddressFormSubmit(data: Record<string, any>) {
		ShowSpinner();
		data.relatedBusinessAddressId = currentBillingAddress;
		const url: string =
			data.id !== null && data.id !== ""
				? `/umbraco/api/address/updateShippingAddress`
				: `/umbraco/api/address/createShippingAddress`;
		axios
			.post(url, data)
			.then((result: AxiosResponse<ICommercetoolsAddress[]>) => {
				setExistingShippingAddresses(result.data);
				if (result.data.length === 1) {
					setcurrentShippingAddress(result.data[0].Id);
				}
				HideSpinner();
				setShowShippingForm(false);
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError;
					setModelStateErrorsShipping(object.ValidationErrors);
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner();
			});
	}

	function ContinueOrder() {
		if (
			currentBillingAddress === null ||
			currentBillingAddress === "" ||
			(showDeliveryAddresses && (currentShippingAddress === null || currentShippingAddress === ""))
		) {
			setShowValidationError(true);
			return;
		}

		setShowValidationError(false);
		ShowSpinner();

		const data = {
			BillingAddressId: currentBillingAddress,
			ShippingAddressId: showDeliveryAddresses ? currentShippingAddress : null,
			UseBillingAsShippingAddress: !showDeliveryAddresses
		};
		axios
			.post(`/umbraco/api/cart/AddAddressToCart`, data)
			.then((result: AxiosResponse<ICommercetoolsAddress[]>) => {
				HideSpinner();
				window.location.href = "/checkout-teilnehmer";
			})
			.catch((error: any) => {
				HideSpinner();
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError;
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
			});
	}

	const selectBillingAddress = (addressId: string) => setcurrentBillingAddress(addressId);

	const selectShippingAddress = (addressId: string) => setcurrentShippingAddress(addressId);

	const toggleBillingForm = () => {
		setShowBillingForm(!showBillingForm);
		setcurrentEditBillingAddress(null);
	};

	const toggleShippingForm = () => {
		setShowShippingForm(!showShippingForm);
		setcurrentEditShippingAddress(null);
	};

	return (
		<div className="container">
			<BillingAddresses
				billingAddresses={existingBillingAddresses}
				onSelectBillingAddress={selectBillingAddress}
				currentAddres={currentBillingAddress}
				onFormSubmit={onBillingAddressFormSubmit}
				showBillingForm={showBillingForm}
				toggleForm={toggleBillingForm}
				onRemoveAddress={removeBillingAddress}
				onEditAddress={editAddress}
				editAddress={currentEditBillingAddress}
				addresBoxLarge={false}
				showRadioButton
				modelStateErrors={modelStateErrorsBilling}
				customer={props.Customer}
				isOnlineCustomer={props.Customer?.IsOnlineCustomer}
				isEmployee={props.IsEmployee}
				isPurchaser={props.IsPurchaser}
				scrollTo={scrollToBillingForm}
			/>
			<div className="row">
				<div className="col-12">
					<div
						className="check different__address"
						onClick={() => setShowDeliveryAddresses(!showDeliveryAddresses)}
					>
						<span
							className={`check__box check__elm billing__checkbox ${
								showDeliveryAddresses ? "checked" : ""
							}`}
						/>
						<span className="check__elm checkbox__label">abweichende Rechnungsversandadresse</span>
					</div>
				</div>
			</div>

			{showDeliveryAddresses && (
				<DeliveryAddresses
					shippingAddresses={existingShippingAddresses}
					onSelectShippingAddress={selectShippingAddress}
					currentShippingAddres={currentShippingAddress}
					onFormSubmit={onShippingAddressFormSubmit}
					showShippingForm={showShippingForm}
					toggleForm={toggleShippingForm}
					onRemoveShippingAddress={removeShippingAddress}
					onEditShippingAddress={editShippingAddress}
					editShippingAddress={currentEditShippingAddress}
					addresBoxLarge={false}
					showRadioButton
					scrollTo={scrollToShippingForm}
					isOnlineCustomer={props.Customer?.IsOnlineCustomer}
					isEmployee={props.IsEmployee}
					isPurchaser={props.IsPurchaser}
					modelStateErrors={modelStateErrorsShipping}
				/>
			)}
			<div className="row pb-4 process__nav">
				<div className="col-12 col-sm-3">
					<BackButton url="/checkout-login" buttonText="Zurück" />
				</div>
				<div className="col-12 col-sm-9 text-right">
					{existingBillingAddresses.length > 0 && (
						<button
							className="button button__cta button--red order__button"
							onClick={() => ContinueOrder()}
							type="button"
						>
							<span className="txt">Weiter zu Teilnehmerdaten</span>
							<i className="fas fa-arrow-right icon-fa icon--white" />
						</button>
					)}
					{showValidationError && (
						<div className="form-error">Bitte wählen Sie die Adressen aus.</div>
					)}
				</div>
			</div>
		</div>
	);
};
