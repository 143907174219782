import * as React from "react";
import * as ReactDom from 'react-dom';
import { ICommercetoolsCart } from "../../ts/models/icommercetools-cart";
import { CheckoutAddressesGuest } from "./components/checkout-addresses-guest";
import { GoogleAnalytictsTrackingCheckout} from "../../ts/functions/google-tracking"

export const RenderAddressesGuest = (): void => {
    const checkoutAddressesGuest: HTMLElement = document.querySelector("#checkoutAddressesGuest");
    
	if (checkoutAddressesGuest){
        const cartJson = checkoutAddressesGuest.getAttribute("data-cart");
        const cart = JSON.parse(cartJson) as ICommercetoolsCart;
        GoogleAnalytictsTrackingCheckout(cart, "3");

		ReactDom.render(<CheckoutAddressesGuest Cart={cart}/>, checkoutAddressesGuest);
	}
};  