import { ITrboResonse } from "../../models/Trbo/i-trbo-response";
import {
	IProduktkarussellItem,
	IProduktkarussellModel
} from "../../models/i-product-carousel";

export const mapTrboResponseToProduktkarussellModel = (
	trboResponse: ITrboResonse
): IProduktkarussellModel => {
	const response = {} as IProduktkarussellModel;
	response.karussellItems = [] as IProduktkarussellItem[];
	if (trboResponse.tracking.length > 0) {
		response.campaignId = trboResponse.tracking[0].campaign_id;
		response.campaignName = trboResponse.tracking[0].campaign_name;
		response.moduleId = trboResponse.tracking[0].module_id;
		response.moduleName = trboResponse.tracking[0].module_name;
		response.userType = trboResponse.tracking[0].user_type;
	}

	if (trboResponse.data) {
		for (let i = 0; i < trboResponse.data.length; i++) {
			for (let tr = 0; tr < trboResponse.data[i].length; tr++) {
				try {
					const trboProduct = trboResponse.data[i][tr];
					response.karussellItems.push({
						id: trboProduct.article,
						mainTitle: trboProduct.productname,
						url: trboProduct.deeplink,
						imageUrl: trboProduct.image_url,
						price: parseFloat(trboProduct.price).toLocaleString("de-De", {
							style: "currency",
							currency: "EUR"
						}),
						category: trboProduct.category,
						isTop: trboProduct.custom1 ? JSON.parse(trboProduct.custom1.toLowerCase()) as boolean : false,
						isNew: trboProduct.custom2 ? JSON.parse(trboProduct.custom2.toLowerCase()) as boolean : false,
						subTitle: trboProduct.custom3,
						type: trboProduct.custom4,
						location: trboProduct.custom5,
						date: trboProduct.custom6,
						moreDates: trboProduct.custom7 ? JSON.parse(trboProduct.custom7.toLowerCase()) as boolean : false,
						timeHours: trboProduct.custom8,
						mediaType: trboProduct.custom9,
						productDetailUrl: trboProduct.deeplink_orig,
						productId: parseInt(trboProduct.article,10)
					});
				} catch (error) {
					console.error(error);
				}
			}
		}
	}

	return response;
};
