import * as React from "react"
import { FunctionComponent, useState } from "react"
import { useForm } from "react-hook-form/dist/index.ie11"
import { ICustomer } from "../../../ts/models/customer"
import { fullProfessionList } from "../../../ts/constants/form-values"

interface IProps {
	toggleForm: Function
	customer: ICustomer
	onFormSubmit: Function
}

export const PersonlicheDatenForm: FunctionComponent<IProps> = (props: IProps) => {
	const { register, handleSubmit, errors } = useForm({
		defaultValues: {
			customerId: props.customer?.CustomerId,
			email: props.customer?.Email,
			gender: props.customer?.Gender,
			title: props.customer?.Title,
			surname: props.customer?.Surname,
			prename: props.customer?.Prename,
			profession: props.customer?.Professions
		}
	})

	function onSubmit(data: Record<string, any>) {
		props.onFormSubmit(data)
	}

	return (
		<>
			<div className="address__form">
				<div className="row">
					<div className="col-12">
						<span className="heading">Persönliche Daten ändern</span>
						<hr />
					</div>
					<div className="col-sm-12">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									E-Mail-Adresse<span className="mandatory">*</span>
									<br /> <span className="form-hint">(zur Kommunikation)</span>
								</label>
								<div className="col-sm-8">
									<input
										value={props.customer.CustomerId}
										ref={register()}
										type="text"
										name="customerId"
										hidden
									/>
									<input
										value={props.customer.CustomerMark}
										ref={register()}
										type="text"
										name="customerMark"
										hidden
									/>
									<input
										type="email"
										name="email"
										className="form-control"
										id=""
										placeholder="E-Mail"
										ref={register({
											required: true,
											pattern: /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/
										})}
									/>
									{errors?.email && (
										<div className="form-error">Bitte geben Sie Ihre Email ein.</div>
									)}
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									Anrede<span className="mandatory">*</span>
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										className="form-control"
										name="gender"
										id=""
										ref={register({ required: true })}
									>
										<option value="F">Frau</option>
										<option value="M">Herr</option>
										<option value="D">Divers</option>
									</select>
									{errors?.gender && (
										<div className="form-error">Bitte geben Sie Ihre Geschlecht ein.</div>
									)}
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">Titel</label>
								<div className="col-md-5 col-sm-8">
									<select
										className="form-control"
										id=""
										placeholder="Titel"
										name="title"
										ref={register()}
									>
										<option />
										<option>Dr.</option>
										<option>Prof.</option>
										<option>Prof. Dr.</option>
										<option>LL. M.</option>
										<option>Ass. Jur.</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									Vorname<span className="mandatory">*</span>
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="prename"
										className="form-control"
										placeholder="Vorname"
										ref={register({ required: true })}
									/>
									{errors?.prename && (
										<div className="form-error">Bitte geben Sie Ihren Vornamen ein.</div>
									)}
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">
									Nachname<span className="mandatory">*</span>
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="surname"
										className="form-control"
										placeholder="Nachname"
										ref={register({ required: true })}
									/>
									{errors?.surname && (
										<div className="form-error">Bitte geben Sie Ihren Nachnamen ein.</div>
									)}
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">Beruf/Funktion</label>
								<div className="col-sm-8">
									<select
										name="profession"
										ref={register()}
										className="form-control"
										id=""
										placeholder="Beruf/Funktion"
									>
										{fullProfessionList.map((profession) => (
											<option value={profession.value}>{profession.name}</option>
										))}
									</select>
								</div>
							</div>

							<div className="form-group row">
								<label className="col-sm-4 col-form-label">Passwort</label>
								<div className="col-sm-8 pt-2">
									<a href="/meine-daten/passwort-andern" className="link--underline">
										<i className="fas fa-chevron-right icon-red" />
										Passwort ändern
									</a>
								</div>
							</div>
							<p>
								<button className="button button__cta button--red order__button mr-3">
									<span className="">Speichern</span>
								</button>
								<button
									className="button button__cta button--grey order__button"
									onClick={() => props.toggleForm()}
								>
									<span className="">Abbrechen</span>
								</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}
