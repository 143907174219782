import * as React from "react"
import { createRef, FunctionComponent, useState } from "react"
import { ICommercetoolsAddress } from "../../../../ts/models/billing-address"
import { DeliveryAddresses } from "../../../checkout-addresses/components//delivery-addresses"
import axios, { AxiosResponse } from "axios"
import { HideSpinner, ShowSpinner } from "../../../../ts/components/spinner"
import { IResponseModelState } from "../../../../ts/models/response-model-state"
import { ShowPopupError } from "../../../../ts/components/error-popup"
import { IFormError } from "../../../../ts/models/error/form-error"
import { ICustomer } from "../../../../ts/models/customer"
import { Headline } from "../../../shared/checkout/headline"
import { AddressBox } from "../../../checkout-addresses/components/address-box"
import { AddButton } from "../../../shared/checkout/add-button"
import { ParticipantForm } from "./participant-form"

interface IProps {
	Particpants: ICommercetoolsAddress[]
	BillingAdressId: string
	IsPurchaser: boolean
	IsEmployee: boolean
	IsOnlineCustomer: boolean
}

export const Participants: FunctionComponent<IProps> = (props: IProps) => {
	const scrollToForm = createRef<HTMLDivElement>()
	const [currentEditShippingAddress, setcurrentEditShippingAddress] = useState<
		ICommercetoolsAddress
	>()

	const [existingParticipants, setExistingParticipants] = useState(props.Particpants)

	const [modelStateErrors, setModelStateErrors] = useState<IResponseModelState[]>()

	const [showForm, setShowForm] = useState(
		existingParticipants.length < 1
	)

	function removeAddress(addressId: string) {
		ShowSpinner()
		const data = { addressId }
		axios
			.post(`/umbraco/api/address/deleteParticipantAddress`, data)
			.then((result: AxiosResponse<ICommercetoolsAddress[]>) => {
				setExistingParticipants(result.data)
				HideSpinner()
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError
					setModelStateErrors(object.ValidationErrors)
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage)
					}
				}
				HideSpinner()
			})
	}

	function onFormSubmit(data: Record<string, any>) {
		ShowSpinner()
		data.relatedBusinessAddressId = props.BillingAdressId
		const url: string = `/umbraco/api/address/createParticipantAddress`
		axios
			.post(url, data)
			.then((result: AxiosResponse<ICommercetoolsAddress[]>) => {
				setExistingParticipants(result.data)
				HideSpinner()
				setShowForm(false)
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError
					setModelStateErrors(object.ValidationErrors)
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage)
					}
				}
				HideSpinner()
			})
	}

	const toggleForm = () => {
		setShowForm(!showForm)
		setcurrentEditShippingAddress(null)
		if (!showForm && scrollToForm.current) {
			scrollToForm.current.scrollIntoView({
				behavior: "smooth"
			})
		}
	}

	return (
		<React.Fragment>
			<div className="container">
				<Headline
					headline="Teilnehmerverwaltung"
					subHeadline="Hier können Sie Ihre Teilnehmer verwalten. Falls Sie Teilnehmerdaten ändern wollen, löschen Sie bitte bestehende Teilnehmerdaten und fügen Sie einen neuen Teilnehmer mit aktualisierten Daten hinzu."
				/>
				<div className="row billing__boxes">
					{existingParticipants.map((address: ICommercetoolsAddress) => (
						<AddressBox
							key={address.Id}
							address={address}
							onRemoveAddress={removeAddress}
							onEditAddress={null}
							type="shippingaddress"
							onSelect={null}
							currentAddress={null}
							title="Teilnehmer"
							addresBoxLarge={true}
							showRadioButton={false}
							isParticipant={true}
						/>
					))}
				</div>
				{!showForm && (
					<div className="row">
						<div className="col-12">
							<AddButton
								buttonText="Neuen Teilnehmer anlegen"
								onClick={toggleForm}
							></AddButton>
						</div>
					</div>
				)}

				<div ref={scrollToForm}></div>
				{showForm && (
					<ParticipantForm
						editAddress={null}
						toggleForm={toggleForm}
						onFormSubmit={onFormSubmit}
						showHeading={existingParticipants?.length > 0}
						modelStateErrors={modelStateErrors}
					/>
				)}
			</div>
		</React.Fragment>
	)
}
