import * as React from "react"
import { FunctionComponent} from "react"
import { ICommercetoolsCart } from "../../../ts/models/icommercetools-cart"

interface ICartPros {
    Cart: ICommercetoolsCart;
}

export const BestellubersichtTotalPrice: FunctionComponent<ICartPros> = (
    props: ICartPros
) => {

    const cart = props.Cart

    return (
        <div className="total text-right">
        <div className="row">
            <div className="offset-6 col-md-3 pr-4">
                <span className="d-block">Gesamtbetrag</span>
                <span>zzgl. gesetzl. MwSt.</span>
            </div>
            <div className="col-md-3 pr-4"><span className="" />{(props.Cart.Cart.TotalNetPrice.Amount / 100).toLocaleString('de-De', { style: 'currency', currency: props.Cart.Cart.TotalNetPrice.Currency.CurrencyISOCode })}</div>
        </div>
        {props.Cart.Cart.Discount !== null && (
            <div>
                <div className="row py-2">
                    <div className="offset-6 col-md-3 pr-4">
                        <span className="">Rabatt</span>
                    </div>
                    <div className="col-md-3 pr-4"><span className="" />{(props.Cart.Cart.Discount.TotalDiscountedAmount.Amount / 100).toLocaleString('de-De', { style: 'currency', currency: props.Cart.Cart.TotalNetPrice.Currency.CurrencyISOCode })}</div>
                </div>
                <div className="row">
                    <div className="offset-6 col-md-3 pr-4">
                        <span className="price">Gesamtbetrag</span>
                        <span>zzgl. gesetzl. MwSt.</span>
                    </div>
                    <div className="col-md-3 pr-4"><span className="price" />{(props.Cart.Cart.Discount.RemainingBalance.Amount / 100).toLocaleString('de-De', { style: 'currency', currency: props.Cart.Cart.TotalNetPrice.Currency.CurrencyISOCode })}</div>
                </div>
            </div>
        )}                           
    </div>
    )
}