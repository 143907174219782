import * as React from "react";
import * as ReactDom from 'react-dom';
import { ICommercetoolsCart } from "../../ts/models/icommercetools-cart";
import { Basket } from "./components/basket";
import { GoogleAnalytictsTrackingCheckout} from "../../ts/functions/google-tracking"

export const RenderBasket = (): void => {
    const checkoutBasket: HTMLElement = document.querySelector("#basketList");
    
	if (checkoutBasket){
        const cartJson = checkoutBasket.getAttribute("data-cart");
        const cart = JSON.parse(cartJson) as ICommercetoolsCart;
        GoogleAnalytictsTrackingCheckout(cart, "1");
		ReactDom.render(<Basket Cart={cart}/>, checkoutBasket);
	}

};