import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FunctionComponent } from "react"
import * as React from "react"

interface IProps {
	onClick: Function
	buttonText: string
}

export const AddButton: FunctionComponent<IProps> = (props: IProps) => {
	return (
		<div
			className="billing__box add__address text-center"
			onClick={() => props.onClick()}
		>
			<a className="link">
				<FontAwesomeIcon icon={faPlusCircle} /> {}
				{props.buttonText}
			</a>
		</div>
	)
}
