import * as React from "react"
import { FunctionComponent, useState } from "react"
import { PasswordSetzenForm } from "./password-setzen-form"
import { PasswordVergessenForm } from "./password-vergessen-form"

interface IProps {
	Token: string;
	SiteKey: string;
}


export const PasswordVergessen: FunctionComponent<IProps> = (props: IProps) => {

	return (
		<React.Fragment>
    {props.Token ==""  && (<div className="container">
            <div className="row register-form">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <h2 className="heading">Haben Sie Ihr Passwort vergessen?</h2>
                    <p>
                        Kein Problem! Tragen Sie einfach Ihre im beck-seminare.de-Konto verwendete E-Mail-Adresse ein und klicken Sie auf 'Abschicken'.
                        Sie bekommen dann umgehend eine E-Mail, mit der Sie ein neues Passwort festlegen können.
                    </p>
                    <div className="col-md-8 col-sm-8 col-xs-12">
							<PasswordVergessenForm SiteKey={props.SiteKey}></PasswordVergessenForm>
                    </div>
                </div>
            </div>
        </div>)    
    }
    {props.Token !="" && (
                <div className="container">
                <div className="row register-form">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <h2 className="heading">Passwort zurücksetzen</h2>
                        <p>
                            Geben Sie Ihr neues Passwort ein!
                        </p>
                        <div className="col-md-10 col-sm-10 col-xs-12">
                           <PasswordSetzenForm Token={props.Token}></PasswordSetzenForm>
                        </div>
                    </div>
                </div>
            </div>
    )
    }
		</React.Fragment>
	)
}
