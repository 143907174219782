import * as React from "react";
import { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import axios from "axios";
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner";
import { IFormError } from "../../../ts/models/error/form-error";
import { ShowPopupError } from "../../../ts/components/error-popup";
import { IResponseModelState } from "../../../ts/models/response-model-state";
import { professionList } from "../../../ts/constants/form-values";
import * as UserCentrics from "../../../ts/shared/user-centrics";
import { checkoutRegisterClickTracking } from "../../../ts/functions/google-tracking";

interface IProps {
	successRedirectUrl: string;
	siteKey: string;
	isCheckout: boolean;
}
declare let grecaptcha: any;

export const RegisterForm: FunctionComponent<IProps> = (props: IProps) => {
	const { register, handleSubmit, errors, watch } = useForm();

	function postData(data: Record<string, any>) {
		axios
			.post(`/umbraco/api/user/register`, data)
			.then(() => {
				HideSpinner();
				window.location.href = props.successRedirectUrl;
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError;
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner();
			});
	}

	function onSubmit(data: Record<string, any>) {
		if (!UserCentrics.IsRecaptchaAllowed()) {
			return;
		}

		ShowSpinner();

		grecaptcha.ready(function() {
			grecaptcha
				.execute(props.siteKey, { action: "submit" })
				.then(function(token: string) {
					if (token === "") {
						HideSpinner();
						return;
					}

					data.recaptchaValue = token;
					if (props.isCheckout) {
						checkoutRegisterClickTracking();
					}

					postData(data);
				})
				.catch(function(error: any) {
					console.error(error);
					HideSpinner();
				});
		});
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row sign-up__form">
					<div className="col-12">
						<hr />
						<span className="heading">Neuregistrierung</span>
						<p>
							Bitte füllen Sie alle mit <span className="mandatory">*</span> gekennzeichneten Felder
							aus.
						</p>
					</div>
					<div className="col-lg-6 col-sm-12">
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">
								E-Mail<span className="mandatory">*</span>
							</label>
							<div className="col-sm-8">
								<input
									type="email"
									className="form-control"
									name="username"
									placeholder="E-Mail"
									maxLength={60}
									ref={register({
										required: true,
										max: 60,
										pattern: /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/
									})}
								/>
								{errors.username && (
									<div className="form-error">Bitte geben Sie Ihre E-Mail ein.</div>
								)}
							</div>
						</div>

						<div className="form-group row" style={{ display: "none" }}>
							<label className="col-sm-4 col-form-label">
								Passwort<span className="mandatory">*</span>
							</label>
							<div className="col-sm-8">
								<input
									autoComplete="off"
									type="text"
									className="form-control"
									name="password_321"
									placeholder=""
									ref={register({
										required: false
									})}
								/>
							</div>
						</div>

						<div className="form-group row">
							<label className="col-sm-4 col-form-label">
								Passwort<span className="mandatory">*</span>
							</label>
							<div className="col-sm-8">
								<input
									type="password"
									className="form-control"
									name="password"
									placeholder="Passwort"
									ref={register({
										required: true,
										minLength: 8,
										pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/
									})}
								/>
								{errors.pass && (
									<div className="form-error">Bitte geben Sie ein gültiges Passwort ein.</div>
								)}
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">
								Passwort wiederholen<span className="mandatory">*</span>
							</label>
							<div className="col-sm-8">
								<input
									type="password"
									className="form-control"
									name="passwordTwo"
									placeholder="Passwort"
									ref={register({
										required: true,
										validate: (value) => value === watch("password")
									})}
								/>
								{errors.passwordTwo && (
									<div className="form-error">Passwörter stimmen nicht überein.</div>
								)}
								<p className="info__text">
									Mindestanforderungen an das Passwort: mind. 8 Zeichen, ein Kleinbuchstabe, ein
									Großbuchstabe, eine Ziffer
								</p>
							</div>
						</div>
					</div>

					<div className="col-lg-6 col-sm-12">
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">
								Anrede<span className="mandatory">*</span>
							</label>
							<div className="col-sm-8">
								<select
									name="salutation"
									ref={register({ required: true })}
									className="form-control"
								>
									<option value="" />
									<option value="F">Frau</option>
									<option value="M">Herr</option>
									<option value="D">Divers</option>
								</select>
								{errors.salutation && (
									<div className="form-error">Bitte wählen Sie Ihre Andrede aus.</div>
								)}
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Titel</label>
							<div className="col-sm-8">
								<select
									name="title"
									ref={register()}
									className="form-control"
									id=""
									placeholder="Titel"
								>
									<option />
									<option>Dr.</option>
									<option>Prof.</option>
									<option>Prof. Dr.</option>
									<option>LL. M.</option>
									<option>Ass. Jur.</option>
								</select>
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">
								Vorname<span className="mandatory">*</span>
							</label>
							<div className="col-sm-8">
								<input
									type="text"
									className="form-control"
									placeholder="Vorname"
									name="name"
									maxLength={30}
									ref={register({ required: true, max: 30 })}
								/>
								{errors.name && (
									<div className="form-error">Bitte geben Sie Ihren Vorname ein.</div>
								)}
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">
								Nachname<span className="mandatory">*</span>
							</label>
							<div className="col-sm-8">
								<input
									type="text"
									className="form-control"
									placeholder="Nachname"
									name="lastname"
									maxLength={30}
									ref={register({ required: true, max: 30 })}
								/>
								{errors.lastname && (
									<div className="form-error">Bitte geben Sie Ihren Nachname ein.</div>
								)}
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Beruf/Funktion</label>
							<div className="col-sm-8">
								<select
									name="profession"
									ref={register()}
									className="form-control"
									id=""
									placeholder="Beruf/Funktion"
								>
									{professionList.map((profession) => (
										<option value={profession.value}>{profession.name}</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>

				<div className="row gRecaptchaWrapper" style={{ display: "none" }}>
					<div className="gRecaptcha" />
				</div>

				<div className="row">
					<div className="col-12 col-sm-6" />
					<div className="col-12 col-sm-6 text-right">
						<button className="button button__cta button--red order__button">
							<span className="txt">Registrieren</span>
							<i className="fas fa-arrow-right icon-fa icon--white" />
						</button>
						<p>Nach der Registrierung können Sie Ihre Buchung fortsetzen.</p>
					</div>
				</div>
			</form>
		</>
	);
};
