import * as React from "react"
import { ChangeEvent, FunctionComponent, useState } from "react"
import { ILineitem } from "../../../ts/models/line-item"

interface ILineItemPros {
    lineItem: ILineitem;
    updateLineItem: Function;
    onRemoveItem: Function;
}

export const BasketLineItem: FunctionComponent<ILineItemPros> = (
    props: ILineItemPros
) => {

    const { lineItem, updateLineItem, onRemoveItem } = props

    return (
        <React.Fragment key={lineItem.Id}><div className="row item">
            <div className="col-md-4 pl-4">
                <a href={`/${lineItem.Product.SeoOptimizedUrlPart}/product/${lineItem.Product.ProductId}`} className="item__heading">{lineItem.Product.TitleMain}</a>
                <span className="item__place">{lineItem.Product.SeminarInfo.Engagement.Hotel.City}, {lineItem.Product.SeminarInfo.Engagement.Hotel.Title}</span>
                <span className="item__date">{lineItem.Product.SeminarInfo.Engagement.DateSummary}</span>
            </div>
            <div className="col-md-3 counterline">
                <div className="counter__elements">
                    <a onClick={() => updateLineItem(lineItem.Id, lineItem.Product.SeminarInfo.Engagement.EngagementId, lineItem.Quantity - 1, lineItem.Product.ProductId, 'eec.removeFromCart')} className="counter--sub"> - </a>
                    <input type="number" className="counter lineitem_count" min="1" value={lineItem.Quantity} />
                    <a onClick={() => updateLineItem(lineItem.Id , lineItem.Product.SeminarInfo.Engagement.EngagementId, lineItem.Quantity + 1, lineItem.Product.ProductId, 'eec.addToCart')} className="counter--add"> + </a>
                </div>
                <p className="clear">
                    <a onClick={() => onRemoveItem(lineItem.Id, lineItem.Product.ProductId)} className="delete-item link">
                        <img src="/assets/icons/svg/essentials/trash-black.svg" className="icon" />
                        <span className="text link">
                            &nbsp;<u>Artikel löschen</u>
								</span>
                    </a>
                </p>
            </div>
            <div className="col-md-2 price">
                <span className="item__price">
                    {(lineItem.SingleItemNetPrice.Amount / 100).toLocaleString('de-De', { style: 'currency', currency: lineItem.SingleItemNetPrice.Currency.CurrencyISOCode })}
                </span>
                <span>zzgl. gesetzl. MwSt.</span>
            </div>
            <div className="col-md-3 fullprice pr-4 text-right">
                <span className="item__fullprice">{(lineItem.TotalItemNetPrice.Amount / 100).toLocaleString('de-De', { style: 'currency', currency: lineItem.TotalItemNetPrice.Currency.CurrencyISOCode })}</span>
                <span className="item__mwst">zzgl. gesetzl. MwSt. </span>
                <span>{lineItem.SingleItemNetPrice.PriceDescriptionShort}</span>
                {lineItem.TotalItemNetPriceNonDiscountedOriginal && (
                    <div>
                        <span className="item__pricetype discount">inkl. Kollegenrabatt</span>
                        <span className="item__fullprice"><s>{(lineItem.TotalItemNetPriceNonDiscountedOriginal?.Amount / 100).toLocaleString('de-De', { style: 'currency', currency: lineItem.TotalItemNetPrice.Currency.CurrencyISOCode })}</s></span>
                        <span className="item__pricetype">regulärer Gesamtpreis</span>
                    </div>)
                }

            </div>
        </div>
            <div className="row item__divider">
                <div className="col-12">
                    <hr />
                </div>
            </div>
        </React.Fragment>
    )
}