import * as React from "react";
import { FunctionComponent } from "react";
import { Modal } from "react-bootstrap";

interface IProps {
	ShowModal: boolean;
	SetShowModal: Function;
	RemoveMissingParticipants: Function;
}

export const ParticipantsMissingModal: FunctionComponent<IProps> = (props: IProps) => {
	return (
		<Modal show={props.ShowModal} backdrop="static">
			<Modal.Header />
			<Modal.Body>
				<div className="section--white section__modal">
					<p className="headline__lg">
						Teilnehmeranzahl <span className="headline__part">verringern?</span>
					</p>
					<div className="row">
						<div className="col-12">
							<p className="text__module">
								Soll die ursprünglich gewählte Teilnehmerzahl auf die Anzahl der aktuell
								angemeldeten Teilnehmer verringert werden?
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<button
								className="button button__cta button--grey order__button mr-3 d-none d-lg-block float-left"
								onClick={() => props.SetShowModal(false)}
								type="button"
							>
								<span className="">Abbrechen</span>
							</button>
							<button
								className="button button__cta button--red order__button float-right"
								onClick={() => props.RemoveMissingParticipants()}
								type="submit"
							>
								<span className="txt">Teilnehmeranzahl verringern</span>
								<i className="fas fa-arrow-right icon-fa icon--white" />
							</button>
							<button
								className="button button__cta button--grey order__button mr-3 d-block d-lg-none"
								onClick={() => props.SetShowModal(false)}
								type="button"
							>
								<span className="">Abbrechen</span>
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};
