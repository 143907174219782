import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import { ICommercetoolsAddress } from "../../../ts/models/billing-address";
import { IResponseModelState } from "../../../ts/models/response-model-state";
import { validatePostcode } from "../../../ts/functions/form-validation";
import { countryList } from "../../../ts/constants/form-values";

interface IProps {
	editAddress: ICommercetoolsAddress;
	toggleForm: Function;
	onFormSubmit: Function;
	showHeading: boolean;
	modelStateErrors?: IResponseModelState[];
}

export const ShippingAddressForm: FunctionComponent<IProps> = (props: IProps) => {
	const { register, handleSubmit, errors, watch, getValues, setValue } = useForm({
		defaultValues: {
			company: props.editAddress?.Company,
			Addition1: props.editAddress?.Addition1,
			Addition2: props.editAddress?.Addition2,
			department: props.editAddress?.Department,
			industry: props.editAddress?.Industry,
			salutation: props.editAddress?.Salutation,
			title: props.editAddress?.Title,
			firstName: props.editAddress?.FirstName,
			lastName: props.editAddress?.LastName,
			additionalInfo: props.editAddress?.AdditionalInfo,
			streetName: props.editAddress?.StreetName,
			streetNumber: props.editAddress?.StreetNumber,
			postalCode: props.editAddress?.PostalCode,
			postfachNo: props.editAddress?.PostfachNo,
			postFilialeNo: props.editAddress?.PostFilialeNo,
			city: props.editAddress?.City,
			country: props.editAddress?.Country,
			shippingtype: props.editAddress?.PostfachNo ? "postfach" : "address"
		}
	});

	const [currentEditShippingAddress, setcurrentEditShippingAddress] = useState({
		...props.editAddress
	});
	const type = watch("shippingtype", "address");

	const [plzError, setPlZError] = useState(false);

	const country = watch("country"); // watch selcted country
	const [modelStateErrors, setModelStateErrors] = useState(props.modelStateErrors);

	const validatePlz = (value: string) => {
		const isValid = validatePostcode(value, country);
		setPlZError(!isValid);
		return isValid;
	};

	useEffect(() => {
		setModelStateErrors(props.modelStateErrors);
	}, [props.modelStateErrors]);

	function CreateApiError(key: string) {
		const error = modelStateErrors?.find((x) => x.Key === key);
		if (error !== undefined && error !== null) {
			return <div className="form-error">{error.Value[0]}</div>;
		}
	}

	function clearApiErrors() {
		setModelStateErrors([]);
	}

	function onSubmit(data: Record<string, any>) {
		props.onFormSubmit(data);
	}

	return (
		<>
			<form
				aria-labelledby={props.showHeading ? "headline_new_shippingaddress" : null}
				aria-label={!props.showHeading ? "Neue Rechnungsversandadresse anlegen" : null}
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="address__form">
					<div className="row">
						{props.showHeading && (
							<div className="col-12">
								{currentEditShippingAddress.Id && (
									<h2 id="headline_new_shippingaddress" className="heading">
										Rechnungsversandadresse - Bearbeiten
									</h2>
								)}
								{!currentEditShippingAddress.Id && (
									<h2 id="headline_new_shippingaddress" className="heading">
										Neue Rechnungsversandadresse anlegen
									</h2>
								)}
								<hr />
								<legend>
									Bitte füllen Sie alle mit <span className="mandatory">*</span> gekennzeichneten
									Felder aus.
								</legend>
							</div>
						)}

						<div className="col-sm-12">
							<div className="form-group row">
								<label htmlFor="company_shipping_input" className="col-sm-4 col-form-label">
									Firma / Name des Selbstständigen
									<span className="mandatory">*</span> <br />
									<span id="company_shipping_explanation" className="form-hint">
										(Bitte tragen Sie bei selbstständiger Tätigkeit hier die Firmierung / Ihren
										Namen, z.B. Rechtsanwältin Erika Musterfrau, ein.)
									</span>
								</label>
								<div className="col-sm-8">
									<input
										value={currentEditShippingAddress.Id}
										ref={register()}
										type="text"
										name="id"
										hidden
									/>
									<input
										type="text"
										name="company"
										id="company_shipping_input"
										aria-describedby={
											errors?.company
												? "company_shipping_input_error"
												: "company_shipping_explanation"
										}
										className="form-control"
										placeholder="Firmenname"
										maxLength={35}
										aria-invalid={errors?.company ? "true" : "false"}
										aria-required="true"
										ref={register({ required: true })}
									/>
									{errors?.company && (
										<span id="company_shipping_input_error" className="form-error">
											Bitte geben Sie Ihren Firmennamen ein.
										</span>
									)}
									{CreateApiError("data.Company")}
									<input
										type="text"
										name="Addition1"
										aria-label="Firmenzusatz 1"
										className="form-control mt-3"
										placeholder="Firmenzusatz1"
										maxLength={35}
										ref={register()}
									/>
									{CreateApiError("data.Addition1")}
									<input
										type="text"
										name="Addition2"
										aria-label="Firmenzusatz 2"
										className="form-control mt-3"
										placeholder="Firmenzusatz2"
										maxLength={35}
										ref={register()}
									/>
									{CreateApiError("data.Addition2")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="department_shipping_input" className="col-sm-4 col-form-label">
									Abteilung
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="department"
										id="department_shipping_input"
										className="form-control"
										placeholder="Abteilung"
										maxLength={35}
										ref={register()}
									/>
									{CreateApiError("data.Department")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="salutation_shipping_input" className="col-sm-4 col-form-label">
									Anrede
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										name="salutation"
										className="form-control"
										id="salutation_shipping_input"
										ref={register()}
									>
										<option value="" />
										<option value="F">Frau</option>
										<option value="M">Herr</option>
										<option value="D">Divers</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="title_shipping_input" className="col-sm-4 col-form-label">
									Titel
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										name="title"
										className="form-control"
										id="title_shipping_input"
										placeholder="Titel"
										ref={register()}
									>
										<option />
										<option>Dr.</option>
										<option>Prof.</option>
										<option>Prof. Dr.</option>
										<option>LL. M.</option>
										<option>Ass. Jur.</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="firstname_shipping_input" className="col-sm-4 col-form-label">
									Vorname
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="firstName"
										id="firstname_shipping_input"
										className="form-control"
										placeholder="Vorname"
										maxLength={30}
										aria-describedby={errors?.firstName ? "firstname_input_shipping_error" : ""}
										aria-invalid={errors?.firstName ? "true" : "false"}
										ref={register({ maxLength: 30 })}
									/>
									{errors?.firstName && (
										<span id="firstname_input_shipping_error" className="form-error">
											Der Vorname darf maximal 30 Zeichen lang sein.
										</span>
									)}
									{CreateApiError("data.FirstName")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="lastname_shipping_input" className="col-sm-4 col-form-label">
									Nachname
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="lastName"
										id="lastname_shipping_input"
										className="form-control"
										placeholder="Nachname"
										aria-describedby={errors?.lastName ? "lastname_input_shipping_error" : ""}
										aria-invalid={errors?.lastName ? "true" : "false"}
										maxLength={30}
										ref={register({ maxLength: 30 })}
									/>
									{errors?.lastName && (
										<span id="lastname_input_shipping_error" className="form-error">
											Der Nachname darf maximal 30 Zeichen lang sein.
										</span>
									)}
									{CreateApiError("data.LastName")}
								</div>
							</div>

							<fieldset aria-label="Art der Adresse" className="form-group row">
								<div className="col-sm-4" />
								<div className="col-sm-8">
									<div
										className="radio-item d-inline"
										onClick={() => setValue("shippingtype", "address")}
									>
										<input
											type="radio"
											id="address_shipping_switch"
											ref={register}
											name="shippingtype"
											value="address"
											checked={getValues("shippingtype") === "address"}
										/>
										<label htmlFor="address_shipping_switch">Adresse</label>
									</div>
									<div
										className="radio-item d-inline ml-3"
										onClick={() => setValue("shippingtype", "postfach")}
									>
										<input
											type="radio"
											id="postfach_shipping_switch"
											ref={register}
											name="shippingtype"
											value="postfach"
											checked={getValues("shippingtype") === "postfach"}
										/>
										<label htmlFor="postfach_shipping_switch">Postfach</label>
									</div>
								</div>
							</fieldset>

							{type === "address" && (
								<fieldset aria-label="Straße">
									<div className="form-group row">
										<label
											htmlFor="additionalinfo_shipping_input"
											className="col-sm-4 col-form-label"
										>
											Adresszusatz
										</label>
										<div className="col-sm-8">
											<input
												type="text"
												id="additionalinfo_shipping_input"
												name="additionalInfo"
												className="form-control"
												placeholder="Adresszusatz"
												maxLength={35}
												ref={register({ maxLength: 35 })}
											/>
											{CreateApiError("data.AdditionalInfo")}
										</div>
									</div>
									<div className="form-group row">
										<div className="col-sm-4 col-form-label">
											<label htmlFor="street_input_shipping" className="col-form-label">
												Straße /&nbsp;
											</label>
											<label htmlFor="streetnumber_input_shipping" className="col-form-label">
												Hausnummer <span className="mandatory">*</span>
											</label>
										</div>
										<div className="col-sm-5 col-xs-1 mobile-space">
											<input
												type="text"
												name="streetName"
												id="street_input_shipping"
												className="form-control"
												placeholder="Straße"
												maxLength={46}
												aria-required="true"
												aria-describedby={
													errors?.streetName ? "streetname_input_shipping_error" : ""
												}
												aria-invalid={errors?.streetName ? "true" : "false"}
												ref={register({ required: true, maxLength: 46 })}
											/>
											{errors?.streetName && (
												<span id="streetname_input_shipping_error" className="form-error">
													Bitte geben Sie die Straße ein.
												</span>
											)}
											{CreateApiError("data.StreetName")}
										</div>
										<div className="col-sm-3 col-xs-1 mobile-space">
											<input
												type="text"
												name="streetNumber"
												id="streetnumber_input_shipping"
												className="form-control"
												placeholder="Hausnummer"
												aria-required="true"
												aria-describedby={
													errors?.streetNumber ? "streetnumber_input_shipping_error" : ""
												}
												aria-invalid={errors?.streetNumber ? "true" : "false"}
												ref={register({ required: true })}
											/>
											{errors?.streetNumber && (
												<span id="streetnumber_input_shipping_error" className="form-error">
													Bitte geben Sie die Hausnummer ein.
												</span>
											)}
											{CreateApiError("data.StreetNumber")}
										</div>
									</div>
								</fieldset>
							)}

							{type === "postfach" && (
								<fieldset aria-label="Postfach" className="form-group row">
									<label htmlFor="postfach_input_shipping" className="col-sm-4 col-form-label">
										Postfach<span className="mandatory">*</span>
									</label>
									<div className="col-sm-3 col-xs-1">
										<input
											type="text"
											name="postfachNo"
											id="postfach_input_shipping"
											className="form-control"
											placeholder="Postfach"
											aria-required="true"
											aria-describedby={errors?.postfachNo ? "postfach_input_shipping_error" : ""}
											aria-invalid={errors?.postfachNo ? "true" : "false"}
											ref={register({ required: true })}
										/>
										{errors?.postfachNo && (
											<span id="postfach_input_shipping_error" className="form-error">
												Bitte geben Sie die Postfachnummer ein.
											</span>
										)}
										{CreateApiError("data.PostfachNo")}
									</div>
								</fieldset>
							)}

							<div className="form-group row">
								<div className="col-sm-4">
									<label htmlFor="plz_input_shipping" className="col-form-label">
										PLZ /&nbsp;
									</label>
									<label htmlFor="city_input_shipping" className="col-form-label">
										Ort<span className="mandatory">*</span>
									</label>
								</div>
								<div className="col-sm-3 col-xs-1 mobile-space">
									<input
										type="text"
										name="postalCode"
										id="plz_input_shipping"
										className="form-control"
										placeholder="PLZ"
										aria-required="true"
										aria-describedby={errors?.postalCode ? "plz_input_shipping_error" : ""}
										aria-invalid={errors?.postalCode ? "true" : "false"}
										ref={register({ required: true, validate: validatePlz })}
									/>
									{errors?.postalCode && (
										<span id="plz_input_shipping_error" className="form-error">
											Bitte geben Sie eine valide PLZ ein.
										</span>
									)}
									{CreateApiError("data.PostalCode")}
								</div>
								<div className="col-sm-5 col-xs-1 mobile-space">
									<input
										type="text"
										name="city"
										id="city_input_shipping"
										className="form-control"
										placeholder="Ort"
										aria-required="true"
										aria-describedby={errors?.city ? "city_input_shipping_error" : ""}
										aria-invalid={errors?.city ? "true" : "false"}
										ref={register({ required: true })}
									/>
									{errors?.city && (
										<span id="city_input_shipping_error" className="form-error">
											Bitte geben Sie den Ort ein.
										</span>
									)}
									{CreateApiError("data.City")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="county_input_shipping" className="col-sm-4 col-form-label">
									Land: <span className="mandatory">*</span>
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										name="country"
										className="form-control"
										id="county_input_shipping"
										placeholder=""
										aria-required="true"
										ref={register({ required: true })}
									>
										{countryList.map((countryItem) => (
											<option value={countryItem.value}>{countryItem.name}</option>
										))}
									</select>
									{errors?.country && (
										<span className="form-error">Bitte wählen Sie Ihre Stadt aus.</span>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-4" />
						<div className="col-12 col-md-8 text-right">
							<button
								className="button button__cta button--grey order__button mr-3 d-none d-md-inline-block"
								onClick={() => props.toggleForm()}
								type="button"
							>
								<span className="">Abbrechen</span>
								<i className="fas fa-arrow-right icon-fa icon--white" />
							</button>
							<button
								className="button button__cta button--red order__button"
								onClick={clearApiErrors}
								type="submit"
							>
								<span className="">
									{props.editAddress != null ? "Speichern" : "Neue Adresse anlegen"}
								</span>
								<i className="fas fa-arrow-right icon-fa icon--white" />
							</button>
							<button
								className="button button__cta button--grey order__button mr-3 d-inline-block d-md-none"
								onClick={() => props.toggleForm()}
								type="button"
							>
								<span className="">Abbrechen</span>
								<i className="fas fa-arrow-right icon-fa icon--white" />
							</button>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};
