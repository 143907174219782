export interface IFactFinderAutoSuggestWrapper {
	Suggestions: IFactFinderAutoSuggest[]
}

export interface IFactFinderAutoSuggest {
	Attributes: IFactFinderAutoSuggestAttributes
	HitCount: number
	Image: string
	Name: string
	SearchParams: string
	Type: string
}

export interface IFactFinderAutoSuggestAttributes {
	SeminarTyp: string
	DeepLink: string
	Id: string
	Untertitel: string
}

export interface IFlapOutResultProps {
	suggestion: IFactFinderAutoSuggest
	searchQuery: string
}

export enum EAutoSuggestType {
	"productName",
	"category",
	"searchTerm"
}
