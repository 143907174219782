
const backToTopButton: HTMLElement = document.querySelector("#backToTop");

function scroll(): void {
	if (document.body.scrollTop > 320 || document.documentElement.scrollTop > 320) {
		backToTopButton.style.display = "block";
	} else {
		backToTopButton.style.display = "none";
	}
}

// When the user clicks on the button, scroll to the top of the document
function topFunction(ev: MouseEvent): void {
	ev.preventDefault();
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
}

export const ScrollToTop = (): void => {
	window.addEventListener("scroll", scroll);
	backToTopButton.addEventListener("click", topFunction);
};
