import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
	faArrowLeft,
	faArrowRight,
	faChevronUp,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faSearch,
	faPlusCircle,
	faTimes
} from "@fortawesome/free-solid-svg-icons";
import { MobileMenu } from "./components/mobile-menu";
import { IconHover } from "./functions/icon-hover";
import { LazyLoad } from "./functions/lazy-load";
import { SwiperSlider } from "./components/swiper-slider";
import { RenderFlapOut } from "../react/flap-out";
import { AccountPopup, CloseAccountPopup } from "./components/meine-daten-popup";
import { ScrollToTop } from "./components/backtotop-button";
import { RenderBasket } from "../react/checkout-basket";
import { RenderAddresses } from "../react/checkout-addresses";
import { RenderBestellubersicht } from "../react/checkout-bestellubersicht";
import { RenderParticipants } from "../react/checkout-participants";
import { RenderLoginForm } from "../react/login";
import { RenderLogout } from "../react/logout";
import { RenderRegisterForm } from "../react/register";
import { RenderMeineDatenRechnungsadressen } from "../react/meine-daten-rechnungsadressen";
import { RenderMeineDatenVersandadressen } from "../react/meine-daten-versandadressen";
import { RenderMeineDatenPersonlicheDaten } from "../react/meien-daten-persönliche-daten";
import { RenderMeineDatenLogout } from "../react/meine-daten-logout";
import { RegisterButton } from "./components/register-button";
import { RenderAddressesGuest } from "../react/checkout-addresses-guest";
import { RenderPasswordUpdate } from "../react/meine-daten-password-andern";
import { RenderNewsletterGrid } from "../react/newsletter";
import { RenderPasswordVergessen } from "../react/meine-daten-password-vergessen";
import { ItemCountForBasket } from "./shared/mini-cart";
import { RenderMeineDatenParticipants } from "../react/meine-daten/participants";
import { PromotionClickTracking, PromotionviewTracking } from "./functions/google-tracking";
import { RenderTrboSliders } from "./components/trbo-carousel";
import { RenderTrboDynamicSliders } from "./components/trbo-dynamic-carousel";

library.add(
	faChevronUp,
	faChevronDown,
	faSearch,
	faChevronLeft,
	faChevronRight,
	faArrowRight,
	faArrowLeft,
	faPlusCircle,
	faTimes
);

document.addEventListener("DOMContentLoaded", () => {
	dom.i2svg();

	PromotionviewTracking();
	PromotionClickTracking();
	RenderNewsletterGrid();
	MobileMenu();
	AccountPopup();
	CloseAccountPopup();
	IconHover();
	LazyLoad();
	SwiperSlider();
	RenderFlapOut();
	RenderLoginForm();
	RenderLogout();
	RenderRegisterForm();
	RenderBasket();
	RenderAddresses();
	RenderAddressesGuest();
	RenderParticipants();
	RenderMeineDatenPersonlicheDaten();
	RenderMeineDatenRechnungsadressen();
	RenderMeineDatenVersandadressen();
	RenderMeineDatenLogout();
	RenderMeineDatenParticipants();
	RenderBestellubersicht();
	RenderPasswordUpdate();
	RenderPasswordVergessen();
	ScrollToTop();
	RegisterButton();
	ItemCountForBasket();
	RenderTrboSliders();
	RenderTrboDynamicSliders();
});

let timer: number;
document.addEventListener("scroll", function(e) {
	if (timer) {
		window.clearTimeout(timer);
	}
	timer = window.setTimeout(function() {
		PromotionviewTracking();
	}, 100);
});
