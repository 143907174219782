import * as React from "react"
import { FunctionComponent, useState } from "react"
import { useForm } from "react-hook-form/dist/index.ie11"
import axios from "axios"
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner"
import { ShowPopupError } from "../../../ts/components/error-popup"
import { IFormError } from "../../../ts/models/error/form-error"
import { IResponseModelState } from "../../../ts/models/response-model-state"

interface IProps {
}

export const PasswordUpdate: FunctionComponent<IProps> = (props: IProps) => {
    const { register, handleSubmit, errors, watch } = useForm();
    const [updateError, setUpdateError] = useState(false);

    function toggleForm(){
        window.location.href = "/meine-daten/personliche-daten";
    }

	function onSubmit(data: Record<string, any>) {
		ShowSpinner();
		axios
			.post(`/umbraco/api/user/UpdatePassword`, data)
			.then(() => {
                setUpdateError(false)
				window.location.href = "/meine-daten/personliche-daten";
				HideSpinner();
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				HideSpinner()
			})
	}

	return (
		<React.Fragment>
            <span className="heading">Passwort ändern</span>
				<hr />
			<form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">
					<label className="col-sm-4 col-form-label">Aktuelles Password<span className="mandatory">*</span></label>
					<div className="col-sm-8">
						<input type="password" name="oldPassword" className="form-control" id="" placeholder="Aktuelles Passwort" ref={register({ required: true})}
								/>
								{errors.oldPassword && (
									<div className="form-error">Bitte geben Sie Ihr aktuelles Passwort ein.</div>
								)}
					</div>
				</div>
            <div className="form-group row">
					<label className="col-sm-4 col-form-label">Passwort<span className="mandatory">*</span></label>
					<div className="col-sm-8">
						<input type="password" name="newPassword" className="form-control" id="" placeholder="Neues Passwort" ref={register({ required: true, minLength: 8, pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,64}$/})}
								/>
								{errors.newPassword && (
									<div className="form-error">Bitte geben Sie ein gültiges Passwort ein.</div>
								)}
					</div>
				</div>
				<div className="form-group row">
					<label  className="col-sm-4 col-form-label">Passwort wiederholen<span className="mandatory">*</span></label>
					<div className="col-sm-8">
						<input type="password" className="form-control" id="" name="passwordTwo" placeholder="Neues Passwort wiederholen" ref={register({
										required: true,
										validate: (value) => value === watch("newPassword")
									})}
								/>
								{errors.passwordTwo && (
									<div className="form-error">Passwörter stimmen nicht überein.</div>
								)}
								<p className="info__text">
									Mindestanforderungen an das Passwort: mind. 8 Zeichen, ein
									Kleinbuchstabe, ein Großbuchstabe, eine Ziffer
								</p>
					</div>
				</div>
				<p>
					<button className="button button__cta button--red order__button mr-3">
						<span className="">
							Speichern
						</span>
					</button>
					<button className="button button__cta button--grey order__button" onClick={() => toggleForm()}>
						<span className="">
							Abbrechen
						</span>
					</button>
				</p>
			</form>
		</React.Fragment>
	)
}
