import * as React from "react";
import * as ReactDom from 'react-dom';
import { PasswordVergessen } from "./components/password-vergessen";

export const RenderPasswordVergessen = (): void => {
	const passwordForgot: HTMLElement = document.querySelector("#passwordForgot");
	if (passwordForgot){
		const passwortEingabeJson = passwordForgot.getAttribute("data-password");
		const siteKey = passwordForgot.getAttribute("data-sitekey");
        const passwortEingabe = JSON.parse(passwortEingabeJson) as string;
		ReactDom.render(<PasswordVergessen Token={passwortEingabe} SiteKey={siteKey}/>, passwordForgot);
	}
};
