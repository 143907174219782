import * as React from "react"
import { FunctionComponent, useState } from "react"
import axios, { AxiosResponse } from "axios"
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner"

interface IProps {
}

export const Logout: FunctionComponent<IProps> = (props: IProps) => {

	function Logout() {
		ShowSpinner()
		axios
			.post(`/umbraco/api/Auth/Logout`)
			.then((result: AxiosResponse) => {
				HideSpinner();
				window.location.href="/";
			})
			.catch((error: any) => {
				HideSpinner();
			})
	}
	return (
		<React.Fragment>
			<a onClick={() => Logout()}>
				<div className="listElement">
					Abmelden
				</div>
			</a>
		</React.Fragment>
	)
}
