const popUp: HTMLElement = document.querySelector("#errorPopup")
const popUpText: HTMLElement = document.querySelector("#errorPopup .popupText")
const closeButton: HTMLElement = document.querySelector("#errorPopup .popUpClose")

const HidePopupError = (): void => {
	popUp.classList.add("hide")
	popUpText.textContent = ""
}

export const ShowPopupError = (message: string): void => {
	popUp.classList.remove("hide")
	popUpText.textContent = message

	closeButton.addEventListener("click", HidePopupError)
}
