import * as React from "react";
import * as ReactDom from 'react-dom';
import { Rechnungsadressen } from "./components/rechnungsadressen";
import { ICommercetoolsAddress } from "../../ts/models/billing-address"
import { ICustomer } from "../../ts/models/customer";

export const RenderMeineDatenRechnungsadressen= (): void => {
    const rechnungsadressen: HTMLElement = document.querySelector("#rechnungsadressen");
	if (rechnungsadressen){
		const billingaddressesJson = rechnungsadressen.getAttribute("data-billingaddresses");
		const billignAddresses = JSON.parse(billingaddressesJson) as ICommercetoolsAddress[];

		const isOnlineCustomerJson = rechnungsadressen.getAttribute("data-isOnlineCustomer");
        const isOnlineCustomer = JSON.parse(isOnlineCustomerJson) as boolean;

		const isPurchaserJson = rechnungsadressen.getAttribute("data-isPurchaser");
        const isPurchaser = JSON.parse(isPurchaserJson) as boolean;

        const isEmployeeJson = rechnungsadressen.getAttribute("data-isEmployee");
        const isEmployee = JSON.parse(isEmployeeJson) as boolean;
		
		ReactDom.render(<Rechnungsadressen  IsEmployee={isEmployee}
            IsPurchaser={isPurchaser} BillingAddresses={billignAddresses} IsOnlineCustomer={isOnlineCustomer} />, rechnungsadressen);
	}
    
};