import * as React from "react";
import { ChangeEvent, FunctionComponent, useState } from "react";
import { ICommercetoolsAddress } from "../../../ts/models/billing-address";
import { AddButton } from "../../shared/checkout/add-button";
import { AddressBox } from "./address-box";
import { ShippingAddressForm } from "./shipping-address-form";
import { IResponseModelState } from "../../../ts/models/response-model-state";
import { ICustomer } from "../../../ts/models/customer";

interface IProps {
	shippingAddresses: ICommercetoolsAddress[];
	onSelectShippingAddress: Function;
	currentShippingAddres: string;
	onFormSubmit: Function;
	showShippingForm: boolean;
	toggleForm: Function;
	onRemoveShippingAddress: Function;
	onEditShippingAddress: Function;
	editShippingAddress: ICommercetoolsAddress;
	addresBoxLarge: boolean;
	showRadioButton: boolean;
	modelStateErrors?: IResponseModelState[];
	scrollTo: React.RefObject<HTMLDivElement>;
	isOnlineCustomer?: boolean;
	isPurchaser?: boolean;
	isEmployee?: boolean;
}

export const DeliveryAddresses: FunctionComponent<IProps> = (props: IProps) => {
	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="billing">
						<h2 className="heading">Abweichende Rechnungsversandadressen</h2>
					</div>
				</div>
			</div>
			<section
				aria-label="Vorhandene Abweichende Rechnungsversandadressen"
				className="row billing__boxes"
			>
				{props.shippingAddresses.map((address: ICommercetoolsAddress) => (
					<AddressBox
						key={address.Id}
						address={address}
						onRemoveAddress={props.onRemoveShippingAddress}
						onEditAddress={props.onEditShippingAddress}
						type="shippingaddress"
						onSelect={props.onSelectShippingAddress}
						currentAddress={props.currentShippingAddres}
						title="Rechnungsversandadresse"
						addresBoxLarge={props.addresBoxLarge}
						showRadioButton={props.showRadioButton}
						isOnlineCustomer={props.isOnlineCustomer}
						isEmployee={props.isEmployee}
						isPurchaser={props.isPurchaser}
					/>
				))}
			</section>

			{!props.showShippingForm && !props.isEmployee && !props.isPurchaser && (
				<div className="row">
					<div className="col-12">
						<AddButton buttonText="Rechnungsversandadresse hinzufügen" onClick={props.toggleForm} />
					</div>
				</div>
			)}
			<div ref={props.scrollTo} />
			{props.showShippingForm && (
				<section aria-label="Formular Neue Rechnungsversandadresse" aria-live="polite">
					<ShippingAddressForm
						editAddress={props.editShippingAddress}
						toggleForm={props.toggleForm}
						onFormSubmit={props.onFormSubmit}
						showHeading={props.shippingAddresses?.length > 0}
						modelStateErrors={props.modelStateErrors}
					/>
				</section>
			)}
		</>
	);
};
