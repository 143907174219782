import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FunctionComponent } from "react"
import * as React from "react"

interface IProps {
    url: string
    buttonText: string
}

export const BackButton: FunctionComponent<IProps> = (props: IProps) => {
	return (
		<React.Fragment>
			<a className="previous__link link" href={props.url}>
				<i className="fas fa-chevron-left icon-fa icon--red"></i>
				<FontAwesomeIcon icon={faChevronLeft} className="icon--red fa-w-10" /> {}
				<span>{props.buttonText}</span>
			</a>
		</React.Fragment>
	)
}
