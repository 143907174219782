const icons: NodeListOf<HTMLElement> = document.querySelectorAll(".iconHolder");

function mouseOver(): void {
	const icon: HTMLImageElement = this.querySelector(".icon__wrapper img");
	if (!icon.dataset.hover) {
		return;
	}
	if (!icon.dataset.origSrc) {
		icon.dataset.origSrc = icon.src;
	}
	icon.src = icon.dataset.hover;
}

function mouseOut(): void {
	const icon: HTMLImageElement = this.querySelector(".icon__wrapper img");
	if (!icon.dataset.origSrc) {
		return;
	}
	icon.src = icon.dataset.origSrc;
}

export const IconHover = (): void => {
	icons.forEach(icon => {
		icon.addEventListener("mouseover", mouseOver);
		icon.addEventListener("mouseout", mouseOut);
	});
};
