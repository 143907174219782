import * as React from "react";
import * as ReactDom from 'react-dom';
import { Versandadressen } from "./components/versandadressen";
import { ICommercetoolsAddress } from "../../ts/models/billing-address"
import { ICustomer } from "../../ts/models/customer";

export const RenderMeineDatenVersandadressen = (): void => {
    const versandadressen: HTMLElement = document.querySelector("#versandadressen");
	if (versandadressen){
		const shippingaddressesJson = versandadressen.getAttribute("data-shippingaddresses");
		const shippingAddresses = JSON.parse(shippingaddressesJson) as ICommercetoolsAddress[];

		const billingAdressId = versandadressen.getAttribute("data-billingadressid");

		const isOnlineCustomerJson = versandadressen.getAttribute("data-isOnlineCustomer");
        const isOnlineCustomer = JSON.parse(isOnlineCustomerJson) as boolean;

		const isPurchaserJson = versandadressen.getAttribute("data-isPurchaser");
        const isPurchaser = JSON.parse(isPurchaserJson) as boolean;

        const isEmployeeJson = versandadressen.getAttribute("data-isEmployee");
        const isEmployee = JSON.parse(isEmployeeJson) as boolean;
		
		ReactDom.render(<Versandadressen  IsOnlineCustomer={isOnlineCustomer} IsEmployee={isEmployee}
            IsPurchaser={isPurchaser} ShippingAddresses={shippingAddresses}  BillingAdressId={billingAdressId}/>, versandadressen);
	}
    
};